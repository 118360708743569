import HeroSection from "../components/HeroSection";
import FeatureGrid from "../components/FeatureGrid";
import FeatureGrid2 from "../components/feturegrid2";
import PricingTable from "../components/PricingTable";
import FreeDemo from "../components/FreeDemo";
import Faq from "../components/faq";
import PowerdBy from "../components/powerdBy";
import MainFooter from "../utility/mainFooter";
import Navbar from "./navBar";

export default function LandingPage() {
  return (
    <>
      <div className="min-h-screen bg-white font-sans">
        <Navbar />

        <HeroSection />
        <FeatureGrid />
        <FeatureGrid2 />
        {/* <PricingTable /> */}
        <FreeDemo />
        <Faq />
        {/* <Master /> */}
        <MainFooter />
      </div>
    </>
  );
}
