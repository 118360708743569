// components/FreeDemo.js
import React from "react";
import box from "../utility/images/box.svg";
import { useNavigate } from "react-router-dom";
const FreeDemo = () => {
  const navigate = useNavigate();
  return (
    <section className="px-5     mx-auto">
      <img
        src={box}
        style={{
          opacity: 0.1,
        }}
        alt=""
        className="absolute right-0  w-[360px] "
      />
      <div
        style={{
          boxShadow: "0px 3px 33px 0px rgba(0,0,0,0.12)",
        }}
        className="flex rounded-[16px] t:flex-row relative flex-col mt-20  t:items-center  justify-between p-8 bg-white   mx-auto   overflow-hidden"
      >
        {/* Left Section */}
        <div className="">
          <div className="text-[26px] font-bold text-black">
            Try Retailx Today
          </div>
          <div className="text-[#363435] text-[16px] mt-2 t:max-w-[400px] leading-[28px] ">
            Complete POS Solution for Retail Billing & Stock Management with
            Your Ultimate Online Ordering Solution.
          </div>
        </div>

        {/* Right Section */}
        <div
          style={{
            border: "1px solid #FAAB33",
          }}
          className=" p-3  rounded-[70px] ms:mt-3 md:mt-0 w-fit"
        >
          <button
            style={{
              padding: "12px 24px",
            }}
            onClick={() => {
              navigate("/contact");
            }}
            className="bg-[#FAAB33] text-white  rounded-r-[70px] rounded-l-[70px] text-[16px] hover:bg-[#ff8c00]"
          >
            Get Free Demo
          </button>
        </div>

        {/* Background Accent */}
      </div>
    </section>
  );
};

export default FreeDemo;
