import {
  setAlertWithTitle,
  setItemVarientsList,
  setItemVarientsStatus,
  setSessionExpired,
  setSpinnerLoading,
} from "../utility/Redux/modal";
import moment from "moment";
import { setItemList, setSectionList } from "../utility/Redux/list";
import qz from "qz-tray";

import {
  FontSizeWidth,
  getItemByCodeFromPurchase,
  getMessageWallet,
  isPosMod,
} from "../utility/constant";
import HandleIndexedDB from "../Test/handleIndexDb";
import uniqueId from "uniqid";
import { Fade } from "@mui/material";
import { Close } from "@mui/icons-material";
import { toProperCase } from "../utility/component/format";
import {
  SaveCustomerMasterAPI,
  UpdateOrderAccepted,
} from "../utility/functions/common";
import { FormatNumber2 } from "../utility/functions/formatNumber";
import dataToLocal from "../utility/component/dataToLocal";
import deleteWholeDatabase from "../utility/functions/deleteWholeDatabase";
import PlaceHolderTextInput from "../utility/component/geniusComponents/placeTextInput";
import { useEffect, useState } from "react";
import { CallAPIPromise } from "./common";
import CallAPI from "../utility/functions/getData";
import Store from "../utility/Redux/store";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import NumberToWords3 from "../utility/component/numberToFormat";
export const loadImageAsBase64 = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous"; // Important if loading from an external URL
    img.src = url;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      resolve(canvas.toDataURL("image/png")); // Convert to Base64
    };
    img.onerror = (error) => reject(error);
  });
};
export const loadRazorpayScript = () => {
  return new Promise((resolve) => {
    if (
      document.querySelector(
        'script[src="https://checkout.razorpay.com/v1/checkout.js"]'
      )
    ) {
      resolve(true); // Already loaded
      return;
    }

    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.onload = () => resolve(true);
    script.onerror = () => resolve(false);
    document.body.appendChild(script);
  });
};

export const generateThermalPDF = async (isOriginalBill, printData) => {
  const dispatch = Store.dispatch;
  dispatch(setSpinnerLoading("Printing"));
  const pdf = new jsPDF("p", "mm", [58, 297]); // Thermal paper size
  const pageWidth = pdf.internal.pageSize.getWidth();
  const comapnyAddress = Store.getState().profile.companyAddress;
  try {
    const logoBase64 = await loadImageAsBase64(
      require("../utility/Logo/icons/orangeIcon.png")
    );
    pdf.addImage(logoBase64, "PNG", (pageWidth - 20) / 2, 5, 20, 20);
  } catch (error) {
    console.error("Failed to load logo:", error);
  }

  let yPosition = 30;
  pdf.setFontSize(8);
  pdf.text(comapnyAddress.companyName, pageWidth / 2, yPosition, {
    align: "center",
  });

  yPosition += 5;
  pdf.setFontSize(6);
  pdf.text(
    `${comapnyAddress.address}, ${comapnyAddress.city}`,
    pageWidth / 2,
    yPosition,
    { align: "center" }
  );
  yPosition += 4;
  pdf.text(comapnyAddress.email, pageWidth / 2, yPosition, {
    align: "center",
  });
  yPosition += 4;
  pdf.text(comapnyAddress.companyWebsite, pageWidth / 2, yPosition, {
    align: "center",
  });

  yPosition += 6;
  pdf.setLineDash([1, 1], 0);
  pdf.line(5, yPosition, 75, yPosition);
  yPosition += 6;

  pdf.setFontSize(7);
  pdf.text("RETAIL INVOICE", pageWidth / 2, yPosition, { align: "center" });

  yPosition += 6;
  if (!isOriginalBill) {
    pdf.text("Duplicate Bill", pageWidth / 2, yPosition, { align: "center" });
    yPosition += 6;
  }

  const billNo = `Bill No.: ${printData[0]?.INVNO}`;
  const billDate = printData[0]?.DATE
    ? `${moment.utc(printData[0]?.DATE).format("DD/MM/yyyy")}`
    : "";
  pdf.text(billNo, 5, yPosition);
  pdf.text(billDate, 40, yPosition); // Right side

  yPosition += 5;

  pdf.setLineDash([1, 1], 0);
  pdf.line(5, yPosition, 75, yPosition);
  yPosition += 6;

  const data = printData.map((p) => [
    p.ITEMNAME,
    p.QNTY,
    FormatNumber2(p.AMOUNT),
  ]);
  autoTable(pdf, {
    startY: yPosition,
    margin: { left: 5, right: 5 },
    head: [["Item", "Qty", "Amount"]],
    body: data,
    theme: "plain",
    headStyles: { fontSize: 6, textColor: [0, 0, 0] },
    styles: { fontSize: 6 },
    columnStyles: { 1: { halign: "center" }, 2: { halign: "right" } },
  });

  let finalY = pdf.lastAutoTable.finalY;
  pdf.setLineDash([]);
  pdf.line(5, finalY, 75, finalY);
  finalY += 4;

  pdf.setFontSize(7);
  pdf.text("Total:", 40, finalY, { align: "right" });
  pdf.text(
    FormatNumber2(
      printData.reduce((total, row) => total + Number(row.AMOUNT), 0)
    ),
    55,
    finalY,
    { align: "right" }
  );
  finalY += 4;

  pdf.text("Tax:", 40, finalY, { align: "right" });
  pdf.text(
    FormatNumber2(
      printData.reduce(
        (acc, row) => acc + Number(row.TAX1_AMT) + Number(row.TAX2_AMT),
        0
      )
    ),
    55,
    finalY,
    { align: "right" }
  );
  finalY += 4;

  pdf.text("Round Off:", 40, finalY, { align: "right" });
  pdf.text(FormatNumber2(printData[0]?.ROUND_OFF), 55, finalY, {
    align: "right",
  });
  finalY += 4;
  pdf.setFont("helvetica", "bold");

  pdf.text("Grand Total:", 40, finalY, { align: "right" });
  pdf.text(FormatNumber2(printData[0]?.GTOTAL), 55, finalY, {
    align: "right",
  });
  finalY += 4;
  pdf.setFont("helvetica", "normal");

  pdf.setLineDash([]);
  pdf.line(5, finalY, 75, finalY);
  finalY += 5;

  pdf.setFontSize(7);
  pdf.text(
    toProperCase(NumberToWords3(printData[0]?.GTOTAL)),
    pageWidth / 2,
    finalY,
    { align: "center" }
  );
  finalY += 4;

  pdf.setLineDash([1, 1, 0]);
  pdf.setLineWidth(0.5);

  pdf.line(5, finalY, 75, finalY);
  finalY += 7;
  pdf.setFont("helvetica", "bold");
  pdf.text("Amount Received", pageWidth / 2, finalY, {
    align: "center",
  });
  finalY += 7;

  pdf.setFont("helvetica", "normal");
  if (printData[0]?.CASH_AMOUNT) {
    pdf.text(
      `Cash Amount: ${FormatNumber2(printData[0]?.CASH_AMOUNT)}`,
      pageWidth / 2,
      finalY,
      { align: "center" }
    );
    finalY += 6;
  }
  if (printData[0]?.CARD_AMOUNT) {
    const cardText = `Card Amount: ${FormatNumber2(
      printData[0]?.CARD_AMOUNT
    )} [${printData[0]?.CARD_NO}]`;
    pdf.text(cardText, pageWidth / 2, finalY, { align: "center" });
    finalY += 6;
  }
  pdf.setLineWidth(0.01);
  pdf.setLineDash([1, 1]); // Dotted pattern
  pdf.line(5, finalY, 75, finalY);
  finalY += 10;

  pdf.text(
    "All Disputes Subjected to Jalandhar Jurisdiction.",
    pageWidth / 2,
    finalY,
    {
      align: "center",
    }
  );
  finalY += 6;
  pdf.text("Powered by geniusoffice .", pageWidth / 2, finalY, {
    align: "center",
  });
  connectPrinterAndList(pdf).finally(() => {
    dispatch(setSpinnerLoading(false));
  });
  return pdf.output("datauristring");
};
export const GetOrderSummary = (data, token, dispatch, setSummaryList) => {
  dispatch(setSpinnerLoading("loading"));
  CallAPI(
    "/api/dispatch/orderSummary",
    {
      ...data,
    },
    token,
    (e) => {
      if (!e.data.error) {
        if (e.data.response[0]) {
          setSummaryList(e.data.response);
        } else {
          setSummaryList([]);
        }
      } else {
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Something Went Wrong.",
          })
        );
      }
    },
    dispatch,
    () => {
      dispatch(setSpinnerLoading(false));
    }
  );
};
export const generatePDF = async (isOriginalBill, printData) => {
  const dispatch = Store.dispatch;

  dispatch(setSpinnerLoading("Printing"));
  const comapnyAddress = Store.getState().profile.companyAddress;

  const pdf = new jsPDF("p", "mm", "a4");
  const pageWidth = pdf.internal.pageSize.getWidth();

  try {
    // Load and add logo
    const logoBase64 = await loadImageAsBase64(
      require("../utility/Logo/icons/orangeIcon.png")
    );
    const logoWidth = 20;
    const logoHeight = 20;
    const centerX = (pageWidth - logoWidth) / 2;

    pdf.addImage(logoBase64, "PNG", centerX, 5, logoWidth, logoHeight);
  } catch (error) {
    console.error("Failed to load logo:", error);
  }

  let yPosition = 30; // Adjust based on logo height

  // Company Information (Centered)
  pdf.setFontSize(18);
  pdf.text(comapnyAddress.companyName, pageWidth / 2, yPosition, {
    align: "center",
  });

  yPosition += 6;
  pdf.setFontSize(12);
  pdf.text(
    `${comapnyAddress.address}, ${comapnyAddress.state}, ${comapnyAddress.city}, ${comapnyAddress.mobile}`,
    pageWidth / 2,
    yPosition,
    { align: "center" }
  );

  yPosition += 6;
  pdf.text(comapnyAddress.email, pageWidth / 2, yPosition, {
    align: "center",
  });

  yPosition += 6;
  pdf.text(comapnyAddress.companyWebsite, pageWidth / 2, yPosition, {
    align: "center",
  });

  yPosition += 10;
  pdf.setLineWidth(0.01);
  pdf.setLineDash([1, 1], 0); // Dotted pattern
  pdf.line(10, yPosition, 200, yPosition);

  yPosition += 10;

  pdf.setFontSize(13);
  pdf.text("RETAIL INVOICE", pageWidth / 2, yPosition, { align: "center" });

  yPosition += 7;
  pdf.setFontSize(12);
  if (!isOriginalBill) {
    pdf.text("Duplicate Bill", pageWidth / 2, yPosition, { align: "center" });

    yPosition += 7;
  }

  // Bill No. on the left and Date on the right
  const billNo = `Bill No.: ${printData[0]?.INVNO}`;
  const billDate = printData[0]?.DATE
    ? `Date: ${moment.utc(printData[0]?.DATE).format("DD/MM/yyyy")}`
    : "";

  pdf.setFontSize(12);
  pdf.text(billNo, 10, yPosition); // Left side
  pdf.text(billDate, pdf.internal.pageSize.getWidth() - 50, yPosition); // Right side

  yPosition += 5; // Move down slightly

  // Add an underline (solid line below)

  pdf.setLineDash([1, 1], 0);
  pdf.line(10, yPosition, 200, yPosition);
  pdf.setLineDash([]); // Solid line
  yPosition += 8;
  const totalQnty = printData.reduce(
    (total, row) => Number(total) + Number(row.QNTY),
    0
  );

  pdf.setFontSize(12);
  pdf.text(`Bill Status : ${printData[0]?.BILL_STATUS}`, 10, yPosition); // Left side
  const data = printData.map((p) => [
    p.ITEMNAME,
    FormatNumber2(p.SALE_PRICE),
    p.QNTY,
    p.RQNTY,
    FormatNumber2(p.SALE_PRICE),
  ]);
  autoTable(pdf, {
    startY: yPosition + 5,
    margin: { left: 10, right: 10 }, // Set left margin to 10 (Aligns table from x = 10
    head: [["Item Name", "Rate", "Qnty", "Return", "Amount"]],
    body: data,
    theme: "plain", // No background color
    headStyles: {
      halign: "center", // Center header text
      fontStyle: "bold",
      fillColor: [255, 255, 255], // No background color
      textColor: [0, 0, 0], // Black text
      lineWidth: 0.1, // Border thickness
      lineColor: [0, 0, 0], // Black border color
    },
    styles: { fontSize: 10 }, // Optional: Adjust font size
    columnStyles: {
      1: { halign: "right" }, // Align "Rate" column to the right (index 1)
      4: { halign: "right" }, // Align "Amount" column to the right (index 4)
      2: { halign: "center" }, // Align "Amount" column to the center (index 4)
      3: { halign: "center" }, // Align "Amount" column to the right (index 4)
    },
    // didDrawCell: function (data) {
    //   // Draw right border for the last column
    //   pdf.setDrawColor(0); // Black color
    //   pdf.setLineWidth(0.1); // Border thickness
    //   pdf.line(
    //     data.cell.x + data.cell.width, // X start (end of cell)
    //     data.cell.y, // Y start
    //     data.cell.x + data.cell.width, // X end (same as start)
    //     data.cell.y + data.cell.height // Y end (bottom of cell)
    //   );
    // },
  });

  let finalY = pdf.lastAutoTable.finalY; // Adjust spacing

  // Solid Line Below Table
  pdf.setLineDash([]); // Solid line
  pdf.setLineWidth(0.5);
  pdf.line(10, finalY, 200, finalY);
  finalY += 7;
  pdf.text(
    "Total :",
    pdf.lastAutoTable.columns[0].width +
      pdf.lastAutoTable.columns[1].width +
      pdf.lastAutoTable.columns[3].width +
      pdf.lastAutoTable.columns[2].width,
    finalY,
    {
      align: "right",
    }
  );
  pdf.text(
    FormatNumber2(
      printData.reduce((total, row) => Number(total) + Number(row.AMOUNT), 0)
    ),
    pdf.lastAutoTable.columns[0].width +
      pdf.lastAutoTable.columns[1].width +
      pdf.lastAutoTable.columns[3].width +
      10 +
      pdf.lastAutoTable.columns[4].width +
      pdf.lastAutoTable.columns[2].width,
    finalY,
    {
      align: "right",
    }
  );
  // Tax, Round Off & Grand Total
  finalY += 8;
  pdf.setFontSize(12);
  pdf.text(
    "Tax Amount :",
    pdf.lastAutoTable.columns[0].width +
      pdf.lastAutoTable.columns[1].width +
      pdf.lastAutoTable.columns[3].width +
      pdf.lastAutoTable.columns[2].width,
    finalY,
    {
      align: "right",
    }
  );
  pdf.text(
    FormatNumber2(
      printData.reduce(
        (acc, row) =>
          acc +
          Number(
            Number(row.TAX1_AMT) + Number(row.TAX2_AMT) + Number(row.TAX3_AMT)
          ),
        0
      )
    ),
    pdf.lastAutoTable.columns[0].width +
      pdf.lastAutoTable.columns[1].width +
      pdf.lastAutoTable.columns[3].width +
      10 +
      pdf.lastAutoTable.columns[4].width +
      pdf.lastAutoTable.columns[2].width,
    finalY,
    {
      align: "right",
    }
  );

  finalY += 8;
  pdf.text(
    "Round Off :",
    pdf.lastAutoTable.columns[0].width +
      pdf.lastAutoTable.columns[1].width +
      pdf.lastAutoTable.columns[3].width +
      pdf.lastAutoTable.columns[2].width,
    finalY,
    {
      align: "right",
    }
  );
  pdf.text(
    FormatNumber2(printData[0]?.ROUND_OFF),
    pdf.lastAutoTable.columns[0].width +
      pdf.lastAutoTable.columns[1].width +
      pdf.lastAutoTable.columns[3].width +
      10 +
      pdf.lastAutoTable.columns[4].width +
      pdf.lastAutoTable.columns[2].width,
    finalY,
    {
      align: "right",
    }
  );

  finalY += 4;
  pdf.setLineDash([]); // Solid line
  pdf.setLineWidth(0.5);
  pdf.line(10, finalY, 200, finalY);
  finalY += 7;
  pdf.setFont("helvetica", "bold");

  pdf.text(
    "Grand Total :",
    pdf.lastAutoTable.columns[0].width +
      pdf.lastAutoTable.columns[1].width +
      pdf.lastAutoTable.columns[3].width +
      pdf.lastAutoTable.columns[2].width,
    finalY,
    {
      align: "right",
    }
  );

  pdf.text(
    FormatNumber2(printData[0].GTOTAL),
    pdf.lastAutoTable.columns[0].width +
      pdf.lastAutoTable.columns[1].width +
      pdf.lastAutoTable.columns[3].width +
      10 +
      pdf.lastAutoTable.columns[4].width +
      pdf.lastAutoTable.columns[2].width,
    finalY,
    {
      align: "right",
    }
  );
  pdf.setFont("helvetica", "normal");

  // Another Solid Line
  finalY += 5;
  pdf.setLineDash([]); // Solid line
  pdf.setLineWidth(0.5);
  pdf.line(10, finalY, 200, finalY);

  // Amount Received
  finalY += 8;
  pdf.setFontSize(12);
  pdf.text(
    toProperCase(NumberToWords3(printData[0]?.GTOTAL)),
    pageWidth / 2,
    finalY,
    {
      align: "center",
    }
  );
  finalY += 5;

  pdf.setLineDash([1, 1], 0);
  pdf.setLineWidth(0.5);

  pdf.line(10, finalY, 200, finalY);
  // "All disputes subject to Jalandhar"
  finalY += 7;
  pdf.setFont("helvetica", "bold");
  pdf.text("Amount Received", pageWidth / 2, finalY, {
    align: "center",
  });
  finalY += 7;
  pdf.setFontSize(12);

  pdf.setFont("helvetica", "normal");

  if (printData[0]?.CASH_AMOUNT) {
    pdf.text(
      `Cash Amount: ${FormatNumber2(printData[0]?.CASH_AMOUNT)}`,
      pageWidth / 2,
      finalY,
      { align: "center" }
    );
    finalY += 6;
  }

  if (printData[0]?.CARD_AMOUNT) {
    const cardText = `Card Amount: ${FormatNumber2(
      printData[0]?.CARD_AMOUNT
    )} [${printData[0]?.CARD_NO}]`;
    pdf.text(cardText, pageWidth / 2, finalY, { align: "center" });
    finalY += 6;
  }
  pdf.setLineWidth(0.01);
  pdf.setLineDash([1, 1], 0); // Dotted pattern
  pdf.line(10, finalY, 200, finalY);
  pdf.setFontSize(10);
  finalY += 10;

  pdf.text(
    "All Disputes Subjected to Jalandhar Jurisdiction.",
    pageWidth / 2,
    finalY,
    {
      align: "center",
    }
  );
  finalY += 6;
  pdf.text("Powered by geniusoffice .", pageWidth / 2, finalY, {
    align: "center",
  });
  // setPdfPreview(pdf.output("bloburl"));
  connectPrinterAndList(pdf).finally(() => {
    dispatch(setSpinnerLoading(false));
  });
  return pdf.output("datauristring");
};
export const connectPrinterAndList = () => {
  const dispatch = Store.dispatch;
  const state = Store.getState();
  const token = state.security.token;
  return new Promise((resolve, reject) => {
    // Check if QZ Tray WebSocket is already active
    if (qz.websocket.isActive()) {
      // If connected, directly find printers
      qz.printers
        .find()
        .then((printers) => {
          resolve(printers);
        })
        .catch((err) => {
          dispatch(
            setAlertWithTitle({
              title: "QZ Tray Application",
              msg: "Run Qz Tray Application to connect printer",
            })
          );
          console.error("Error finding printers:", err);
          reject(err); // Reject the promise if there's an error
        });
    } else {
      qz.security.setCertificatePromise(function (resolve, reject) {
        // Preferred method - from server
        fetch("../../digital-certificate.txt", {
          cache: "no-store",
          headers: { "Content-Type": "text/plain" },
        }).then(function (data) {
          data.ok ? resolve(data.text()) : reject(data.text());
        });
      });

      qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
      qz.security.setSignaturePromise(function (toSign) {
        return function (resolve, reject) {
          CallAPIPromise(
            `/api/barCodeSignIn/barCodePrivateKey`,
            {
              toSign: toSign,
            },
            token,
            dispatch
          )
            .then((res) => {
              if (res.data.signature) {
                resolve(res.data.signature);
              }
            })
            .catch((e) => console.error(e));
        };
      });

      qz.websocket
        .connect()
        .then(() => {
          qz.printers
            .find()
            .then((printers) => {
              resolve(printers);
            })
            .catch((err) => {
              console.error("Error finding printers:", err);
              reject(err); // Reject the promise if there's an error
            });
        })
        .catch((err) => {
          console.error("Connection Error:", err);
          reject(err); // Reject the promise if connection fails
        });
    }
  });
};

export const GetSuspendedOrderSummary = (
  data,
  token,
  dispatch,
  setSummaryList
) => {
  dispatch(setSpinnerLoading("loading"));
  CallAPI(
    "/api/crossCustomerOrder/suspendedOrderSummary",
    {
      ...data,
      ITEMNAME: "",
    },
    token,
    (e) => {
      if (!e.data.error) {
        if (e.data.response[0]) {
          setSummaryList(e.data.response);
        }
      } else {
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Something Went Wrong.",
          })
        );
      }
    },
    dispatch,
    () => {
      dispatch(setSpinnerLoading(false));
    }
  );
};
export const GetListItemMaster = (
  prefix,
  sectionName,
  token,
  dispatch,
  dd,
  setFullItemList,
  setList,
  pageNumber,
  setTotalPagelist
) => {
  return new Promise((resolve, reject) => {
    const data = {
      table_prefix: prefix,
      SECTION_NAME: sectionName,
      ICODE: "",
      ITEMNAME: "",
      ALIAS_CODE: "",
      page: pageNumber ? pageNumber : 1,
      limit: 100,
    };
    dispatch(setSpinnerLoading("Loading Items"));
    CallAPIPromise("/api/master/listItemMaster", data, token, dispatch)
      .then((e) => {
        if (!e.data.error) {
          resolve(e.data.response.data);
          setList(e.data.response.data);
          setTotalPagelist(e.data.response.totalPages);

          if (!sectionName) {
            dispatch(setItemList(e.data.response.data));
          }
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  });
};
export function SectionlistUI({
  sectionList,
  sectionName,
  setSectionName,
  prefix,
  token,
  dispatch,
  setItemList,
  currentItemsPage,
  setTotalItemsPage,
  setCurrentItemPage,
}) {
  const [searchText, setSearchText] = useState("");
  const [list, setList] = useState([]);
  useEffect(() => {
    if (searchText) {
      setList(
        list.filter((o) => o.SECTION_NAME.toLowerCase().includes(searchText))
      );
    } else {
      setList(sectionList);
    }
  }, [searchText, sectionList]);
  return (
    <div className="h-full   flex flex-col grow  ml-2">
      <PlaceHolderTextInput
        icon={
          <i className="bi mx-2 bi-search text-[#b5b3b3]  flex  items-center"></i>
        }
        autoFocus={window.innerWidth > 1280}
        id={"search"}
        autoFill={"off"}
        type={"text"}
        placeholder={"Search Section"}
        value={searchText}
        onchange={(e) => {
          setSearchText(e.target.value);
        }}
      />
      <div className="border-[#A09E9E] bg-[#f8f8fd] mt-2 shadow-sm p-2 border rounded-sm flex flex-col h-full overflow-y-auto">
        <div className="grid grid-cols-1  ll:grid-cols-2 w-full bg-white rounded-b-sm gap-2  ">
          {list.map((item, key) => (
            <div
              key={key}
              className="flex overflow-hidden"
              style={{
                border:
                  item.SECTION_NAME === sectionName
                    ? "2px solid #A8CF45"
                    : "0px solid #A8CF45",
                height: "45px",
                boxShadow: "1px 1px 1px 0px #e6e6e3",
                borderRadius: "7px",
                textTransform: "none",
                backgroundColor: "#f5eb82",
                fontSize: FontSizeWidth(window.innerWidth),
                color: "#4a4a4a",
              }}
            >
              <button
                onClick={(e) => {
                  setCurrentItemPage(1);
                  setSectionName(item.SECTION_NAME);
                  GetListItemMaster(
                    prefix,
                    item.SECTION_NAME,
                    token,
                    dispatch,
                    "",
                    "",
                    setItemList,
                    currentItemsPage,
                    setTotalItemsPage
                  );
                }}
                style={{
                  width: `calc(100% - 20px)`,
                }}
                className="text-[#4a4a4a] px-2 text-start  font-semibold whitespace-nowrap "
              >
                {toProperCase(item.SECTION_NAME)}
              </button>
              <Fade
                orientation="horizontal"
                in={item.SECTION_NAME === sectionName}
              >
                <button
                  style={{
                    paddingRight: "5px",
                    paddingLeft: "5px",
                  }}
                  className="bg-green h-full"
                  onClick={() => {
                    setSectionName("");
                    setItemList([]);
                  }}
                >
                  <Close
                    color="white"
                    fontSize={"small"}
                    sx={{
                      fontSize: "15px",
                      color: "white",
                    }}
                  />
                </button>
              </Fade>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export const GetPurchaseBill = (prefix, token, dispatch, setBillCartList) => {
  const data = { table_prefix: prefix, CUSTOMER_NAME: "", CUSTOMER_MOBILE: "" };
  CallAPIPromise(
    "/api/billing/listBills",
    data,
    token,

    dispatch
  ).then((e) => {
    if (!e.data.error) {
      let list = [];
      const data = e.data.response;
      for (let i = 0; i < data.length; i++) {
        list.push({
          ...data[i],
          id: i,
        });
      }
      setBillCartList(list);
    } else {
      dispatch(
        setAlertWithTitle({
          title: "Alert",
          msg: "Somthing went wrong",
        })
      );
    }
  });
};

export const listSectionMasterApi = (prefix, token, setRows, dispatch) => {
  const data = {
    table_prefix: prefix,
    SECTION_NAME: "",
  };
  CallAPIPromise(`/api/master/listSectionMaster`, data, token, dispatch)
    .then(async function (response) {
      if (!response.data.error) {
        let list = [];
        const data = response.data.response;

        for (let i = 0; i < data.length; i++) {
          list.push({
            ...data[i],
            id: i,
          });
        }

        dispatch(setSectionList(list));
        if (isPosMod) {
          const databaseName = "SectionMaster";
          const storeName = "SectionList";
          const dataToSave = list;
          HandleIndexedDB({ databaseName, storeName, dataToSave });
        }
      } else {
        dispatch(
          setAlertWithTitle({
            title: "Error 112",
            msg: response.data.error,
          })
        );
        console.log(!response.data.error);
      }
    })
    .catch(function (error) {
      if (error.response.data.name === "TokenExpiredError") {
        dispatch(setSessionExpired(true));
        console.log(error.response);
      } else {
        dispatch(
          setAlertWithTitle({
            title: "ERROR",
            msg: "Something went wrong.",
            lottie: "reject",
          })
        );
      }
    })
    .finally(() => {
      dispatch(setSpinnerLoading(false));
    });
};

export const GetlistCustomerList = (prefix, token, setRows, dispatch) => {
  const data = {
    table_prefix_master: prefix.replace(/_.+_/, "_"),
    CUSTOMER_MOBILE: "",
    CUSTOMER_NAME: "",
  };
  CallAPIPromise(`/api/master/listCustomerMaster`, data, token, dispatch)
    .then(async function (response) {
      if (!response.data.error) {
        const data = response.data.response;

        setRows(data);
        if (isPosMod) {
          const databaseName = "CustomerMaster";
          const storeName = "CustomerList";
          const dataToSave = data;
          HandleIndexedDB({ databaseName, storeName, dataToSave });
        }
      } else {
        dispatch(
          setAlertWithTitle({
            title: "Error 112",
            msg: response.data.error,
          })
        );
        console.log(!response.data.error);
      }
    })
    .catch(function (error) {
      if (error.response.data.name === "TokenExpiredError") {
        dispatch(setSessionExpired(true));
        console.log(error.response);
      } else {
        dispatch(
          setAlertWithTitle({
            title: "ERROR",
            msg: "Something went wrong.",
            lottie: "reject",
          })
        );
      }
    })
    .finally(() => {
      dispatch(setSpinnerLoading(false));
    });
};

export const LoadBill = (
  prefix,
  token,
  dispatch,
  inv,
  setBillCartList,
  setCustomerDetails,
  setBillingDetails,
  setDate,
  bill_performa,
  isRemoveINVNO
) => {
  return new Promise((resolve, reject) => {
    const data = {
      table_prefix: prefix,
      INVNO: inv,
      BILL_PERFORMA: bill_performa,
    };
    CallAPIPromise(
      "/api/billing/loadBill",
      data,
      token,

      dispatch
    ).then((e) => {
      if (!e.data.error) {
        const data = e.data.response;
        let list = [];
        setDate(moment(data[0].DATE).format("YYYY-MM-DD"));
        for (let i = 0; i < data.length; i++) {
          list.push({
            ...data[i],
            DATE: isRemoveINVNO
              ? moment().format("YYYY-MM-DD")
              : moment(data[i].DATE).format("YYYY-MM-DD"),
            INVNO: isRemoveINVNO ? "" : data[i].INVNO,
            ORDER_NO: isRemoveINVNO ? data[i].INVNO : 0,
          });
        }
        resolve(list);

        setBillCartList(list);
        setCustomerDetails({
          CUSTOMER_NAME: data[0].CUSTOMER_NAME,
          CUSTOMER_ADDRESS: data[0].CUSTOMER_ADDRESS,
          CUSTOMER_MOBILE: data[0].CUSTOMER_MOBILE,
          CUSTOMER_EMAIL: data[0].CUSTOMER_EMAIL,
          CUSTOMER_TYPE: "",
          CITY: "",
          COUNTRY: "",
          PIN_CODE: "",
          DISQ_RATE: data[0].DISQ_RATE,
          LOYALTY_POINTS: "",
          CUSTOMER_STATUS: "",
          PRV_BAL: "",
          BAL_TYPE: "",
          CUSTOMER_CODE: data[0].CUSTOMER_CODE,
        });

        setBillingDetails({
          mobile: data[0].CUSTOMER_MOBILE,
          customerName: data[0].CUSTOMER_NAME,
          cashAmount: data[0].CASH_AMOUNT,
          cardAmount: data[0].CARD_AMOUNT,
          cardNo: data[0].CARD_NO,
          eAmount: 0,
          btcAmount: data[0].BTC_AMOUNT,
          btcName: data[0].NAME,
          couponAmount: data[0].COUPAN_AMOUNT,
          couponNo: data[0].COUPAN_NO,
          HANDLING_CHARGES: data[0].HANDLING_CHARGES
            ? data[0].HANDLING_CHARGES
            : 0,
          DELIVERY_CHARGES: data[0].DELIVERY_CHARGES
            ? data[0].DELIVERY_CHARGES
            : 0,
        });
      } else {
        reject();
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Something went wrong",
          })
        );
      }
    });
  });
};

export const editBillFields = (
  e,
  setBillCartList,
  dispatch,
  billCartList,
  billingDetails,
  customerDetails,
  setAlertTwoButtonModal,
  setBillingDetails,
  profileSetting,
  setCustomerDetails
) => {
  if (e.field === "QNTY") {
    if (Number(e.value) === 0) {
      const item = billCartList.filter((c) => c.ICODE === e.id);
      if (item[0].RQNTY < 1) {
        setBillCartList((b) => b.filter((c) => c.ICODE !== e.id));
      }
    } else {
      const box = billCartList.filter((c) => c.ICODE === e.id);
      if (box[0]) {
        itemClick(
          box[0],
          box[0],
          setBillCartList,
          billingDetails,
          customerDetails,
          Number(e.value),
          dispatch,
          setAlertTwoButtonModal,
          true,
          "",
          "",
          "",
          true,
          profileSetting,
          billCartList
        );
      }
    }
  } else {
    if (e.field === "SALE_PRICE") {
      if (e.value > 0) {
        setBillCartList((c) => {
          const data = c.map((b) => {
            if (b.ICODE === e.id) {
              return {
                ...b,
                SALE_PRICE: e.value,
                AMOUNT: e.value * b.QNTY,
                NET_AMOUNT: e.value * b.QNTY,
              };
            } else {
              return b;
            }
          });

          deleteWholeDatabase("billing").then(() => {
            dataToLocal("billing", data, [
              {
                ICODE: 1,
              },
            ]);
          });

          return data;
        });
      } else {
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Sale Price not found",
          })
        );
      }
    } else {
      if (e.field === "RQNTY") {
        if (Number(e.value) === 0) {
          const item = billCartList.filter((c) => c.ICODE === e.id);
          if (item[0].RQNTY > 0) {
            setBillCartList((b) => {
              const data = b.filter((c) => c.ICODE !== e.id);
              deleteWholeDatabase("billing").then(() => {
                dataToLocal("billing", data, [
                  {
                    ICODE: 1,
                  },
                ]);
              });

              return data;
            });
          } else {
            setBillCartList((c) => {
              const data = c.map((b) => {
                if (b.ICODE === e.id) {
                  return {
                    ...b,
                    RQNTY: e.value,
                    AMOUNT: e.value * b.SALE_PRICE,
                    DISQ_AMT: "0",
                    NET_AMOUNT: e.value * b.SALE_PRICE,
                  };
                } else {
                  return b;
                }
              });
              deleteWholeDatabase("billing").then(() => {
                dataToLocal("billing", data, [
                  {
                    ICODE: 1,
                  },
                ]);
              });
              return data;
            });
          }
        } else {
          setBillCartList((c) => {
            const data = c.map((b) => {
              if (b.ICODE === e.id) {
                return {
                  ...b,
                  RQNTY: e.value,
                  QNTY: "0",
                  AMOUNT: e.value * b.SALE_PRICE,
                  DISQ_AMT: "0",
                  NET_AMOUNT: e.value * b.SALE_PRICE,
                };
              } else {
                return b;
              }
            });
            deleteWholeDatabase("billing").then(() => {
              dataToLocal("billing", data, [
                {
                  ICODE: 1,
                },
              ]);
            });
            return data;
          });
        }
      } else {
        if (e.field === "DISQ_AMT") {
          setBillCartList((c) => {
            const data = c.map((b) => {
              if (b.ICODE === e.id) {
                if (b.SALE_PRICE * b.QNTY > e.value) {
                  const basicRate = b.SALE_PRICE * b.QNTY;
                  const a = b.SALE_PRICE * b.QNTY - e.value;
                  return {
                    ...b,
                    DISQ_AMT: e.value,
                    NET_AMOUNT: a,
                    AMOUNT: b.SALE_PRICE * b.QNTY,

                    DISQ_RATE: (e.value / basicRate) * 100,
                  };
                } else {
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: "You Cannot give discount more than amount.",
                      horizontal: true,
                    })
                  );
                  return b;
                }
              } else {
                return b;
              }
            });

            deleteWholeDatabase("billing").then(() => {
              dataToLocal("billing", data, [
                {
                  ICODE: 1,
                },
              ]);
            });
            return data;
          });
        } else {
          if (e.field === "DISQ_RATE") {
            setBillCartList((c) => {
              const data = c.map((b) => {
                if (b.ICODE === e.id) {
                  const am = Number(b.QNTY) * Number(b.SALE_PRICE);
                  const disqAmount = FormatNumber2(
                    (am * Number(e.value)) / 100
                  );
                  if (am > disqAmount) {
                    const amt = am - disqAmount;
                    return {
                      ...b,
                      DISQ_AMT: disqAmount,
                      NET_AMOUNT: amt,
                      AMOUNT: am,
                      DISQ_RATE: Number(e.value),
                    };
                  } else {
                    dispatch(
                      setAlertWithTitle({
                        title: "Alert",
                        msg: "You Cannot give discount more than amount.",
                        horizontal: true,
                      })
                    );
                    return b;
                  }
                } else {
                  return b;
                }
              });
              deleteWholeDatabase("billing").then(() => {
                dataToLocal("billing", data, [
                  {
                    ICODE: 1,
                  },
                ]);
              });

              return data;
            });
          } else {
            if (e.field === "CASH") {
              setBillingDetails((o) => {
                return {
                  ...o,
                  cashAmount: Number(e.value),
                };
              });
            } else {
              if (e.field === "CARD_AMT") {
                setBillingDetails((o) => {
                  return {
                    ...o,
                    cardAmount: Number(e.value),
                  };
                });
              } else {
                if (e.field === "CARD_NUMBER") {
                  setBillingDetails((o) => {
                    return {
                      ...o,
                      cardNo: Number(e.value),
                    };
                  });
                } else {
                  if (e.field === "EAMOUNT") {
                    setBillingDetails((o) => {
                      return {
                        ...o,
                        eAmount: Number(e.value),
                      };
                    });
                  } else {
                    if (e.field === "BTC_AMT") {
                      setBillingDetails((o) => {
                        return {
                          ...o,
                          btcAmount: Number(e.value),
                        };
                      });
                    } else {
                      if (e.field === "BTC_NAME") {
                        setBillingDetails((o) => {
                          return {
                            ...o,
                            btcName: Number(e.value),
                          };
                        });
                      } else {
                        if (e.field === "COUPAN_AMT") {
                          setBillingDetails((o) => {
                            return {
                              ...o,
                              couponAmount: Number(e.value),
                            };
                          });
                        } else {
                          if (e.field === "COUPAN_NO") {
                            setBillingDetails((o) => {
                              return {
                                ...o,
                                couponNo: Number(e.value),
                              };
                            });
                          } else {
                            if (e.field === "DISQ_RATE_ALL") {
                              const value = e.value ? e.value : 0;
                              setBillCartList((c) => {
                                const data = c.map((b) => {
                                  if (b.RQNTY === 0 || !b.RQNTY) {
                                    const am =
                                      Number(b.QNTY) * Number(b.SALE_PRICE);
                                    const disqAmount =
                                      (am * Number(value)) / 100;
                                    if (am > disqAmount) {
                                      const amt = am - disqAmount;
                                      const data = {
                                        ...b,
                                        DISQ_AMT: disqAmount,
                                        NET_AMOUNT: amt,
                                        AMOUNT: am,

                                        DISQ_RATE: Number(value),
                                      };
                                      return data;
                                    } else {
                                      dispatch(
                                        setAlertWithTitle({
                                          title: "Alert",
                                          msg: "You Cannot give discount more than amount.",
                                          horizontal: true,
                                        })
                                      );
                                      return b;
                                    }
                                  } else {
                                    return b;
                                  }
                                });
                                deleteWholeDatabase("billing").then(() => {
                                  dataToLocal("billing", data, [
                                    {
                                      ICODE: 1,
                                    },
                                  ]);
                                });

                                return data;
                              });
                            } else {
                              if (e.field === "DISQ_AMT_ALL") {
                                const value = e.value ? e.value : 0;

                                setBillCartList((c) => {
                                  const data = c.map((b) => {
                                    const totalAmt = billCartList.reduce(
                                      (acc, row) =>
                                        acc +
                                        Number(
                                          row.RQNTY > 0 || !row.RQNTY
                                            ? row.QNTY * row.SALE_PRICE
                                            : 0
                                        ),
                                      0
                                    );
                                    const disqAmt = value;
                                    const disqRate = (disqAmt / totalAmt) * 100;
                                    if (b.RQNTY === 0 || !b.RQNTY) {
                                      const am =
                                        Number(b.QNTY) * Number(b.SALE_PRICE);
                                      const disqAmount =
                                        (am * Number(disqRate)) / 100;
                                      if (am > disqAmount) {
                                        const amt = am - disqAmount;
                                        const data = {
                                          ...b,
                                          DISQ_AMT: disqAmount,
                                          NET_AMOUNT: amt,
                                          AMOUNT: am,

                                          DISQ_RATE: Number(disqRate),
                                        };
                                        return data;
                                      } else {
                                        dispatch(
                                          setAlertWithTitle({
                                            title: "Alert",
                                            msg: "You Cannot give discount more than amount.",
                                            horizontal: true,
                                          })
                                        );
                                        return b;
                                      }
                                    } else {
                                      return b;
                                    }
                                  });
                                  deleteWholeDatabase("billing").then(() => {
                                    dataToLocal("billing", data, [
                                      {
                                        ICODE: 1,
                                      },
                                    ]);
                                  });

                                  return data;
                                });
                              } else {
                                if (e.field === "HANDLING_CHARGES") {
                                  setBillingDetails((o) => {
                                    return {
                                      ...o,
                                      HANDLING_CHARGES: Number(e.value),
                                    };
                                  });
                                } else {
                                  if (e.field === "DELIVERY_CHARGES") {
                                    setBillingDetails((o) => {
                                      return {
                                        ...o,
                                        DELIVERY_CHARGES: Number(e.value),
                                      };
                                    });
                                  } else {
                                    if (e.field === "CUSTOMER_MOBILE") {
                                      setCustomerDetails((o) => {
                                        return {
                                          ...o,
                                          CUSTOMER_MOBILE: e.value,
                                        };
                                      });
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};
const handlePlaceOrdeOnline = async (
  data,
  token,
  dispatch,
  isPending,
  setBillNo,
  setPrintData,
  list,
  billCartList,
  store,
  customerDetails,
  prefix,
  isFromOrderScreen,
  navigate,
  setModalStatus,
  isPrintHide,
  defaultSetting,
  setAlertTwoButtonModal,
  setViewBillStatus,
  Undo,
  onClickPrintBill
) => {
  const res = await loadRazorpayScript();

  if (!res) {
    alert("Razorpay SDK failed to load. Check your internet.");
    return;
  }

  CallAPIPromise(
    "/api/paymentsRx/create-order",
    {
      amount: data.bill_cart[0].GTOTAL, // replace with actual amount
      receipt: "receipt#001",
      table_prefix: prefix,
    },
    token
  ).then(async (res) => {
    if (res.status === 200) {
      const order = await res.data;
      const options = {
        key: order.RAZORPAY_KEY_ID,
        amount: order.amount,
        currency: order.currency,
        name: "RetailxCross",
        description: "Online Payment",
        order_id: order.id,
        handler: async function (response) {
          CallAPIPromise(
            "/api/paymentsRx/payment/verify",
            {
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
              table_prefix: prefix,
            },
            token
          ).then(async (res) => {
            if (res.status === 200) {
              const result = await res.data;

              if (result.status === "success") {
                dispatch(setSpinnerLoading("Saving"));
                saveBillAPI(
                  {
                    ...data,
                    bill_cart: data.bill_cart.map((b) => {
                      return {
                        ...b,
                        E_AMOUNT: b.CASH_AMOUNT,
                        CASH_AMOUNT: "0",
                        PAYMENT_METHOD: result.payment.method,
                        PAYMENT_ID: result.payment.id,
                      };
                    }),
                  },
                  token,
                  dispatch,
                  isPending,
                  setBillNo,
                  setPrintData,
                  list,
                  billCartList,
                  store,
                  customerDetails,
                  prefix,
                  isFromOrderScreen,
                  navigate,
                  setModalStatus,
                  isPrintHide,
                  defaultSetting,
                  setAlertTwoButtonModal,
                  setViewBillStatus,
                  Undo,
                  onClickPrintBill
                );
              } else {
                dispatch(
                  setAlertWithTitle({
                    title: "Failed",
                    msg: "Payment Verification Failed",
                  })
                );
              }
            }
          });

          // Call backend to confirm payment and create order
        },
        prefill: {
          name: customerDetails.CUSTOMER_NAME,
          email: customerDetails.CUSTOMER_EMAIL,
          contact: customerDetails.CUSTOMER_MOBILE,
        },
        modal: {
          ondismiss: function () {
            console.log("❌ Payment popup closed or failed");
            dispatch(
              setAlertWithTitle({
                title: "Failed",
                msg: "Payment Failed",
              })
            );
          },
        },
        theme: {
          color: "#ff4500",
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    }
  });
};
export const onBillSave = (
  billCartList,
  dispatch,
  prefix,
  token,
  Undo,
  TotalAmount,
  setBillNo,
  setAlertTwoButtonModal,
  setViewBillStatus,
  inputFields,
  SaleType,
  setModalStatus,
  isPrintHide,
  longitude,
  latitude,
  isPending,
  customerDetails,
  setIsOriginalBill,
  roundOffAmt,
  isCrossOrder,
  navigate,
  setPrintData,
  onClickPrintBill,
  isFromOrderScreen,
  paymentMethod = "COD"
) => {
  console.log(paymentMethod);
  const store = Store.getState();
  const defaultSetting = store.profile.profileDefaultSetting;
  if (billCartList[0]) {
    const phoneRegex = /^[6-9]\d{9}$/;
    const isMobileValid =
      billCartList[0].CUSTOMER_MOBILE &&
      phoneRegex.test(billCartList[0].CUSTOMER_MOBILE);
    const totalAmoutWithoutTax = billCartList.reduce(
      (acc, row) => acc + Number(row.AMOUNT),
      0
    );
    if (isMobileValid || !billCartList[0].CUSTOMER_MOBILE) {
      if (
        (billCartList[0].BTC_AMOUNT > 0 && billCartList[0].CUSTOMER_MOBILE) ||
        billCartList[0].BTC_AMOUNT === 0
      ) {
        if (
          (billCartList[0].COUPAN_AMOUNT > 0 && billCartList[0].COUPAN_NO) ||
          (billCartList[0].COUPAN_AMOUNT === 0 && !billCartList[0].COUPAN_NO)
        ) {
          const list = billCartList.map((e) => {
            let saleAmount = parseFloat(e.AMOUNT);
            let disqAmount = parseFloat(e.DISQ_AMT);

            let tax1Rate = parseFloat(e.TAX1_RATE);
            let tax2Rate = parseFloat(e.TAX2_RATE);
            let tax3Rate = parseFloat(e.TAX3_RATE);

            let tax1_amt = 0;
            let tax2_amt = 0;
            let tax3_amt = 0;
            let saleAmount11 = saleAmount - disqAmount;
            if (SaleType?.toLowerCase() === "inclusive") {
              if (
                !isNaN(saleAmount) &&
                !isNaN(tax1Rate) &&
                !isNaN(tax2Rate) &&
                !isNaN(tax3Rate)
              ) {
                let tax1amt =
                  saleAmount11 - saleAmount11 / (1 + tax1Rate / 100);
                tax1_amt = tax1amt.toFixed(2);
                tax2Rate = saleAmount11 - saleAmount11 / (1 + tax2Rate / 100);
                tax2_amt = tax2Rate.toFixed(2);
                let tax3amt =
                  saleAmount11 - saleAmount11 / (1 + tax3Rate / 100);
                tax3_amt = tax3amt.toFixed(2);
              }
            } else {
              if (
                !isNaN(saleAmount) &&
                !isNaN(tax1Rate) &&
                !isNaN(tax2Rate) &&
                !isNaN(tax3Rate)
              ) {
                let tax1amt = (saleAmount11 * tax1Rate) / 100;
                tax1_amt = tax1amt.toFixed(2);
                tax2Rate = (saleAmount11 * tax2Rate) / 100;
                tax2_amt = tax2Rate.toFixed(2);
                let tax3amt = (saleAmount11 * tax3Rate) / 100;
                tax3_amt = tax3amt.toFixed(2);
              }
            }

            const data = {
              ...e,
              DATE:
                isCrossOrder &&
                billCartList[0]?.BILL_STATUS.toLowerCase() === "order"
                  ? moment().format("YYYY-MM-DD HH:mm:ss")
                  : e.DATE,
              TAX1_AMT: tax1_amt,
              TAX2_AMT: tax2_amt,
              TAX3_AMT: tax3_amt,
              TOTAL: totalAmoutWithoutTax,
              GTOTAL: TotalAmount,
              SALE_TYPE: SaleType,
              LATITUDE: latitude ? latitude : "",
              LONGITUDE: longitude ? longitude : "",
              IP_ADDRESS: "",
              BILL_STATUS: isPending ? "Hold" : "Settled",
              ROUND_OFF: roundOffAmt,
              BATCH: "",
              ORDER_TYPE: e.ORDER_TYPE ? e.ORDER_TYPE : "Counter Sale",
              DISQ_RATE: e.DISQ_RATE.toFixed(2),
              NET_AMOUNT: e.NET_AMOUNT.toFixed(2),
              DISQ_AMT: parseFloat(e.DISQ_AMT).toFixed(2),
              CUSTOMER_CODE: customerDetails.CUSTOMER_CODE
                ? customerDetails.CUSTOMER_CODE
                : 0,
              DELIVERY_DATE: e.DELIVERY_DATE
                ? moment(e.DELIVERY_DATE).format("YYYY-MM-DD")
                : "",
              DELIVERY_TIME: e.DELIVERY_TIME ? e.DELIVERY_TIME : "",
              DELIVERED_BY: "",
              DELIVERED_TIME: "",
              DEL_ASSIGNED_EMPLOYEE_CODE: 0,
              DELIVERED_BY_EMP_CODE: 0,
              DELIVERY_ASSIGN_TO: "",
              DELIVERY_ASSIGN_TIME: "",
              ORDER_RECEIVED_BY: "",
              ORDER_NO: e?.ORDER_NO ? e?.ORDER_NO : 0,
            };
            return data;
          });

          SaveCustomerMasterAPI(customerDetails, prefix, token, dispatch)
            .then(() => {
              const data = {
                table_prefix: prefix,
                bill_cart: list,
                NEW_MODIFY: list[0].INVNO ? "Modify" : "New",
                BILL_PERFORMA: "bills",
              };
              if (list[0].INVNO) {
                setIsOriginalBill(false);
              } else {
                setIsOriginalBill(true);
              }
              if (paymentMethod === "COD") {
                saveBillAPI(
                  data,
                  token,
                  dispatch,
                  isPending,
                  setBillNo,
                  setPrintData,
                  list,
                  billCartList,
                  store,
                  customerDetails,
                  prefix,
                  isFromOrderScreen,
                  navigate,
                  setModalStatus,
                  isPrintHide,
                  defaultSetting,
                  setAlertTwoButtonModal,
                  setViewBillStatus,
                  Undo,
                  onClickPrintBill
                );
              } else {
                handlePlaceOrdeOnline(
                  data,
                  token,
                  dispatch,
                  isPending,
                  setBillNo,
                  setPrintData,
                  list,
                  billCartList,
                  store,
                  customerDetails,
                  prefix,
                  isFromOrderScreen,
                  navigate,
                  setModalStatus,
                  isPrintHide,
                  defaultSetting,
                  setAlertTwoButtonModal,
                  setViewBillStatus,
                  Undo,
                  onClickPrintBill
                );
              }
            })
            .catch((msg) => {
              dispatch(
                setAlertWithTitle({
                  title: "Alert",
                  msg: msg,
                })
              );
            });
        } else {
          dispatch(
            setAlertWithTitle({
              title: "Alert",
              msg: "Fill both field Coupan Amount and Coupan Number.",
            })
          );
          inputFields.forEach((element) => {
            if (element.id === "Coupan_no") {
              element.focus();
            }
          });
        }
      } else {
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Please select any account to save BTC amount.",
          })
        );
        inputFields.forEach((element) => {
          if (element.id === "Btc_name") {
            element.focus();
          }
        });
      }
    } else {
      dispatch(
        setAlertWithTitle({
          title: "Alert",
          msg: "Customer Mobile Invalid.",
        })
      );
      // inputFields.forEach((element) => {
      //   if (element.id === "Customer_mobile") {
      //     element.focus();
      //   }
      // });
    }
  } else {
    dispatch(
      setAlertWithTitle({
        title: "Alert",
        msg: "No data found to save",
      })
    );
  }
};

export const isItemHaveOffer = (prefix, icode, token, dispatch) => {
  return new Promise((resolve, reject) => {
    CallAPIPromise(
      `/api/offerMaster/findOffer`,
      {
        table_prefix: prefix,
        ICODE: icode,
        CDATE: moment().format("YYYY-MM-DD"),
        CTIME: moment().format("HH:mm"),
      },
      token,
      dispatch
    )
      .then((res) => {
        if (!res.data.error) {
          resolve(res.data.response);
        } else {
          resolve([]);
        }
      })
      .catch((err) => {
        console.log(err);
        reject([]);
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  });
};

export const itemClick = (
  item,
  box,
  setBillCartList,
  billingDetails,
  customerDetails,
  q,
  dispatch,
  setAlertTwoButtonModal,
  edit,
  isStockCheck,
  prefix,
  token,
  isAllreadyInBillCart,
  profileSetting,
  billCartList
) => {
  const qty = q ? Number(q) : 1;
  const isInBill = billCartList.filter((o) => o.ICODE === box.ICODE);
  if (
    isAllreadyInBillCart ||
    profileSetting?.OFFER_SALE_PRICE === "No" ||
    isInBill[0]
  ) {
    if (isAllreadyInBillCart || isInBill[0]) {
      dispatch(setSpinnerLoading(false));
      addItem(
        setBillCartList,
        box,
        edit,
        qty,
        isStockCheck,
        setAlertTwoButtonModal,
        customerDetails,
        item,
        billingDetails
      );
    } else {
      getItemByCodeFromPurchase(prefix, item.ICODE, token, dispatch).then(
        (variants) => {
          if (variants[1]) {
            dispatch(
              setItemVarientsList(
                variants.map((v) => {
                  return {
                    ...item,
                    PUR_BILL_NO: v.INVNO,
                    BATCH: v.BATCH,
                    SALE_PRICE: v.SALE_PRICE,
                    BAL_STOCK: v.BAL_STOCK,
                  };
                })
              )
            );
            dispatch(setItemVarientsStatus(true));
          } else {
            // add variant details default
            if (variants[0]) {
              const boxex = {
                ...box,
                PUR_BILL_NO: variants[0].INVNO,
                BATCH: variants[0].BATCH,
                SALE_PRICE: variants[0].SALE_PRICE,
              };
              const itemx = {
                ...item,
                PUR_BILL_NO: variants[0].INVNO,
                BATCH: variants[0].BATCH,
                SALE_PRICE: variants[0].SALE_PRICE,
              };
              addItem(
                setBillCartList,
                boxex,
                edit,
                qty,
                isStockCheck,
                setAlertTwoButtonModal,
                customerDetails,
                itemx,
                billingDetails
              );
            } else {
              // else details from item master
              addItem(
                setBillCartList,
                box,
                edit,
                qty,
                isStockCheck,
                setAlertTwoButtonModal,
                customerDetails,
                item,
                billingDetails
              );
            }
          }
        }
      );
    }
  } else {
    isItemHaveOffer(prefix, box.ICODE, token, dispatch).then((offerList) => {
      if (offerList[0]) {
        const itemDetail = {
          ...box,
          SALE_PRICE: offerList[0].OFFER_SALE_PRICE,
        };
        const itemsDetails = {
          ...item,
          SALE_PRICE: offerList[0].OFFER_SALE_PRICE,
        };
        addItem(
          setBillCartList,
          itemDetail,
          edit,
          qty,
          isStockCheck,
          setAlertTwoButtonModal,
          customerDetails,
          itemsDetails,
          billingDetails,
          true
        );
      } else {
        addItem(
          setBillCartList,
          box,
          edit,
          qty,
          isStockCheck,
          setAlertTwoButtonModal,
          customerDetails,
          item,
          billingDetails
        );
      }
    });
  }
};
export const addItem = (
  setBillCartList,
  box,
  edit,
  qty,
  isStockCheck,
  setAlertTwoButtonModal,
  customerDetails,
  item,
  billingDetails,
  isOffer
) => {
  setBillCartList((oldCart, i) => {
    const isItemExist = oldCart.filter((i) => i.ICODE === box.ICODE);
    if (isItemExist[0]) {
      const itemBal = edit
        ? isItemExist[0].BAL_STOCK - qty
        : isItemExist[0].BAL_STOCK - isItemExist[0].QNTY - qty;
      if (itemBal >= 0 || !isStockCheck) {
        const data = oldCart.map((o) => {
          if (o.ICODE === box.ICODE) {
            const q = edit ? qty : Number(o.QNTY) + qty;

            const basicRate = o.SALE_PRICE * q;
            return {
              ...o,
              QNTY: q,
              NET_AMOUNT: Number(o.SALE_PRICE * q) - o.DISQ_AMT,
              AMOUNT: Number(o.SALE_PRICE * q),
              RQNTY: 0,
              DISQ_RATE: (o.DISQ_AMT / basicRate) * 100,
              IS_OFFER: o?.IS_OFFER ? o?.IS_OFFER : false,
            };
          } else
            return {
              ...o,
            };
        });
        deleteWholeDatabase("billing").then(() => {
          dataToLocal("billing", data, [
            {
              ICODE: 1,
            },
          ]);
        });
        return data;
      } else {
        setAlertTwoButtonModal((old) => {
          return {
            title: "Alert",
            msg: ` Balance Stock ${isItemExist[0].BAL_STOCK} .`,
            status: true,
            button1Click: () => {
              setAlertTwoButtonModal((o) => {
                return {
                  ...o,
                  status: false,
                };
              });
            },
            button1: "Ok",
          };
        });

        return [...oldCart];
      }
    } else {
      const itemBal = box.BAL_STOCK - qty;
      if (itemBal >= 0 || !isStockCheck) {
        const data = [
          ...oldCart,
          {
            DATE: moment().format("YYYY-MM-DD HH:mm:ss"),
            USER: "",
            BILL_TYPE: "Retail",
            ACCOUNT_NO: "0",
            NAME: "",
            ADDRESS: "",
            MOBILE: "",
            TAX_NUMBER: "",
            CUSTOMER_CODE: customerDetails.CUSTOMER_CODE,
            CUSTOMER_NAME: customerDetails.CUSTOMER_NAME,
            CUSTOMER_ADDRESS: customerDetails.CUSTOMER_ADDRESS,
            CUSTOMER_MOBILE: customerDetails.CUSTOMER_MOBILE,
            CUSTOMER_EMAIL: customerDetails.CUSTOMER_EMAIL,
            ICODE: item.ICODE,
            ITEMNAME: item.ITEMNAME,
            ITEM_DESC: item.ITEM_DESC,
            PACKING: item.PACKING,
            UNIT: item.UNIT,
            SECTION_NAME: item.SECTION_NAME,
            SUB_SEC_NAME: item.SUB_SEC_NAME,
            COLOR: "",
            SIZE: "",
            CATEGORY: "",
            BRAND_NAME: item.BRAND_NAME,
            PUR_PRICE: item.PUR_PRICE,
            MRP_RATE: item.MRP_RATE,
            SALE_PRICE: item.SALE_PRICE,
            QNTY: qty,
            NET_AMOUNT: item.SALE_PRICE * qty,
            AMOUNT: item.SALE_PRICE * qty,

            DISQ_RATE: 0,
            DISQ_AMT: 0,
            HSN_CODE: item.HSN_CODE,
            TAX1_RATE: item.TAX1_RATE ? item.TAX1_RATE : "0",
            TAX1_AMT: 0.0,
            TAX2_RATE: item.TAX2_RATE ? item.TAX2_RATE : "0",
            TAX2_AMT: 0.0,
            TAX3_RATE: item.TAX3_RATE ? item.TAX3_RATE : "0",
            TAX3_AMT: 0.0,
            CESS_RATE: "0",
            CESS_AMOUNT: item.CESS_RATE,
            ROUND_OFF: 0.0,
            TOTAL: 0,
            PAYMENT_ID: "",
            PAYMENT_METHOD: "",
            GTOTAL: 0,
            REMARKS: "",
            CARD_NO: billingDetails.cardNo,
            CARD_NAME: "",
            SALES_MAN: "",
            NO_POINTS: 0,
            BAL_STOCK: item.BAL_STOCK ? item.BAL_STOCK : 0,
            LOYALTY_AMOUNT: 0,
            LESS_LOYALITY: 0.0,
            AMOUNT_RCV: 0,
            ORDER_NO: 0,
            PRINT_ORDER_SLIP: item.PRINT_ORDER_SLIP,
            CASH_AMOUNT: billingDetails.cashAmount,
            E_AMOUNT: billingDetails.eAmount,
            CARD_AMOUNT: billingDetails.cardAmount,
            BTC_AMOUNT: billingDetails.btcAmount,
            COUPAN_AMOUNT: billingDetails.couponAmount,
            COUPAN_NO: billingDetails.couponNo,
            INVNO: "",
            RQNTY: 0,
            IS_OFFER: isOffer ? true : false,
            PUR_BILL_NO: item?.PUR_BILL_NO ? item?.PUR_BILL_NO : 0,
          },
        ];
        deleteWholeDatabase("billing").then(() => {
          dataToLocal("billing", data, [
            {
              ICODE: 1,
            },
          ]);
        });

        return data;
      } else {
        setAlertTwoButtonModal((old) => {
          return {
            title: "Alert",

            msg: ` Balance Stock ${box.BAL_STOCK} .`,
            status: true,
            button1Click: () => {
              setAlertTwoButtonModal((o) => {
                return {
                  ...o,
                  status: false,
                };
              });
            },
            button1: "Ok",
          };
        });

        return [...oldCart];
      }
    }
  });

  setTimeout(() => {
    const nearestInput = document.getElementById(box.ICODE + "-" + "QNTY");
    if (nearestInput) {
      nearestInput.focus();
    }
  }, 200);
  if (window.innerWidth > 1280) {
    setTimeout(() => {
      const nearestInput = document.getElementById("search");
      if (nearestInput) {
        nearestInput.focus();
      }
    }, 500);
  }
};
function saveBillAPI(
  data,
  token,
  dispatch,
  isPending,
  setBillNo,
  setPrintData,
  list,
  billCartList,
  store,
  customerDetails,
  prefix,
  isFromOrderScreen,
  navigate,
  setModalStatus,
  isPrintHide,
  defaultSetting,
  setAlertTwoButtonModal,
  setViewBillStatus,
  Undo,
  onClickPrintBill
) {
  CallAPIPromise(
    "/api/billing/saveBill",
    data,
    token,

    dispatch
  )
    .then((res) => {
      if (!res.data.error) {
        if (!isPending) {
          setBillNo(res.data?.INVNO);
        }
        setPrintData(
          list.map((l) => {
            return {
              ...l,
              INVNO: res.data?.INVNO,
            };
          })
        );
        if (billCartList[0]?.ORDER_NO) {
          getMessageWallet(
            store.profile.companyAddress.email,
            token,
            false,
            dispatch
          ).then((bal) => {
            if (bal > 0) {
              CallAPIPromise("/api/whatsApp/send-whatsapp-message", {
                type: "order_accepted",
                toPhoneNumber: customerDetails.CUSTOMER_MOBILE,
                INVNO: billCartList[0].ORDER_NO,
                AMOUNT: billCartList[0].NET_AMOUNT.toFixed(2),
                prefix: prefix,
                template_for: "order_accepted",
                companyEmail: store.profile.companyAddress.email,
                message_for: "order_accepted",
                rejected_reason: "",
                company_name: store.profile.companyAddress.companyName,
              }).then((e) => {
                console.log(e);
              });
            }
          });
        }
        if (billCartList[0]?.BILL_STATUS) {
          if (billCartList[0]?.BILL_STATUS.toLowerCase() === "order") {
            UpdateOrderAccepted(
              billCartList[0].CUSTOMER_MOBILE,
              billCartList[0].INVNO
            );
            if (!isFromOrderScreen) {
              navigate("/billing");
            }
          }
        }
        deleteWholeDatabase("billing");
        if (setModalStatus) {
          setModalStatus();
        }

        if (!isPrintHide && !isPending) {
          if (defaultSetting?.PREVIEW_BILL_PRINT !== "No") {
            setAlertTwoButtonModal((e) => {
              return {
                ...e,
                title: `Bill Saved`,
                msg: `Do You want to print this bill ?`,
                status: true,
                button1: "Yes",
                button1Click: () => {
                  setAlertTwoButtonModal((e) => {
                    return {
                      ...e,
                      status: false,
                    };
                  });

                  setViewBillStatus(true);
                },
                button2: "Cancel",
                button1Bg: "#34b4eb",
                button2Bg: "#e1e2e3",
                button2Color: "black",
                button2Click: () => {
                  setAlertTwoButtonModal((e) => {
                    return {
                      ...e,
                      status: false,
                    };
                  });
                },
              };
            });
            Undo(res.data?.INVNO);
          } else {
            if (defaultSetting?.PREVIEW_BILL_PRINT === "No") {
              setTimeout(() => {
                onClickPrintBill(
                  list.map((l) => {
                    return {
                      ...l,
                      INVNO: res.data?.INVNO,
                    };
                  })
                );
                Undo(res.data?.INVNO);
                dispatch(setSpinnerLoading(false));
              }, 2000);
              // setTimeout(() => {
              //   setPrintData([]);
              // }, 3000);
            }
          }
        } else {
          dispatch(
            setAlertWithTitle({
              title: "Alert",
              msg: `Bill Saved for Hold`,
            })
          );
          Undo();
        }
      } else {
        dispatch(
          setAlertWithTitle({
            title: "Error",
            msg: res.data.error,
          })
        );
      }
    })
    .catch(() => {
      dispatch(
        setAlertWithTitle({
          title: "Error",
          msg: "Something went wrong",
        })
      );
    })
    .finally(() => {
      if (defaultSetting?.PREVIEW_BILL_PRINT !== "No") {
        setTimeout(() => {
          dispatch(setSpinnerLoading(false));
        }, 1000);
      }
    });
}
