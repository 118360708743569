import { Grow } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUserType } from "../utility/Redux/profile";

const Reports = () => {
  const navigation = useNavigate();
  const list = [
    {
      name: "Sale Summary",
      path: "",
      userType: "MANAGER,USER,STORE",
      onclick: () => {
        navigation("/sale_summary");
      },
    },
    // {
    //   name: "Recived Orders",
    //   path: "",
    //   userType: "MANAGER,USER,STORE",
    //   onclick: () => {
    //     navigation("/recieved_orders");
    //   },
    // },

    {
      name: "Voucher Summary",
      path: "",
      userType: "MANAGER,USER,STORE",
      onclick: () => {
        navigation("/voucher_summary");
      },
    },
    {
      name: "Item Wise Sale Report",
      path: "",
      userType: "MANAGER,USER,STORE",
      onclick: () => {
        navigation("/item_wise_sale_report");
      },
    },
    {
      name: "Party Wise Item Sale",
      path: "",
      userType: "MANAGER,USER,STORE",
      onclick: () => {
        navigation("/party_Items_Sale");
      },
    },
    {
      name: "Offer Report",
      path: "",
      userType: "MANAGER,USER,STORE",
      onclick: () => {
        navigation("/offer_list");
      },
    },

    {
      name: "Sale Tax Report",
      path: "",
      userType: "MANAGER,USER,STORE",
      onclick: () => {
        navigation("/sale_tax_report");
      },
    },
    {
      name: "Expiry Report",
      path: "",
      userType: "MANAGER,USER,STORE",
      onclick: () => {
        navigation("/expiry_report");
      },
    },
    {
      name: "Customer Ledger",
      path: "",
      userType: "MANAGER,USER,STORE",
      onclick: () => {
        navigation("/customer_ledger");
      },
    },
    {
      name: "Customer Balance",
      path: "",
      userType: "MANAGER,USER,STORE",
      onclick: () => {
        navigation("/customer_balance_report");
      },
    },
  ];
  const list2 = [
    {
      name: "Item Wise Purchase Report",
      path: "",
      userType: "MANAGER,USER,STORE",
      onclick: () => {
        navigation("/item_wise_purchase_report");
      },
    },
    {
      name: "Daily Stock Movement",
      path: "",
      userType: "MANAGER,USER,STORE",
      onclick: () => {
        navigation("/Closing_Value_Report");
      },
    },
    {
      name: "Purchase Summary",
      path: "",
      userType: "MANAGER,USER,STORE",
      onclick: () => {
        navigation("/purchase_summary");
      },
    },
    {
      name: "Party Ledger",
      path: "",
      userType: "MANAGER,USER,STORE",
      onclick: () => {
        navigation("/party_ledger");
      },
    },
    {
      name: "Item Purchase history",
      path: "",
      userType: "MANAGER,USER,STORE",
      onclick: () => {
        navigation("/item_wise_purchase");
      },
    },
    {
      name: "Item Status",
      path: "",
      userType: "MANAGER,USER,STORE",
      onclick: () => {
        navigation("/item_status");
      },
    },
    {
      name: "Stock Report",
      path: "",
      userType: "MANAGER,USER,STORE",
      onclick: () => {
        navigation("/stock_report");
      },
    },
    {
      name: "Party Wise Balance Report",
      path: "",
      userType: "MANAGER,USER,STORE",
      onclick: () => {
        navigation("/party_bal_report");
      },
    },
  ];

  const list3 = [
    {
      name: "FieldWay DFR",
      path: "",
      userType: "ADMIN",
      onclick: () => {
        navigation("/fieldway_dfr");
      },
    },
    {
      name: "FieldWay Location",
      path: "",
      userType: "ADMIN",
      onclick: () => {
        navigation("/fieldway_location");
      },
    },
  ];
  const list4 = [
    {
      name: "Issue Summary",
      path: "",
      userType: "STORE MANAGER",
      onclick: () => {
        navigation("/issue_summary");
      },
    },
    {
      name: "Department Wise Issue Report",
      path: "",
      userType: "STORE MANAGER",
      onclick: () => {
        navigation("/dep_wise_issue");
      },
    },
    {
      name: "Item Wise Issue Report",
      path: "",
      userType: "STORE MANAGER",
      onclick: () => {
        navigation("/item_wise_issue");
      },
    },
  ];
  const list5 = [
    {
      name: "Pending Payments Of Deliverd",
      path: "",
      userType: "STORE MANAGER",
      onclick: () => {
        navigation("/pending_payments_of_deliverd");
      },
    },
  ];
  const userType = useSelector(selectUserType);
  const userWiseList = list.filter((i) =>
    i.userType.toLowerCase().includes(userType.toLowerCase())
  );
  const userWiseList2 = list2.filter((i) =>
    i.userType.toLowerCase().includes(userType.toLowerCase())
  );

  const userWiseList3 = list3.filter((i) =>
    i.userType.toLowerCase().includes(userType.toLowerCase())
  );
  const userWiseList4 = list4.filter((i) =>
    i.userType.toLowerCase().includes(userType.toLowerCase())
  );
  const userWiseList5 = list5.filter((i) =>
    i.userType.toLowerCase().includes(userType.toLowerCase())
  );
  return (
    <div className="h-full p-4 overflow-y-auto">
      {userWiseList[0] && (
        <div
          style={{
            border: "1px solid #b5b3b3",
            fontSize: "13px  ",
            fontWeight: "500",
          }}
          className="w-fit flex px-3 py-1 mb-2 text-white   rounded-br-3xl bg-[#0d0d0d]"
        >
          Sales Report :
        </div>
      )}
      {userWiseList[0] && (
        <div className="grid pb-6 grid-cols-1 sm:grid-cols-2 t:grid-cols-3 d:grid-cols-5 ll:grid-cols-6 gap-3   ">
          {userWiseList[0] &&
            userWiseList.map((e, i) => {
              return (
                <Grow
                  key={i}
                  in={true}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(true ? { timeout: i === 0 ? 0 : i * 200 } : {})}
                >
                  <button
                    key={i}
                    onClick={() => {
                      e.onclick();
                    }}
                    variant={"contained"}
                    style={{
                      textTransform: "none",
                      backgroundColor: "transparent",
                      padding: "0px",
                      borderRadius: "7px",
                      overflow: "hidden",
                      boxShadow: "3px 3px 8px #BDC2C6, -3px -3px 3px #ffffff",
                    }}
                  >
                    <div className="bg-[#e9e9e5] py-4 w-full text-black">
                      {e.name}
                    </div>
                  </button>
                </Grow>
              );
            })}
        </div>
      )}

      {userWiseList2[0] && (
        <div className="">
          <div
            style={{
              border: "1px solid #b5b3b3",
              fontSize: "13px  ",
              fontWeight: "500",
            }}
            className="w-fit flex px-3 py-1 mb-2 text-white   rounded-br-3xl bg-[#0d0d0d]"
          >
            Purchase Report :
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 t:grid-cols-3 d:grid-cols-5 ll:grid-cols-6 gap-3   ">
            {userWiseList2[0] &&
              userWiseList2.map((e, i) => {
                return (
                  <Grow
                    key={i}
                    in={true}
                    style={{ transformOrigin: "0 0 0" }}
                    {...(true ? { timeout: i === 0 ? 0 : i * 200 } : {})}
                  >
                    <button
                      key={i}
                      onClick={() => {
                        e.onclick();
                      }}
                      variant={"contained"}
                      style={{
                        textTransform: "none",
                        backgroundColor: "transparent",
                        padding: "0px",
                        borderRadius: "7px",
                        overflow: "hidden",
                        boxShadow: "3px 3px 8px #BDC2C6, -3px -3px 3px #ffffff",
                      }}
                    >
                      <div className="bg-[#e9e9e5] py-4 w-full text-black">
                        {e.name}
                      </div>
                    </button>
                  </Grow>
                );
              })}
          </div>
        </div>
      )}
      {userWiseList4[0] && (
        <div className="mt-3">
          <div
            style={{
              border: "1px solid #b5b3b3",
              fontSize: "13px  ",
              fontWeight: "500",
            }}
            className="w-fit flex px-3 py-1 mb-2 text-white   rounded-br-3xl bg-[#0d0d0d]"
          >
            Issue Report :
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 t:grid-cols-3 d:grid-cols-5 ll:grid-cols-6 gap-3   ">
            {userWiseList4[0] &&
              userWiseList4.map((e, i) => {
                return (
                  <Grow
                    key={i}
                    in={true}
                    style={{ transformOrigin: "0 0 0" }}
                    {...(true ? { timeout: i === 0 ? 0 : i * 200 } : {})}
                  >
                    <button
                      key={i}
                      onClick={() => {
                        e.onclick();
                      }}
                      variant={"contained"}
                      style={{
                        textTransform: "none",
                        backgroundColor: "transparent",
                        padding: "0px",
                        borderRadius: "7px",
                        overflow: "hidden",
                        boxShadow: "3px 3px 8px #BDC2C6, -3px -3px 3px #ffffff",
                      }}
                    >
                      <div className="bg-[#e9e9e5] py-4 w-full text-black">
                        {e.name}
                      </div>
                    </button>
                  </Grow>
                );
              })}
          </div>
        </div>
      )}

      {userWiseList3[0] && (
        <div className="">
          <div className="py-2 font-semibold">FieldWay Report :</div>
          <div className="grid grid-cols-1 sm:grid-cols-2 t:grid-cols-3 d:grid-cols-5 ll:grid-cols-6 gap-3   ">
            {userWiseList3[0] &&
              userWiseList3.map((e, i) => {
                return (
                  <Grow
                    key={i}
                    in={true}
                    style={{ transformOrigin: "0 0 0" }}
                    {...(true ? { timeout: i === 0 ? 0 : i * 200 } : {})}
                  >
                    <button
                      key={i}
                      onClick={() => {
                        e.onclick();
                      }}
                      variant={"contained"}
                      style={{
                        textTransform: "none",
                        backgroundColor: "transparent",
                        padding: "0px",
                        borderRadius: "7px",
                        overflow: "hidden",
                        boxShadow: "3px 3px 8px #BDC2C6, -3px -3px 3px #ffffff",
                      }}
                    >
                      <div className="bg-[#e9e9e5] py-4 w-full text-black">
                        {e.name}
                      </div>
                    </button>
                  </Grow>
                );
              })}
          </div>
        </div>
      )}

      {userWiseList5[0] && (
        <div className="mt-3">
          <div
            style={{
              border: "1px solid #b5b3b3",
              fontSize: "13px  ",
              fontWeight: "500",
            }}
            className="w-fit flex px-3 py-1 mb-2 text-white   rounded-br-3xl bg-[#0d0d0d]"
          >
            Delivery Reports :
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 t:grid-cols-3 d:grid-cols-5 ll:grid-cols-6 gap-3   ">
            {userWiseList5[0] &&
              userWiseList5.map((e, i) => {
                return (
                  <Grow
                    key={i}
                    in={true}
                    style={{ transformOrigin: "0 0 0" }}
                    {...(true ? { timeout: i === 0 ? 0 : i * 200 } : {})}
                  >
                    <button
                      key={i}
                      onClick={() => {
                        e.onclick();
                      }}
                      variant={"contained"}
                      style={{
                        textTransform: "none",
                        backgroundColor: "transparent",
                        padding: "0px",
                        borderRadius: "7px",
                        overflow: "hidden",
                        boxShadow: "3px 3px 8px #BDC2C6, -3px -3px 3px #ffffff",
                      }}
                    >
                      <div className="bg-[#e9e9e5] py-4 w-full text-black">
                        {e.name}
                      </div>
                    </button>
                  </Grow>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Reports;
