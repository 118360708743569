import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import box from "../utility/images/box.svg";
import k1 from "../utility/images/k1.svg";
import k2 from "../utility/images/k2.svg";
import k3 from "../utility/images/k3.svg";
import k4 from "../utility/images/k4.svg";
import { useRef } from "react";
import { motion } from "framer-motion";

export default function FeatureGrid() {
  const features = [
    {
      title: "Flexible Billing",
      description:
        "Retailx adapts to your business needs with the ability to manage billing with or without stock tracking. This flexibility allows you to operate efficiently, whether you’re managing a small inventory or running a more complex retail operation.",
      icon: k1,
    },
    {
      title: "Integrated Communication",
      description:
        "Enhance customer engagement by sending bills instantly via WhatsApp or SMS. This feature not only ensures timely communication but also helps in maintaining a digital record for both the retailer and the customer.",
      icon: k2,
    },
    {
      title: "Promotions Made Easy",
      description:
        "Retailx simplifies the process of applying offers and discounts at the point of sale. Quickly set up promotions that automatically apply to relevant items, making sure your customers always get the best deals without slowing down the billing process.",
      icon: k3,
    },
    {
      title: "Multiple Payment Options",
      description:
        "Whether your customers prefer to pay in cash or on credit, Retailx supports both methods seamlessly. Keep your transactions organized and ensure accurate records with easy-to-use payment management features.",
      icon: k4,
    },
    {
      title: "Comprehensive Reporting",
      description:
        "Stay on top of your business performance with detailed reports. Generate sales reports to track revenue, and create tax reports to see the total tax collected. These insights help you make informed decisions and stay compliant with tax regulations.",
      icon: k1,
    },
    {
      title: "Account Management",
      description:
        "Maintain a clear overview of your business’s financial health with Retailx’s party balance and ledger features. Track outstanding balances and review party transactions at a glance, ensuring your accounts are always up to date.",
      icon: k2,
    },
    {
      title: "Efficient Purchasing",
      description:
        "Manage your inventory with ease by handling purchases and purchase refunds within the same system. Retailx helps you keep track of stock levels, orders, and returns, ensuring that your inventory is always accurate.",
      icon: k3,
    },
    {
      title: "Stock Management",
      description:
        "Get a detailed view of your inventory with comprehensive stock reports and item ledgers. Retailx makes it easy to monitor stock levels, identify best-selling items, and manage reordering, so you never run out of essential products.",
      icon: k4,
    },
  ];
  const swiperRef = useRef(null);
  return (
    <section className="px-5 pt-20 relative  mx-auto">
      {/* Background Image */}
      <img
        src={box}
        style={{
          opacity: 0.1,
        }}
        alt=""
        className="absolute w-[350px] -left-[180px]"
      />
      {/* Heading */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        className="text-start mb-8"
      >
        <div className="text-[36px] text-black font-bold text-center mb-[29px] leading-[51px]">
          Key Features
        </div>
        <p className="text-center text-[#363435] text-[15px]  mx-auto leading-[26px]">
          seamlessly connects with our Retailx billing system to bring your
          retail business online. Designed for convenience and efficiency,
          RetailxCross enables customers to browse products, place orders, and
          choose delivery or pickup options—all from the comfort of their
          devices. With real-time inventory sync, secure payments, and easy
          order management, RetailxCross expands your reach and offers a smooth
          shopping experience for your customers. Bring your store online with
          RetailxCross, and enjoy streamlined operations, enhanced customer
          satisfaction, and increased sales opportunities!
        </p>
      </motion.div>
      {/* Swiper Carousel */}
      <motion.div>
        <Swiper
          modules={[Navigation]}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          spaceBetween={20}
          slidesPerView={1}
          breakpoints={{
            640: { slidesPerView: 1 },
            764: { slidesPerView: 2 },
            1130: { slidesPerView: 3 },
            1536: { slidesPerView: 4 },
          }}
          className="feature-swiper "
        >
          {features.map((feature, index) => (
            <SwiperSlide key={index}>
              <motion.div
                initial={{ opacity: 0, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                whileHover={{ scale: 1.05 }}
                transition={{ type: "spring", stiffness: 200 }}
              >
                <div
                  style={{
                    boxShadow: "0px 0px 13px 0px rgba(0,0,0,0.1)",
                  }}
                  className="bg-white rounded-[16px] my-[30px] sm:min-h-[268px] ms:min-h-[250px] p-6 text-left space-y-4 transition-transform transform hover:shadow-lg"
                >
                  <div className="h-[50px] w-[50px] bg-[#FFA800] rounded-full flex items-center justify-center mb-4">
                    <img
                      src={feature.icon}
                      alt={`${feature.title} icon`}
                      className="h-6 w-6"
                    />
                  </div>
                  <h3 className="text-[16px] font-semibold text-black">
                    {feature.title}
                  </h3>
                  <p className="text-[#666666] text-[12px] leading-relaxed">
                    {feature.description}
                  </p>
                </div>
              </motion.div>
            </SwiperSlide>
          ))}
        </Swiper>
      </motion.div>

      {/* Navigation Buttons Below */}
      <div className="flex justify-center space-x-6 mt-4">
        <button
          onClick={() => swiperRef.current?.slidePrev()} // Control Swiper
          className="custom-prev w-[45px] h-[45px]  rounded-full flex items-center justify-center border border-[#ff8800] text-[25px] hover:text-white text-[#ff8800] hover:bg-[#ff8800]"
          aria-label="Previous Slide"
        >
          &lt;
        </button>
        <button
          onClick={() => swiperRef.current?.slideNext()} // Control Swiper
          className="custom-next w-[45px] h-[45px]  rounded-full flex items-center justify-center border-[#ff8800] border text-[25px] hover:text-white text-[#ff8800] hover:bg-[#ff8800]"
          aria-label="Next Slide"
        >
          &gt;
        </button>
      </div>
    </section>
  );
}
