import React from "react";
import { Modal, Select, MenuItem } from "@mui/material";
import { Backspace } from "@mui/icons-material"; // Optional: icon for flair
import GeniustextInput from "./component/mastertext";
import ModalHeaderStyle from "./component/styles/modalHeader";

const CustomKeyboard = ({
  language,
  showKeyboard,
  onKeyPress,
  onClose,
  onLanguageChange,
  value,
  setdata,
}) => {
  const keyboardMap = {
    Hindi: [
      "अ",
      "आ",
      "इ",
      "ई",
      "उ",
      "ऊ",
      "ऋ",
      "ए",
      "ऐ",
      "ओ",
      "औ",
      "ा",
      "ि",
      "ी",
      "ु",
      "ू",
      "ृ",
      "े",
      "ै",
      "ो",
      "ौ",
      "ं",
      "ँ",
      "ः",
      "्",
      "क",
      "ख",
      "ग",
      "घ",
      "ङ",
      "च",
      "छ",
      "ज",
      "झ",
      "ञ",
      "ट",
      "ठ",
      "ड",
      "ढ",
      "ण",
      "त",
      "थ",
      "द",
      "ध",
      "न",
      "प",
      "फ",
      "ब",
      "भ",
      "म",
      "य",
      "र",
      "ल",
      "व",
      "श",
      "ष",
      "स",
      "ह",
      "क्ष",
      "त्र",
      "ज्ञ",
      " ",
      ".",
      ",",
      "?",
      "!",
      "०",
      "१",
      "२",
      "३",
      "४",
      "५",
      "६",
      "७",
      "८",
      "९",
    ],
    Punjabi: [
      "ਅ",
      "ਆ",
      "ਇ",
      "ਈ",
      "ਉ",
      "ਊ",
      "ਏ",
      "ਐ",
      "ਓ",
      "ਔ",
      "ਾ",
      "ਿ",
      "ੀ",
      "ੁ",
      "ੂ",
      "ੇ",
      "ੈ",
      "ੋ",
      "ੌ",
      "ੰ",
      "ਂ",
      "ਃ",
      "੍",
      "ਕ",
      "ਖ",
      "ਗ",
      "ਘ",
      "ਙ",
      "ਚ",
      "ਛ",
      "ਜ",
      "ਝ",
      "ਞ",
      "ਟ",
      "ਠ",
      "ਡ",
      "ਢ",
      "ਣ",
      "ਤ",
      "ਥ",
      "ਦ",
      "ਧ",
      "ਨ",
      "ਪ",
      "ਫ",
      "ਬ",
      "ਭ",
      "ਮ",
      "ਯ",
      "ਰ",
      "ਲ",
      "ਵ",
      "ਸ਼",
      "ਸ",
      "ਹ",
      "ਖ਼",
      "ਗ਼",
      "ਜ਼",
      "ਫ਼",
      "ਲ਼",
      " ",
      ".",
      ",",
      "?",
      "!",
      "੦",
      "੧",
      "੨",
      "੩",
      "੪",
      "੫",
      "੬",
      "੭",
      "੮",
      "੯",
    ],
  };

  const currentKeys = keyboardMap[language] || [];

  return (
    <Modal open={showKeyboard} onClose={onClose}>
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-white rounded-xl shadow-lg max-w-3xl w-full overflow-y-auto max-h-[90vh]">
          <ModalHeaderStyle header={"Keyword"} onClose={onClose} />
          <div className=" p-4">
            {/* Language Switcher */}
            <div className="">
              <GeniustextInput
                option={[
                  { name: "Hindi", value: "Hindi" },
                  { name: "Punjabi", value: "Punjabi" },
                ]}
                width={200}
                title={"Language"}
                hideHeader={true}
                value={language}
                onChange={(e) =>
                  setdata((p) => {
                    return {
                      ...p,
                      REGNAL_LANGUAGE: e.target.value,
                    };
                  })
                }
              />
            </div>

            {/* Value Display */}
            <div className="mb-4 text-lg font-mono p-2 bg-gray-100 rounded flex justify-between border  border-gray-300">
              <div>{value}</div>
              <div
                onClick={() => onKeyPress("backspace")}
                className="col-span-2 cursor-pointer select-none  rounded-lg text-center  text-lg text-red-600 font-semibold hover:bg-gray-100 active:bg-gray-200"
              >
                ⌫
              </div>
            </div>

            {/* Keyboard Grid */}
            <div className="grid grid-cols-6 gap-2">
              {currentKeys.map((char, index) => (
                <div
                  key={index}
                  onClick={() => onKeyPress(char)}
                  className="cursor-pointer select-none border border-gray-300 rounded-lg text-center py-2 text-lg hover:bg-gray-100 active:bg-gray-200"
                >
                  {char}
                </div>
              ))}

              {/* Backspace Button */}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CustomKeyboard;
