import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default function DynamicBanner({ banners = [] }) {
  if (banners.length === 0) {
    return (
      <div className="text-center text-gray-500">No banners available</div>
    );
  }

  return (
    <div className="relative mt-3 bg-white">
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={10}
        slidesPerView={1}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        loop={true}
        className="rounded-lg shadow-md"
      >
        {banners.map((banner, index) => (
          <SwiperSlide key={index}>
            <img
              src={banner.url}
              alt={`Banner ${index}`}
              className="w-[1024px] h-[220px] rounded-lg"
            />
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Custom Navigation Buttons */}
      <button
        style={{
          height: 10,
          width: 10,
          fontSize: 10,
          color: "red",
        }}
        className="swiper-button-prev "
      ></button>
      <button
        style={{
          height: 10,
          width: 10,
          fontSize: 10,
          color: "red",
        }}
        className="swiper-button-next"
      ></button>
    </div>
  );
}
