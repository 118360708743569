import React from "react";
import lcon from "../../../utility/Logo/icons/whiteIcon.svg";
import lconB from "../../../utility/Logo/icons/blackIcon.svg";
import Text from "../../../utility/Logo/TextLogo/whiteTextLogo.svg";
import TextB from "../../../utility/Logo/TextLogo/blackTextLogo.svg";
const retailxLogo = ({ black, size }) => {
  const icSize = size ? size : "30px";
  return (
    <div className=" flex  justify-center items-center">
      <div className=" flex items-center">
        {/* <img
          src={
            black
              ? "https://firebasestorage.googleapis.com/v0/b/retailx-126e1.appspot.com/o/retailx%2Fapp%2Fblack-icon?alt=media&token=3c355e16-e7bd-449a-a729-b39ac1dacb1e"
              : "https://firebasestorage.googleapis.com/v0/b/retailx-126e1.appspot.com/o/retailx%2Fapp%2Fwhite-icon?alt=media&token=3c355e16-e7bd-449a-a729-b39ac1dacb1e"
          }
          style={{
            width: icSize,
            height: "auto",
          }}
        /> */}
      </div>
      <div className="flex items-center ml-1">
        <img
          src={
            black
              ? `https://firebasestorage.googleapis.com/v0/b/retailx-126e1.appspot.com/o/retailx%2Fapp%2Fblack-text?alt=media&token=3c355e16-e7bd-449a-a729-b39ac1dacb1e`
              : `https://firebasestorage.googleapis.com/v0/b/retailx-126e1.appspot.com/o/retailx%2Fapp%2Fwhite-text?alt=media&token=3c355e16-e7bd-449a-a729-b39ac1dacb1e`
          }
          alt=""
          style={{
            height: "34px",
            width: "auto",
          }}
        />
      </div>
    </div>
  );
};

export default retailxLogo;
