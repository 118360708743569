import React, { useEffect, useRef, useState } from "react";
import ItemDetails from "./itemDetails";
import ItemPrice from "./itemPrice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ModalHeaderStyle from "../../utility/component/styles/modalHeader";
import {
  selectCompanyAddress,
  selectPrefix,
  selectWidth,
} from "../../utility/Redux/profile";
import { selectToken } from "../../utility/Redux/security";
import { useNavigate } from "react-router-dom";
import { ItemMasterHelp } from "../../help";
import { OnSubmit } from "./func";
import SectionMasterHelp from "../../help/sectionMasterHelp";
import SubSecMasterHelp from "../../help/subSectionMasterJHelp";
import DepartmentMasterHelp from "../../help/departmentHelp";
import { DownloadFile } from "../../utility/functions/firebase";
import HsnMasterHelp from "../../help/hsnMasterHelp";
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from "../../utility/Redux/modal";
import { toProperCase } from "../../utility/component/format";
import { Add, Close, Remove } from "@mui/icons-material";
import { Fade, Pagination, Skeleton } from "@mui/material";
import ItemBox2 from "./itemBox";
import UploadImageModal from "./uploadImg";
import HandleIndexedDB from "../../Test/handleIndexDb";
import AlertModalTwoButton from "../../utility/component/modals/alertWithTwoButton";
import deleteWholeDatabase from "../../utility/functions/deleteWholeDatabase";
import dataToLocal from "../../utility/component/dataToLocal";
import IsLocalData from "../../utility/component/isLocalData";
import {
  SelectnewItemDetails,
  setNewItemDetails,
} from "../../utility/Redux/masters";
import EmptyModal from "../../help/freeModal";
import qz from "qz-tray";
import { PrnStyle } from "../../billing/comp";
import { CallAPIPromise } from "../../billing/common";
import UploadImagesModal from "./imageUploaderModal";
import "./setyles.css";
import {
  LocalButton,
  ModifyButton,
  SaveButton,
} from "../../utility/component/buttons";
import BulkItemMaster from "./bulkItemMaster";
import { useReactToPrint } from "react-to-print";
import UpdateItemImages from "./itemImageUpdate";
const inputClasses = "w-36 border p-2 border-black text-input rounded-md";
const cardClasses = " w-full ";
const flexColClasses = "flex flex-col";
const textSmClasses = "text-sm";

const ItemMaster = ({ handleClose, setRows, rows, setItemMasterStatus }) => {
  const [uploadImgModalStatus, setUploadImgStatus] = useState(false);
  const selectedStylelocal = JSON.parse(localStorage.getItem("selectedStyle"));
  const selecteSizeLocal = localStorage.getItem("selectedSize");
  const company = useSelector(selectCompanyAddress);
  const [lastSelectedItem, setLastSelectedItem] = useState("");
  const itemDetails = useSelector(SelectnewItemDetails);
  const prefix = useSelector(selectPrefix);
  const [prnExtraData, setPrnExtraData] = useState("");
  const [testPnrFile, setTestPnrFile] = useState("");
  const [printerList, setPrinterList] = useState([]);
  const [sectionMasterhelpStatus, setSectionMaterHelpStatus] = useState(false);
  const [partyFilterText, setPartyFiltertext] = useState("");
  const [selectedItemPage, setSelectedItemPage] = useState("Item Details");
  const [subSectionMasterhelpStatus, setSubSectionMaterHelpStatus] =
    useState(false);
  const [departMentMasterHelpStatus, setDepartmentMasterHelpStatus] =
    useState(false);
  const [data, setdata] = useState({
    table_prefix: prefix,
    ...itemDetails,
  });
  const [barCodeStatus, setBarcodeStatus] = useState(false);
  const [itemImgList, setItemImgList] = useState([]);
  const [selectedPrinter, setSelectedPrinter] = useState("");

  const [refreshItems, setRefreshItems] = useState(false);
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: "",
    msg: "",
    status: false,
    setStatus: "",
    button1: "",
    button1Click: "",
    button2: "",
    button2Click: "",
  });
  const printRef = useRef();

  const [filterBy, setFilterBy] = useState({
    AISLE: "",
    SHELF: "",
    BAY: "",
  });

  const styles = {
    style1: {
      name: "2/1 inch",
      value: {
        width: "192px",
        height: "96px",
        prn: `SIZE 47.5 mm, 25 mm
GAP 3 mm, 0 mm
SPEED 4
DENSITY 10
DIRECTION 0,0
REFERENCE 0,0
OFFSET 0 mm
SET PEEL OFF
SET CUTTER OFF
SET PARTIAL_CUTTER OFF
<xpml></page></xpml><xpml><page quantity='1' pitch='25.0 mm'></xpml>SET TEAR ON
CLS
CODEPAGE 1252
TEXT 365,62,"0",180,6,6,"${data.ITEMNAME.slice(0, 23)}"
TEXT 367,36,"0",180,7,7,"Rs.:${data.SALE_PRICE}"
BARCODE 367,147,"128M",51,0,180,3,6,"!104${data.ICODE}"
TEXT 367,93,"0",180,12,6,"${data.ICODE}"
TEXT 267,184,"0",180,9,9,"${company.companyName}"
TEXT 139,54,"0",180,5,5,${data.PACKING}
TEXT 138,30,"0",180,5,5,${data.UNIT}
PRINT 1,1`,
      }, // 2/1 inch
    },
    style2: {
      name: "2/1.5 inch",
      value: {
        width: "192px",
        height: "144px",
        prn: `SIZE 101.6 mm, 25 mm
    DIRECTION 0,0
    REFERENCE 0,0
    OFFSET 0 mm
    SET PEEL OFF
    SET CUTTER OFF
    SET PARTIAL_CUTTER OFF
    SET TEAR ON
    CLS
    CODEPAGE 1252
    TEXT 806,187,"0",180,11,7,"${company.companyName}"
    BAR 456,159, 345, 3
    TEXT 793,153,"0",180,9,7,"Item:"
    TEXT 733,153,"0",180,11,7,"${data.ITEMNAME.slice(0, 23)}"
    TEXT 789,126,"0",180,7,6,"M.R.P:"
    TEXT 729,126,"0",180,9,7,"${data.ITEMNAME.slice(0, 23)}"
    TEXT 789,103,"0",180,6,7,"Weight:"
    TEXT 727,98,"0",180,13,7,"${data.PACKING} ${data.UNIT}"
    BARCODE 791,73,"128M",39,0,180,3,6,"${data.ICODE}"
    TEXT 705,28,"ROMAN.TTF",180,1,6,"${data.ICODE}"
    TEXT 400,187,"0",180,11,7,"${company.companyName}"
    BAR 50,159, 345, 3
    TEXT 387,153,"0",180,9,7,"Item:"
    TEXT 327,153,"0",180,11,7,"${data.ITEMNAME.slice(0, 23)}"
    TEXT 383,126,"0",180,7,6,"M.R.P:"
    TEXT 323,126,"0",180,9,7,"${data.ITEMNAME.slice(0, 23)}"
    TEXT 383,103,"0",180,6,7,"Weight:"
    TEXT 321,98,"0",180,13,7,"${data.PACKING} ${data.UNIT}"
    BARCODE 385,73,"128M",39,0,180,3,6,"${data.ICODE}"
    TEXT 299,28,"ROMAN.TTF",180,1,6,"${data.ICODE}"
    PRINT ${data.barQnty},1
  
    `,
      }, // 2/1.5 inch
    },
    style3: {
      name: "2/2 inch",
      value: {
        width: "192px",
        height: "192px",
        prn: `SIZE 47.5 mm, 25 mm
GAP 3 mm, 0 mm
SPEED 4
DENSITY 10
DIRECTION 0,0
REFERENCE 0,0
OFFSET 0 mm
SET PEEL OFF
SET CUTTER OFF
SET PARTIAL_CUTTER OFF
SET TEAR ON
CLS
CODEPAGE 1252
TEXT 365,62,"0",180,6,6,"${data.ITEMNAME.slice(0, 23)}"
TEXT 367,36,"0",180,7,7,"Rs.:${data.SALE_PRICE}"
BARCODE 367,147,"128M",51,0,180,3,6,"!104${data.ICODE}"
TEXT 367,93,"0",180,12,6,"${data.ICODE}"
TEXT 267,184,"0",180,9,9,"${company.companyName}"
TEXT 139,54,"0",180,5,5,${data.PACKING}
TEXT 138,30,"0",180,5,5,${data.UNIT}
PRINT 1,1`,
      },
    },
    style4: {
      name: "1.5/1 inch",
      value: {
        width: "144px",
        height: "96px",
        prn: `<xpml><page quantity='0' pitch='25.0 mm'></xpml>SIZE 47.5 mm, 25 mm
        GAP 3 mm, 0 mm
        SPEED 4
        DENSITY 10
        DIRECTION 0,0
        REFERENCE 0,0
        OFFSET 0 mm
        SET PEEL OFF
        SET CUTTER OFF
        SET PARTIAL_CUTTER OFF
       SET TEAR ON
        CLS
        CODEPAGE 1252
        TEXT 365,62,"0",180,6,6,"${data.ITEMNAME.slice(0, 23)}"
        TEXT 367,36,"0",180,7,7,"Rs.:${data.SALE_PRICE}"
        BARCODE 367,147,"128M",51,0,180,3,6,"!104${data.ICODE}"
        TEXT 367,93,"0",180,12,6,"${data.ICODE}"
        TEXT 267,184,"0",180,9,9,"${company.companyName}"
        TEXT 139,54,"0",180,5,5,${data.PACKING} 
        TEXT 138,30,"0",180,5,5,${data.UNIT}
         PRINT 1,1 <xpml></page></xpml><xpml><end/></xpml>`,
      }, // 1.5/1 inch
    },
  };
  const [aisleList, setAisleList] = useState([]);

  const [HsnMasterHelpStatus, setHsnMasterHelpStatus] = useState(false);
  const [sectionList, setSectionList] = useState([]);
  const [partyList, setPartyList] = useState([]);
  const [ListsectionName, setListSecname] = useState("");
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const [Path, setpath] = useState("");
  const navigate = useNavigate();
  const [uploadImgStatus, setUploadImghStatus] = useState(false);
  const [uploadImgCode, setUploadImghCode] = useState("");
  const [tabScreen, setTabScreen] = useState(1);
  const [tabScreenBarCode, setTabScreenBarCode] = useState(1);
  const [itemImgListIndexDb, setItemImgListIndexDb] = useState([]);
  const [uploadImgList, setUploadImgList] = useState([]);
  const [selectedItem, setSelecteditem] = useState("");
  const [filterList, setFilteredList] = useState([]);
  const [filterListSection, setFilteredListSection] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchSectionText, setSeacrchSectionText] = useState("");
  const [selectedSize, setSelectedSize] = useState(
    selecteSizeLocal ? selecteSizeLocal : "style1"
  );
  const [selectedStyle, setSelectedStyle] = useState(
    selectedStylelocal ? selectedStylelocal : styles.style1.value
  );
  const [currentPage, setCurrentpage] = useState(1);
  const [totalPages, setTotalCounts] = useState(1);

  const [firstTabWidth, setFirstTabWidth] = useState("");
  const [customerFirebasePic, setFirebaseUrl] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [capturedImage, setCapturedImage] = useState("");
  const [itemList, setItemList] = useState([]);
  const [itemMasterHelpStatus, setItemMasterHelpStatus] = useState(false);
  const formRef = useRef(null);
  const handleSelectChange = (event) => {
    setSelectedSize(event.target.value);
    setSelectedStyle(styles[event.target.value].value);
  };
  const onSave = () => {
    console.log("s");

    OnSubmit(
      data,
      token,
      dispatch,
      formRef,
      setdata,
      capturedImage,
      selectedFile,
      setSelectedFile,
      setCapturedImage,
      setFirebaseUrl,
      setRows,
      rows,
      setItemMasterStatus,
      getData,
      customerFirebasePic,
      uploadImgList,
      setUploadImgList,
      ListsectionName,
      currentPage
    );
  };
  const handlePrint = async () => {
    dispatch(
      setSpinnerLoading(`Printing ${data?.barQnty ? data?.barQnty : 1} Qnty`)
    );
    const prn =
      tabScreenBarCode === 2
        ? testPnrFile
        : PrnStyle(
            company,
            data,
            data?.barQnty ? data?.barQnty : 1,
            selectedSize,
            prnExtraData
          );
    if (prn) {
      const config = qz.configs.create(selectedPrinter); // Replace with your printer name
      try {
        await qz.print(config, [prn]);
        dispatch(
          setAlertWithTitle({
            title: "Success",
            msg: "Printed Successfully",
          })
        );
      } catch (err) {
        console.error("Error while printing:", err);
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Something went wrong while printing, make sure you have opened the qz application",
          })
        );
      }
    } else {
      dispatch(
        setAlertWithTitle({
          title: "Alert",
          msg: "Prn File not found for this size",
        })
      );
    }
  };
  useEffect(() => {
    if (formRef) {
      const inputFields = formRef.current.querySelectorAll("input, select");
      const f = (event, index) => {
        if (event.key === "Enter") {
          event.preventDefault(); // Prevent form submission
          const nextIndex = (index + 1) % inputFields.length;
          if (nextIndex === 3) {
            if (data.ITEMNAME) {
              inputFields[nextIndex].focus();
            }
          } else {
            if (nextIndex === 5) {
              // if(data.SECTION_NAME){
              //   inputFields[nextIndex].focus();
              // }else{
              //   setSectionMaterHelpStatus(true)
              // }
            } else {
              if (nextIndex === 6) {
                // if(data.SUB_SEC_NAME){
                //   inputFields[nextIndex].focus();
                // }else{
                //   setSubSectionMaterHelpStatus(true)
                // }
              } else {
                if (nextIndex === 7) {
                } else {
                  inputFields[nextIndex].focus();
                }
              }
            }
          }
        }
      };

      // Add a keydown event listener to each input and select field
      inputFields.forEach((field, index) => {
        field.addEventListener("keydown", (e) => {
          f(e, index);
        });
      });

      // Cleanup event listeners on unmount
      return () => {
        inputFields.forEach((field, index) => {
          field.removeEventListener("keydown", (e) => {
            f(e, index);
          });
        });
      };
    }
  }, [data.SECTION_NAME, data.ITEMNAME]);
  useEffect(() => {
    localStorage.setItem("selectedStyle", JSON.stringify(selectedStyle));
    localStorage.setItem("selectedSize", selectedSize);
  }, [selectedStyle]);
  useEffect(() => {
    console.log("s");

    function handleKeyPress(event) {
      // Check if Ctrl or Alt key is pressed along with 'S'
      if ((event.ctrlKey || event.altKey) && event.key === "s") {
        // Call your function here
        OnSubmit(
          data,
          token,
          dispatch,
          formRef,
          setdata,
          capturedImage,
          selectedFile,
          setSelectedFile,
          setCapturedImage,
          setFirebaseUrl,
          setRows,
          rows,
          setItemMasterStatus,
          getData,
          customerFirebasePic,
          uploadImgList,
          setUploadImgList,
          ListsectionName,
          currentPage
        );
      }
    }

    // Add event listener when component mounts
    window.addEventListener("keydown", handleKeyPress);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [data, capturedImage, selectedFile]);

  const getSectionList = () => {
    deleteWholeDatabase("sectionList");
    CallAPIPromise(
      "/api/master/listSectionMaster",
      {
        table_prefix: prefix,
        SECTION_NAME: "",
      },
      token,
      dispatch
    )
      .then((res) => {
        setSectionList(res.data.response);
        setFilteredListSection(res.data.response);
        console.log("s");

        if (res.data.response[0]) {
          dataToLocal("sectionList", res.data.response, [
            {
              ICODE: 1,
            },
          ]);

          getData(res.data.response[0].SECTION_NAME);
        }
      })
      .catch((e) => {
        if (e.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
        console.log(e);
      });
  };

  const getpartyList = () => {
    deleteWholeDatabase("sectionList");
    CallAPIPromise(
      "/api/master/listItemSuppliers",
      {
        table_prefix: prefix,
      },
      token,
      dispatch
    )
      .then((e) => {
        if (!e.data.error) {
          setPartyList(e.data.response);
          console.log("s");

          if (e.data.response[0]) {
            getData("", e.data.response[0].SUPPLIER);
          }
        }
      })
      .catch((e) => {
        if (e.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
        console.log(e);
      });
  };

  const getData = async (s, supplier, page, itemName) => {
    dispatch(setSpinnerLoading("Loading"));
    deleteWholeDatabase("itemsList");
    CallAPIPromise(
      "/api/master/listItemMaster",
      {
        table_prefix: prefix,
        SECTION_NAME: s ? s : "",
        ICODE: "",
        ALIAS_CODE: "",
        BRAND_NAME: "",
        DEP_NAME: "",
        SUB_SEC_NAME: "",
        ITEMNAME: itemName ? itemName : "",
        SUPPLIER_NAME: supplier ? supplier : "",
        page: page ? page : currentPage,
      },
      token,
      dispatch
    )
      .then((p) => {
        if (!p.data.error) {
          setTotalCounts(p.data.response.totalPages);
          setCurrentpage(p.data.response.currentPage);
          setItemList(p.data.response.data);
          setFilteredList(p.data.response.data);
        }
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };
  console.log(itemList);
  useEffect(() => {
    if (filterBy) {
      const searchItems = filterList.filter((item) => {
        if (filterBy.AISLE) {
          return item.AISLE?.toString().includes(filterBy.AISLE);
        } else {
          if (filterBy.BAY) {
            return item.BAY?.toString().includes(filterBy.BAY);
          } else {
            if (filterBy.SHELF) {
              return item.SHELF?.toString().includes(filterBy.SHELF);
            } else {
              return true;
            }
          }
        }
      });
      setItemList(searchItems);
    } else {
      setItemList(filterList);
    }
  }, [filterBy]);

  useEffect(() => {
    if (searchSectionText) {
      const regex = /\d+/;
      const searchItems = filterListSection.filter((item) => {
        if (regex.test(searchSectionText.substring(0, 1))) {
          return item.CODE.toString().includes(searchSectionText);
        } else {
          return item.SECTION_NAME.toLowerCase().includes(
            searchSectionText.toLowerCase()
          );
        }
      });
      setSectionList(searchItems);
    } else {
      setSectionList(filterListSection);
    }
  }, [searchSectionText]);
  const firstTabWidthRef = useRef();

  useEffect(() => {
    const databaseName = "ItemsImages";
    const storeName = "images";

    HandleIndexedDB({
      databaseName,
      storeName,
      callback: (data) => {
        if (data[0]) {
          setItemImgList(data);
        }
      },
    });
  }, []);

  useEffect(() => {
    if (firstTabWidthRef) {
      if (firstTabWidthRef.current) {
        setFirstTabWidth(firstTabWidthRef.current?.offsetWidth);
      }
    }
  }, [firstTabWidthRef, firstTabWidthRef.current?.offsetWidth]);
  const saveToLocal = () => {
    const databaseName = "newItemDetails";
    deleteWholeDatabase(databaseName);

    const dataToSave = [
      {
        ...data,
        ICODE: 1,
      },
    ];
    dataToLocal("newItemDetails", dataToSave, [
      {
        ICODE: 1,
      },
    ]);
  };
  useEffect(() => {
    dispatch(setNewItemDetails(data));
  }, [data]);

  useEffect(() => {
    if (itemDetails.SECTION_NAME || itemDetails.HSN_CODE) {
      saveToLocal();
    }
  }, [itemDetails]);

  useEffect(() => {
    const callback = (details, data) => {
      setdata({
        ...data[0],
        ICODE: "",
        ALIAS_CODE: "",
        ITEMNAME: "",
        ITEM_DESC: "",
        PUR_PRICE: "",
        MRP_RATE: "",
        SALE_PRICE: "",
        WHOLE_SALE_PRICE: "",
        table_prefix: prefix,
        IMAGE_1: "",
        IMAGE_2: "",
        IMAGE_3: "",
        IMAGE_4: "",
        REGNAL_ITEMNAME: "",
      });
    };
    const elseFunc = () => {
      console.log("nothing");
    };
    IsLocalData("newItemDetails", callback, elseFunc);
  }, []);

  const getAisleList = async (s) => {
    dispatch(setSpinnerLoading("Loading"));
    CallAPIPromise(
      "/api/master/itemAisleList",
      {
        table_prefix: prefix,
        AISLE: filterBy.AISLE,
        SHELF: filterBy.SHELF,
        BAY: filterBy.BAY,
      },
      token,
      dispatch
    )
      .then((p) => {
        if (!p.data.error) {
          setAisleList(p.data.response);
        }
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  const connectPrinterAndList = () => {
    return new Promise((resolve, reject) => {
      // Check if QZ Tray WebSocket is already active
      if (qz.websocket.isActive()) {
        // If connected, directly find printers
        qz.printers
          .find()
          .then((printers) => {
            resolve(printers);
          })
          .catch((err) => {
            dispatch(
              setAlertWithTitle({
                title: "QZ Tray Application",
                msg: "Run Qz Tray Application to connect printer",
              })
            );
            console.error("Error finding printers:", err);
            reject(err); // Reject the promise if there's an error
          });
      } else {
        qz.security.setCertificatePromise(function (resolve, reject) {
          // Preferred method - from server
          fetch("../../../digital-certificate.txt", {
            cache: "no-store",
            headers: { "Content-Type": "text/plain" },
          }).then(function (data) {
            data.ok ? resolve(data.text()) : reject(data.text());
          });
        });

        qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
        qz.security.setSignaturePromise(function (toSign) {
          return function (resolve, reject) {
            CallAPIPromise(
              `/api/barCodeSignIn/barCodePrivateKey`,
              {
                toSign: toSign,
              },
              token,
              dispatch
            )
              .then((res) => {
                if (res.data.signature) {
                  resolve(res.data.signature);
                }
              })
              .catch((e) => console.error(e));
          };
        });

        qz.websocket
          .connect()
          .then(() => {
            qz.printers
              .find()
              .then((printers) => {
                resolve(printers);
              })
              .catch((err) => {
                console.error("Error finding printers:", err);
                reject(err); // Reject the promise if there's an error
              });
          })
          .catch((err) => {
            console.error("Connection Error:", err);
            reject(err); // Reject the promise if connection fails
          });
      }
    });
  };

  return (
    <div ref={formRef} className="w-full       flex bg-[#f7f7f7] h-full px-2 ">
      <div
        style={{
          padding: 0,
        }}
        className="text-sm w-full container   flex flex-col   h-full "
      >
        <div className="w-full   ">
          <ModalHeaderStyle
            header={"Item Master"}
            onClose={() => {
              if (handleClose) {
                handleClose();
              } else {
                navigate("/masters");
              }
            }}
          />
        </div>
        <div
          style={{
            height: `calc(100% - 80px)`,
          }}
          className="  rounded-b-sm bg-white w-full flex flex-col "
        >
          <div className="h-full flex flex-col w-full ">
            <div className=" flex border-b drop-shadow-sm p-2 h-fit justify-between pb-2 ">
              <div className="ms:hidden md:flex  ">
                {[
                  "Item Details",
                  "Item Price",
                  "Tax Details",
                  "Supplier Details",
                ].map((t) => {
                  return (
                    <a
                      style={{
                        textDecoration: "none",
                        color: "black",
                        fontSize: "14px",
                        border: `1px ${
                          selectedItemPage === t ? "solid" : "dotted"
                        } gray`,
                        marginRight: 3,
                      }}
                      onClick={() => {
                        setTabScreen(1);
                        setSelectedItemPage(t);
                      }}
                      href={`/item_master#${t}`}
                      className="py-2 px-6 rounded-sm  "
                    >
                      {t}
                    </a>
                  );
                })}
              </div>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-2 ">
                <button
                  onClick={() => {
                    setTabScreen(1);
                  }}
                  className="py-2 px-6 ms:flex md:hidden bg-gradient-to-r from-[#f2f4f9] via-[#d9e0e2] to-[#b7c9c7] text-black rounded-sm flex items-center gap-2 hover:shadow-md transition-all duration-300"
                >
                  <i className="bi bi-database" style={{ fontSize: "18px" }} />{" "}
                  {/* Bootstrap Icon for Item Master */}
                  Item Master
                </button>

                <button
                  onClick={() => {
                    if (!itemList[0] || !sectionList[0]) {
                      getSectionList();
                    }
                    setTabScreen(2);
                    if (!aisleList[0]) {
                      getAisleList();
                    }
                  }}
                  className="py-2 px-6 bg-gradient-to-r from-[#f2f4f9] via-[#d9e0e2] to-[#b7c9c7] text-black rounded-sm flex items-center gap-2 hover:shadow-md transition-all duration-300"
                >
                  <i className="bi bi-list-ul" style={{ fontSize: "18px" }} />{" "}
                  {/* Bootstrap Icon for Item List */}
                  Item List
                </button>

                <button
                  onClick={() => {
                    setCurrentpage(1);
                    setTotalCounts(1);
                    setTabScreen(3);
                    if (!partyList[0]) {
                      getpartyList();
                    }
                  }}
                  className="py-2 px-6 bg-gradient-to-r from-[#f2f4f9] via-[#d9e0e2] to-[#b7c9c7] text-black rounded-sm flex items-center gap-2 hover:shadow-md transition-all duration-300"
                >
                  <i className="bi bi-box" style={{ fontSize: "18px" }} />{" "}
                  {/* Bootstrap Icon for Supplier List */}
                  Supplier List
                </button>

                <button
                  onClick={() => {
                    setTabScreen(4);
                  }}
                  className="py-2 px-6 bg-gradient-to-r from-[#f2f4f9] via-[#d9e0e2] to-[#b7c9c7] text-black rounded-sm flex items-center gap-2 hover:shadow-md transition-all duration-300"
                >
                  <i className="bi bi-pencil" style={{ fontSize: "18px" }} />{" "}
                  {/* Bootstrap Icon for Bulk Update */}
                  Bulk Update
                </button>

                <button
                  onClick={() => {
                    setTabScreen(5);
                  }}
                  className="py-2 px-6 bg-gradient-to-r from-[#f2f4f9] via-[#d9e0e2] to-[#b7c9c7] text-black rounded-sm flex items-center gap-2 hover:shadow-md transition-all duration-300"
                >
                  <i className="bi bi-pencil" style={{ fontSize: "18px" }} />{" "}
                  {/* Bootstrap Icon for Bulk Update */}
                  Image Update
                </button>
              </div>
            </div>

            <div className=" flex flex-col scroll-container h-full overflow-y-auto mt-2  p-2">
              <div className="h-full">
                {tabScreen === 1 && (
                  <div>
                    <div ref={firstTabWidthRef}>
                      <ItemDetails
                        itemList={itemList}
                        formRef={formRef}
                        uploadImgList={uploadImgList}
                        setFirebaseUrl={setFirebaseUrl}
                        setpath={setpath}
                        setDepartmentMasterHelpStatus={
                          setDepartmentMasterHelpStatus
                        }
                        setAlertTwoButtonModal={setAlertTwoButtonModal}
                        setSubSectionMaterHelpStatus={
                          setSubSectionMaterHelpStatus
                        }
                        setSectionMaterHelpStatus={setSectionMaterHelpStatus}
                        setItemMasterHelpStatus={setItemMasterHelpStatus}
                        data={data}
                        setdata={setdata}
                      />

                      <ItemPrice
                        onUploadImgClick={() => {
                          setUploadImgStatus(true);
                        }}
                        onPrintClick={() => {
                          if (data.ICODE && data.ICODE !== "0") {
                            connectPrinterAndList().then((printers) => {
                              setPrinterList(
                                printers.map((p) => {
                                  return {
                                    name: p,
                                    value: p,
                                  };
                                })
                              );
                              setTabScreenBarCode(1);
                              setBarcodeStatus(true);
                            });
                          } else {
                            dispatch(
                              setAlertWithTitle({
                                title: "Alert",
                                msg: "Please select item to print barcode",
                              })
                            );
                          }
                        }}
                        setHsnMasterHelpStatus={setHsnMasterHelpStatus}
                        data={data}
                        uploadImgList={uploadImgList}
                        setdata={setdata}
                      />
                    </div>
                  </div>
                )}
                {tabScreen === 2 && (
                  <div
                    style={{
                      width: firstTabWidth,
                    }}
                    className="  min-w-[340px] bg-white  px-3  "
                  >
                    <div className={cardClasses + "pl-2" + " mb-4"}>
                      <div className="flex flex-wrap gap-4 items-end">
                        <div className={flexColClasses}>
                          <label htmlFor="aisle" className={textSmClasses}>
                            Aisle:
                          </label>

                          <select
                            className={inputClasses}
                            value={filterBy.AISLE}
                            onChange={(e) => {
                              setFilterBy({
                                AISLE: e.target.value,
                                BAY: "",
                                SHELF: "",
                              });
                            }}
                          >
                            {aisleList.map((o) => {
                              return <option value={o.AISLE}>{o.AISLE}</option>;
                            })}
                          </select>
                        </div>
                        <div className={flexColClasses}>
                          <label htmlFor="shelf" className={textSmClasses}>
                            Shelf:
                          </label>
                          <select
                            value={filterBy.SHELF}
                            className={inputClasses}
                            onChange={(e) => {
                              setFilterBy({
                                SHELF: e.target.value,
                                BAY: "",
                                AISLE: "",
                              });
                            }}
                          >
                            {aisleList.map((o) => {
                              return <option value={o.SHELF}>{o.SHELF}</option>;
                            })}
                          </select>
                        </div>
                        <div className={flexColClasses}>
                          <label htmlFor="bay" className={textSmClasses}>
                            Bay:
                          </label>
                          <select
                            value={filterBy.BAY}
                            className={inputClasses}
                            onChange={(e) => {
                              setFilterBy({
                                BAY: e.target.value,
                                SHELF: "",
                                AISLE: "",
                              });
                            }}
                          >
                            {aisleList.map((o) => {
                              return <option value={o.BAY}>{o.BAY}</option>;
                            })}
                          </select>
                        </div>
                        <div className="flex items-center j ">
                          <button
                            onClick={() => {
                              getSectionList();
                              getAisleList();
                            }}
                            className="bg-brown text-white py-2 px-4 rounded-md sahdow-md"
                          >
                            Refresh List
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className=" pl-2 ">
                      <div
                        style={{
                          borderLeft: "1px solid black",
                          borderRight: "1px solid black",
                          borderBottom: "1px solid #4a4948",
                          borderTop: "1px solid #4a4948",
                          fontSize: "14px",
                        }}
                        className="flex  rounded-sm overflow-hidden "
                      >
                        <i className="bi bi-search pr-2 p-2"></i>
                        <input
                          value={searchSectionText}
                          type={"text"}
                          placeholder="Search Section"
                          onChange={(e) =>
                            setSeacrchSectionText(e.target.value)
                          }
                          className="w-[200px] flex outline-0 p-2 bg-white"
                        />
                        <input
                          value={searchText}
                          type={"text"}
                          placeholder="Search Items"
                          onChange={(e) => setSearchText(e.target.value)}
                          onKeyDown={(e) => {
                            if (
                              e.code === "Enter" ||
                              e.code === "NumpadEnter"
                            ) {
                              if (e.target.value) {
                                getData("", "", 1, e.target.value);
                              }
                            }
                          }}
                          className="flex-1 border-l pl-2   flex outline-0 bg-white"
                        />
                        <button
                          onClick={() => {
                            getData("", "", 1, searchText);
                          }}
                          className="bg-brown rounded-r-sm px-2 text-white"
                        >
                          Search{" "}
                        </button>
                      </div>
                    </div>
                    <div className=" flex mt-2 flex-1">
                      <div
                        style={{
                          height: `500px`,
                        }}
                        className=" overflow-y-auto min-w-[280px] "
                      >
                        <div className="grid  gap-1 grid-cols-1 ">
                          {sectionList
                            ? sectionList.map((item, key) => (
                                <>
                                  <div
                                    key={key}
                                    className="flex overflow-hidden mx-2"
                                    style={{
                                      border:
                                        item.SECTION_NAME === ListsectionName
                                          ? "2px solid #A8CF45"
                                          : "0px solid #A8CF45",
                                      height: "45px",
                                      boxShadow: "1px 1px 3px 0px #9F9C9C",
                                      borderRadius: "7px",
                                      textTransform: "none",
                                      backgroundColor: "#f5eb82",
                                      fontSize: "15px",
                                      color: "#4a4a4a",
                                    }}
                                  >
                                    <button
                                      onClick={() => {
                                        getData(item.SECTION_NAME, "", 1);
                                        setListSecname(item.SECTION_NAME);
                                        setCurrentpage(1);
                                      }}
                                      style={{
                                        width: `calc(100% - 20px)`,
                                      }}
                                      className="text-[#4a4a4a] px-2 text-[15px] text-start  font-semibold whitespace-nowrap "
                                    >
                                      {toProperCase(item.SECTION_NAME)}
                                    </button>
                                    <Fade
                                      orientation="horizontal"
                                      in={item.SECTION_NAME === ListsectionName}
                                    >
                                      <button
                                        style={{
                                          paddingRight: "5px",
                                          paddingLeft: "5px",
                                        }}
                                        className="bg-green h-full"
                                        onClick={() => {
                                          getData("");
                                          setListSecname("");
                                          setCurrentpage(1);
                                        }}
                                      >
                                        <Close
                                          color="white"
                                          fontSize={"small"}
                                          sx={{
                                            fontSize: "15px",
                                            color: "white",
                                          }}
                                        />
                                      </button>
                                    </Fade>
                                  </div>
                                </>
                              ))
                            : [
                                "1",
                                "2",
                                "3",
                                "4",
                                "5",
                                "6",
                                "7",
                                "8",
                                "9",
                                "10",
                                "11",
                                "12",
                                "13",
                                "14",
                                "15",
                                "16",
                              ].map(() => {
                                return (
                                  <Skeleton
                                    variant="rectangular"
                                    width={136}
                                    animation="wave"
                                    height={70}
                                    sx={{
                                      fontSize: "1rem",
                                      borderRadius: "7px",
                                    }}
                                  />
                                );
                              })}
                        </div>
                      </div>
                      <div className=" flex-1 flex ">
                        <div className="w-full">
                          <div
                            style={{
                              height: `470px`,
                            }}
                            className="    overflow-y-auto"
                          >
                            <div className="grid px-2  gap-2 grid-cols-1 l:grid-cols-4 ">
                              {itemList[0]
                                ? itemList.map((i, index) => {
                                    return (
                                      <ItemBox2
                                        refreshItems={refreshItems}
                                        key={index}
                                        item={i}
                                        itemImgList={itemImgList}
                                        index={index}
                                        indexDbImgList={itemImgListIndexDb}
                                        itemNameStyle={{
                                          width: "100%",
                                          height: "98px",
                                          textTransform: "none",
                                          backgroundColor: "#fadb3f",
                                          borderRadius: "7px",
                                          color: "#4a4a4a",
                                          padding: "0px",
                                          fontSize: "12px",
                                          border:
                                            lastSelectedItem === i.ICODE
                                              ? "2px solid red"
                                              : "0px solid red",
                                        }}
                                        onClick={() => {
                                          setdata({
                                            ...i,
                                            table_prefix: prefix,

                                            OPBAL_DATE: i.OPBAL_DATE
                                              ? moment(i.OPBAL_DATE).format(
                                                  "YYYY-MM-DD"
                                                )
                                              : moment().format("YYYY-MM-DD"),
                                          });
                                          const icode = i.ICODE;
                                          const path = `retailx/items/${
                                            prefix + icode
                                          }`;
                                          DownloadFile(path)
                                            .then((e) => {
                                              if (
                                                !e.includes("FirebaseError")
                                              ) {
                                                setFirebaseUrl(e);
                                                setpath(path);
                                              } else {
                                                setpath("");

                                                setFirebaseUrl("");
                                              }
                                            })
                                            .catch((e) => {
                                              // setCapturedImage('');
                                              setFirebaseUrl("");
                                            });
                                          setTabScreen(1);
                                          setSelectedItemPage("Item Details");
                                          setLastSelectedItem(icode);
                                          // setUploadImghCode(i.ICODE);
                                          // setUploadImghStatus(true);
                                          // setSelecteditem(i);
                                        }}
                                      />
                                    );
                                  })
                                : null}
                            </div>
                          </div>

                          <div className="flex justify-end mt-2">
                            <Pagination
                              page={currentPage}
                              count={totalPages}
                              onChange={(e, v) => {
                                getData(ListsectionName, "", v);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {tabScreen === 3 && (
                  <div
                    style={{
                      width: firstTabWidth,
                    }}
                    className="  min-w-[340px] bg-white  px-3  "
                  >
                    <div className=" flex mt-2 flex-1">
                      <div
                        style={{
                          height: `600px`,
                        }}
                        className=" overflow-y-auto min-w-[280px] border-r"
                      >
                        <div className="grid  gap-1 grid-cols-1   ">
                          {partyList
                            ? partyList
                                .filter((p) => {
                                  if (partyFilterText) {
                                    return p.SUPPLIER.toLowerCase().includes(
                                      partyFilterText.toLowerCase()
                                    );
                                  } else {
                                    return true;
                                  }
                                })
                                .map((item, key) => (
                                  <>
                                    <div
                                      key={key}
                                      className="flex overflow-hidden mx-2"
                                      style={{
                                        border:
                                          item.SUPPLIER === selectedSupplier
                                            ? "2px solid #A8CF45"
                                            : "0px solid #A8CF45",
                                        height: "45px",
                                        boxShadow: "1px 1px 3px 0px #9F9C9C",
                                        borderRadius: "7px",
                                        textTransform: "none",
                                        backgroundColor: "#f5eb82",
                                        fontSize: "15px",
                                        color: "#4a4a4a",
                                      }}
                                    >
                                      <button
                                        onClick={() => {
                                          getData("", item.SUPPLIER);
                                          setSelectedSupplier(item.SUPPLIER);
                                        }}
                                        style={{
                                          width: `calc(100% - 20px)`,
                                        }}
                                        className="text-[#4a4a4a] px-2 text-[15px] text-start  font-semibold whitespace-nowrap "
                                      >
                                        {toProperCase(item.SUPPLIER)}
                                      </button>
                                      <Fade
                                        orientation="horizontal"
                                        in={item.SUPPLIER === selectedSupplier}
                                      >
                                        <button
                                          style={{
                                            paddingRight: "5px",
                                            paddingLeft: "5px",
                                          }}
                                          className="bg-green h-full"
                                          onClick={() => {
                                            getData("");
                                            setSelectedSupplier("");
                                          }}
                                        >
                                          <Close
                                            color="white"
                                            fontSize={"small"}
                                            sx={{
                                              fontSize: "15px",
                                              color: "white",
                                            }}
                                          />
                                        </button>
                                      </Fade>
                                    </div>
                                  </>
                                ))
                            : [
                                "1",
                                "2",
                                "3",
                                "4",
                                "5",
                                "6",
                                "7",
                                "8",
                                "9",
                                "10",
                                "11",
                                "12",
                                "13",
                                "14",
                                "15",
                                "16",
                              ].map(() => {
                                return (
                                  <Skeleton
                                    variant="rectangular"
                                    width={136}
                                    animation="wave"
                                    height={70}
                                    sx={{
                                      fontSize: "1rem",
                                      borderRadius: "7px",
                                    }}
                                  />
                                );
                              })}
                        </div>
                      </div>
                      <div className="flex flex-1">
                        <div className="w-full ">
                          <div
                            style={{
                              height: `550px`,
                            }}
                            className="    overflow-y-auto w-full flex  "
                          >
                            <div className="grid px-2  gap-2 grid-cols-1 l:grid-cols-4 w-full">
                              {itemList[0]
                                ? itemList.map((i, index) => {
                                    return (
                                      <ItemBox2
                                        refreshItems={refreshItems}
                                        key={index}
                                        item={i}
                                        itemImgList={itemImgList}
                                        index={index}
                                        indexDbImgList={itemImgListIndexDb}
                                        itemNameStyle={{
                                          width: "100%",
                                          height: "98px",
                                          textTransform: "none",
                                          backgroundColor: "#fadb3f",
                                          borderRadius: "7px",
                                          color: "#4a4a4a",
                                          padding: "0px",
                                          fontSize: "12px",
                                        }}
                                        onClick={() => {
                                          setUploadImghCode(i.ICODE);
                                          setUploadImghStatus(true);
                                          setSelecteditem(i);
                                        }}
                                      />
                                    );
                                  })
                                : null}
                            </div>
                          </div>

                          <div className="flex justify-end mt-2 ">
                            <Pagination
                              page={currentPage}
                              count={totalPages}
                              onChange={(e, v) => {
                                getData("", selectedSupplier, v);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {tabScreen === 4 && <BulkItemMaster />}
                {tabScreen === 5 && <UpdateItemImages />}
              </div>
            </div>
            {tabScreen === 1 && (
              <div className="flex justify-end mt-2 ">
                {data.ICODE ? (
                  <ModifyButton onClick={onSave} />
                ) : (
                  <SaveButton onClick={onSave} />
                )}
                <LocalButton
                  ml={4}
                  bg={"#383535"}
                  color={"white"}
                  title={"Undo"}
                  onClick={() => {
                    getData("");
                    console.log("s");
                    setFirebaseUrl("");
                    setSelectedFile("");
                    setdata({
                      table_prefix: data.table_prefix,
                      ICODE: "",
                      ALIAS_CODE: "",
                      HEAD_CODE: "",
                      ITEMNAME: "",
                      ITEM_DESC: "",
                      SECTION_NAME: "",
                      SUB_SEC_NAME: "",
                      UNIT: "",
                      PACKING: "",
                      BRAND_NAME: "",
                      PUR_PRICE: "",
                      SALE_PRICE: "",
                      MRP_RATE: "",
                      MIN_STOCK: "",
                      MAX_STOCK: "",
                      TAX1_RATE: "",
                      TAX2_RATE: "",
                      TAX3_RATE: "",
                      OPBAL: "",
                      OPBAL_DATE: moment().format("YYYY-MM-DD"),
                      MARK_RATE: "",
                      HSN_CODE: "",
                      DISQ_YN: "",
                      DISQ_RATE: "",
                      STOP_YN: "",
                      STOCKYN: "",
                      CESS_RATE: "",
                      OPBAL_RATE: "",
                      OPBAL_AMOUNT: "",
                      PRINT_ORDER_SLIP: "No",
                      WHOLE_SALE_PRICE: "",
                      GIFT_PACK: "No",
                      DEP_NAME: "",
                      NON_MOVING: "",
                      ITEM_TYPE: "",
                      AISLE: "",
                      SHELF: "",
                      BAY: "",
                      SUPPLIER_1: "",
                      SUPPLIER_2: "",
                      ITEM_AVAILABILITY: "Online & InStore",
                      SUPPLIER_3: "",
                    });
                    setUploadImgList([]);
                  }}
                />
                {window.innerWidth > 768 && (
                  <>
                    <LocalButton
                      bg={"black"}
                      ml={4}
                      color={"white"}
                      title={"Print Bar Code"}
                      onClick={() => {
                        if (data.ICODE && data.ICODE !== "0") {
                          connectPrinterAndList().then((printers) => {
                            setPrinterList(
                              printers.map((p) => {
                                return {
                                  name: p,
                                  value: p,
                                };
                              })
                            );
                            setTabScreenBarCode(1);
                            setBarcodeStatus(true);
                          });
                        } else {
                          dispatch(
                            setAlertWithTitle({
                              title: "Alert",
                              msg: "Please select item to print barcode",
                            })
                          );
                        }
                      }}
                    />
                    <LocalButton
                      bg={"#1f57d6"}
                      ml={4}
                      color={"white"}
                      title={"Upload Image"}
                      onClick={() => {
                        setUploadImgStatus(true);
                      }}
                      onPrintClick={() => {
                        if (data.ICODE && data.ICODE !== "0") {
                          connectPrinterAndList().then((printers) => {
                            setPrinterList(
                              printers.map((p) => {
                                return {
                                  name: p,
                                  value: p,
                                };
                              })
                            );
                            setTabScreenBarCode(1);
                            setBarcodeStatus(true);
                          });
                        } else {
                          dispatch(
                            setAlertWithTitle({
                              title: "Alert",
                              msg: "Please select item to print barcode",
                            })
                          );
                        }
                      }}
                    />
                  </>
                )}
              </div>
            )}
          </div>
        </div>

        <ItemMasterHelp
          onRowClick={(e) => {
            const i = e.row;
            setdata({
              ...i,
              table_prefix: prefix,

              OPBAL_DATE: i.OPBAL_DATE
                ? moment(i.OPBAL_DATE).format("YYYY-MM-DD")
                : moment().format("YYYY-MM-DD"),
            });
            const icode = i.ICODE;
            const path = `retailx/items/${prefix + icode}`;
            DownloadFile(path)
              .then((e) => {
                if (!e.includes("FirebaseError")) {
                  setFirebaseUrl(e);
                  setpath(path);
                } else {
                  setpath("");

                  setFirebaseUrl("");
                }
              })
              .catch((e) => {
                // setCapturedImage('');
                setFirebaseUrl("");
              });
            setItemMasterHelpStatus(false);
          }}
          status={itemMasterHelpStatus}
          onClose={() => {
            setItemMasterHelpStatus(false);
          }}
        />

        <UploadImagesModal
          isOpen={uploadImgModalStatus}
          onClose={() => {
            setUploadImgStatus(false);
            setUploadImgList([]);
          }}
          setdata={setdata}
          data={data}
          setUploadImgStatus={setUploadImgStatus}
          setUploadImgList={setUploadImgList}
        />
        <SectionMasterHelp
          onRowClick={(row) => {
            setSectionMaterHelpStatus(false);
            setdata((e) => {
              return {
                ...e,
                SECTION_NAME: row.row.SECTION_NAME,
              };
            });
          }}
          status={sectionMasterhelpStatus}
          onClose={() => {
            setSectionMaterHelpStatus(false);
          }}
        />
        <SubSecMasterHelp
          onRowClick={(row) => {
            setSubSectionMaterHelpStatus(false);
            setdata((e) => {
              return {
                ...e,
                SUB_SEC_NAME: row.row.SUB_SEC_NAME,
              };
            });
          }}
          section={data?.SECTION_NAME}
          status={subSectionMasterhelpStatus}
          onClose={() => {
            setSubSectionMaterHelpStatus(false);
          }}
        />

        <DepartmentMasterHelp
          onRowClick={(row) => {
            setDepartmentMasterHelpStatus(false);
            setdata((e) => {
              return {
                ...e,
                DEP_NAME: row.row.DEP_NAME,
              };
            });
          }}
          status={departMentMasterHelpStatus}
          onClose={() => {
            setDepartmentMasterHelpStatus(false);
          }}
        />

        <HsnMasterHelp
          onRowClick={(row) => {
            setHsnMasterHelpStatus(false);
            setdata((e) => {
              return {
                ...e,
                HSN_CODE: row.row.HSN_CODE,
                TAX2_RATE: row.row.TAX_RATE / 2,
                TAX1_RATE: row.row.TAX_RATE / 2,
                TAX3_RATE: row.row.TAX_RATE,
              };
            });
          }}
          status={HsnMasterHelpStatus}
          onClose={() => {
            setHsnMasterHelpStatus(false);
          }}
        />

        {uploadImgStatus && (
          <UploadImageModal
            setRefreshItems={setRefreshItems}
            setItemImgList={setItemImgList}
            uploadImgStatus={uploadImgStatus}
            setUploadImghStatus={setUploadImghStatus}
            selectedItem={selectedItem}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            onCancel={() => {
              setUploadImghStatus(false);
              setSelectedFile("");
            }}
            tabScreen={tabScreen}
            setFirebaseUrl={setFirebaseUrl}
            code={uploadImgCode}
          />
        )}
      </div>

      <EmptyModal
        status={barCodeStatus}
        onClose={setBarcodeStatus}
        data={
          <div className="border text-black  bg-white h-full  rounded-sm w-full ">
            <div className="flex justify-between rounded-t-sm bg-slate-100">
              <div className="flex items-center pl-3 text-sm font-semibold">
                {data.ITEMNAME} - Bar Code
              </div>
              <button
                onClick={() => {
                  setBarcodeStatus(false);
                }}
                className="w-fit p-1 "
              >
                <Close
                  sx={{
                    color: "red",
                  }}
                />
              </button>
            </div>
            <div className="p-2">
              <div className="flex justify-end pb-2">
                <div className="flex ">
                  <button
                    onClick={() => {
                      setTabScreenBarCode((b) => (b === 1 ? 2 : 1));
                    }}
                    className="text-sm text-gray-500"
                  >
                    {tabScreenBarCode === 1
                      ? " Test Prn File"
                      : "Bar Code Print"}
                  </button>
                </div>
              </div>
              {tabScreenBarCode === 1 ? (
                <>
                  <div className="flex">
                    <div className="w-[150px] pr-2">Printer</div>:
                    <select
                      onChange={(e) => {
                        setSelectedPrinter(e.target.value);
                      }}
                      value={selectedPrinter}
                      className="outline-none mb-2 ml-2 border rounded-sm p-1"
                    >
                      {printerList.map((d, key) => (
                        <option key={key} value={d.value}>
                          {d.value}
                        </option>
                      ))}
                    </select>{" "}
                  </div>

                  <div className="flex">
                    <div className="w-[150px] pr-2">Size</div>:
                    <select
                      onChange={handleSelectChange}
                      value={selectedSize}
                      className="outline-none mb-2 border ml-2 rounded-sm p-1"
                    >
                      {Object.keys(styles).map((key) => {
                        return (
                          <option value={key} key={key}>
                            {styles[key].name}
                          </option>
                        );
                      })}
                    </select>{" "}
                  </div>
                  <div className="flex ">
                    <div className="w-[150px] pr-2">Qnty</div>:
                    <div className=" border-[#dbd9d9] ml-2 rounded-sm border-r hover:cursor-pointer border h-[35px]   w-[110px]  flex items-center">
                      <div
                        onClick={() => {
                          if (data?.barQnty ? data.barQnty : 1 > 1) {
                            setdata((d) => {
                              return {
                                ...d,
                                barQnty: Number(d?.barQnty ? d.barQnty : 1) - 1,
                              };
                            });
                          }
                        }}
                        className="px-2  h-full flex items-center bg-gray-100"
                      >
                        <Remove
                          sx={{
                            fontSize: "12px",
                          }}
                        />
                      </div>
                      <input
                        type="number"
                        value={data?.barQnty ? data.barQnty : 1}
                        onChange={(e) => {
                          setdata((d) => {
                            return {
                              ...d,
                              barQnty: Number(e.target.value),
                            };
                          });
                        }}
                        placeholder="Pieces"
                        className="w-full  text-center py-1 bg-transparent outline-none"
                      />
                      <div
                        onClick={() => {
                          setdata((d) => {
                            return {
                              ...d,
                              barQnty: Number(d?.barQnty ? d.barQnty : 1) + 1,
                            };
                          });
                        }}
                        className="px-2 border-l h-full flex items-center bg-gray-100"
                      >
                        <Add
                          sx={{
                            fontSize: "12px",
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex ">
                    <div className="w-[150px] pr-2">Paking Date</div>:
                    <div className="mt-2 border-[#dbd9d9] ml-2 rounded-sm border-r hover:cursor-pointer border h-[35px]   w-[110px]  flex items-center">
                      <input
                        type="date"
                        value={
                          prnExtraData?.packingDate
                            ? moment(prnExtraData?.packingDate).format(
                                "YYYY-MM-DD"
                              )
                            : moment().format("YYYY-MM-DD")
                        }
                        onChange={(e) => {
                          setPrnExtraData((d) => {
                            return {
                              ...d,
                              packingDate: e.target.value,
                            };
                          });
                        }}
                        className="w-full  text-center py-1 bg-transparent outline-none"
                      />
                    </div>
                  </div>
                  <div className="flex ">
                    <div className="w-[150px] pr-2">Expiry Date</div>:
                    <div className="mt-2 border-[#dbd9d9] ml-2 rounded-sm border-r hover:cursor-pointer border h-[35px]   w-[110px]  flex items-center">
                      <input
                        type="date"
                        value={
                          prnExtraData?.expiryDate
                            ? moment(prnExtraData?.expiryDate).format(
                                "YYYY-MM-DD"
                              )
                            : moment().format("YYYY-MM-DD")
                        }
                        onChange={(e) => {
                          setPrnExtraData((d) => {
                            return {
                              ...d,
                              expiryDate: e.target.value,
                            };
                          });
                        }}
                        className="w-full  text-center py-1 bg-transparent outline-none"
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div>
                  <div className="min-w-[460px] min-h-[100px] ">
                    <textarea
                      value={testPnrFile}
                      placeholder="Paste your pnr file"
                      onChange={(e) => {
                        setTestPnrFile(e.target.value);
                      }}
                      className="p-2"
                      style={{
                        width: "100%",
                        border: "1px solid gray",
                        outline: "none",
                        height: 500,
                      }}
                    />
                  </div>
                </div>
              )}
              {console.log(testPnrFile)}
              <div className="flex justify-end">
                <div className="ml-3">
                  <button
                    onClick={handlePrint}
                    className=" bg-black h-[35px] px-3 text-sm rounded-sm text-white"
                  >
                    {tabScreenBarCode === 1
                      ? "Print Bar Code"
                      : "Test Pnr File"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        }
      />
      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
    </div>
  );
};

export default ItemMaster;
