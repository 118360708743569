"use client";
import React, { useState } from "react";

const faqs = [
  {
    question: "What is Retailx?",
    answer:
      "Retailx is a comprehensive web application designed to streamline retail billing, inventory, and stock management processes. It is suitable for any retail business looking for efficient and organized operations.",
  },
  {
    question: "What platforms and devices is Retailx compatible with?",
    answer:
      "Retailx works on all major operating systems, including Windows, macOS, and Android. This flexibility ensures it can be accessed on desktops, laptops, and mobile devices.",
  },
  {
    question: "How does Retailx help with inventory management?",
    answer:
      "Retailx provides real-time tracking of inventory, allowing users to monitor stock levels, set reorder alerts, and manage stock adjustments efficiently. It helps avoid stockouts and overstocking by automating inventory processes.",
  },
  {
    question: "Can Retailx handle multiple store locations?",
    answer:
      "Yes, Retailx is designed to support businesses with multiple locations. You can manage stock, sales, and customer data across all stores from a single platform.",
  },
  {
    question: "What kind of reporting features does Retailx offer?",
    answer:
      "Retailx offers various reports for sales, inventory, customer insights, and employee performance. These reports provide data-driven insights to help make informed decisions and track business performance over time.",
  },
  {
    question: "Is my data secure on Retailx?",
    answer:
      "Retailx prioritizes data security with encryption, secure login credentials, and regular backups to safeguard your data. Only authorized users can access sensitive information.",
  },
  {
    question: "Does Retailx support barcode scanning?",
    answer:
      "Yes, Retailx is compatible with barcode scanners, allowing for quick product lookup and efficient checkout processing.",
  },
  {
    question:
      "Can I manage customer information and loyalty programs with Retailx?",
    answer:
      "Absolutely. Retailx has a customer management system to store contact information, track purchase history, and manage loyalty points or rewards programs.",
  },
  {
    question: "Is Retailx easy to set up and use?",
    answer:
      "Retailx has a user-friendly interface and an intuitive setup process. Training materials and customer support are available to help new users get up and running smoothly.",
  },
  {
    question: "Does Retailx have a mobile app?",
    answer:
      "While Retailx is web-based and accessible on any mobile browser, an Android app is available for optimized mobile usage, enabling staff to manage tasks on the go.",
  },
  {
    question: "How is pricing structured for Retailx?",
    answer:
      "Retailx offers various pricing plans based on the number of users and features required. For specific details, please contact the Retailx sales team or visit the pricing page on our website.",
  },
  {
    question: "How does Retailx handle returns and exchanges?",
    answer:
      "Retailx includes easy-to-use options for processing returns and exchanges. It keeps records of each transaction, adjusting inventory and refund balances as needed.",
  },
  {
    question:
      "Can I integrate Retailx with other software or accounting systems?",
    answer:
      "Yes, Retailx offers integration capabilities with popular accounting and e-commerce platforms, making it easy to sync data and streamline workflows.",
  },
  {
    question: "What support options are available for Retailx users?",
    answer:
      "Retailx offers 12/6 customer support, including ticket, email, and phone options, along with an online knowledge base for quick troubleshooting and guidance.",
  },
];
const Faq = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [showMore, setShowMore] = useState(false); // State to control "Show more"

  const toggleFaq = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div className=" mx-auto  flex ls:flex-row flex-col py-[60px]">
      <div className="text-[#363435] md:text-[36px] ms:text-[24px] leading-[51px] font-bold mb-6 ms:px-10 ls:px-20">
        <div className=" flex-row">
          <div className="mr-1 ls:mr-0">Frequently </div>
          <div className="mr-1 ls:mr-0">Asked</div>
          <div className="mr-1 ls:mr-0">Questions</div>
        </div>
      </div>
      <div className="flex flex-1 px-10">
        <div className="md:space-y-4 w-full">
          {/* Render only first 5 FAQs initially */}
          {faqs.slice(0, 5).map((faq, index) => (
            <div
              key={index}
              className=" py-4 cursor-pointer"
              onClick={() => toggleFaq(index)}
            >
              <div className="flex justify-between items-center">
                <h3 className="text-[18px] font-[500] ms:text-[#5f5e5e] md:text-[#000000]">
                  {faq.question}
                </h3>
                <span className="text-[18px]  font-[500] text-[#000000]">
                  {openIndex === index ? "-" : "+"}
                </span>
              </div>
              {openIndex === index && (
                <p className="mt-2 text-[14px] text-gray-600">{faq.answer}</p>
              )}
            </div>
          ))}

          {/* Conditionally render more FAQs based on `showMore` state */}
          {showMore &&
            faqs.slice(5).map((faq, index) => (
              <div
                key={index + 5} // Adjusted key for remaining FAQs
                className=" py-4 cursor-pointer"
                style={{
                  zIndex: 10000,
                }}
                onClick={() => toggleFaq(index + 5)} // Adjusted index for remaining FAQs
              >
                <div className="flex justify-between items-center">
                  <h3 className="text-[18px] font-bold text-[#000000]">
                    {faq.question}
                  </h3>
                  <span className="text-xl font-semibold text-gray-500">
                    {openIndex === index + 5 ? "-" : "+"}
                  </span>
                </div>
                {openIndex === index + 5 && (
                  <p className="mt-2 text-[14px] text-gray-600">{faq.answer}</p>
                )}
              </div>
            ))}

          {/* "Show more" button */}
          <div className="flex justify-center mt-4">
            <button onClick={toggleShowMore} className="text-[#fd7f19] ">
              {showMore ? "Show less" : "Show more"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
