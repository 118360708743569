// Import Firebase and initialize it with your config (as shown in previous response)
import { initializeApp } from "firebase/app";
import imageCompression from "browser-image-compression";

import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { setSpinnerLoading } from "../Redux/modal";
import { getDatabase } from "firebase/database"; // Import the getDatabase function

import { getAuth, onAuthStateChanged, signInAnonymously } from "firebase/auth";
import { uploadToCloudinary } from "./common";
// Initialize Firebase with your config
const firebaseConfig = {
  apiKey: "AIzaSyC6Zpz7_De9jeUnEYu6v-vZISHqoJFGQpo",
  authDomain: "retailx-126e1.firebaseapp.com",
  projectId: "retailx-126e1",
  storageBucket: "retailx-126e1.appspot.com",
  messagingSenderId: "63087371523",
  appId: "1:63087371523:web:02caee9d6e73bdf9387536",
  measurementId: "G-LVZ68XQYEW",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
signInAnonymously(auth)
  .then(() => {
    console.log("sign");
  })
  .catch((error) => {
    console.log(error);
    // ...
  });
onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/auth.user
    console.log("signin");
    // ...
  } else {
    // User is signed out
    // ...
    console.log("signout");
  }
});
const database = getDatabase(app);

// Get a reference to Firebase Storage
const storage = getStorage(app);

// const UploadFile = async (file, path, name, dispatch) => {
//   console.log("Uploading Path:", path + name);

//   const options = {
//     maxSizeMB: 1, // Max size in MB
//     maxWidthOrHeight: 270, // Resize image
//     useWebWorker: true,
//     alwaysKeepResolution: true, // Ensures it sticks to the exact resolution
//   };

//   try {
//     if (dispatch) dispatch(setSpinnerLoading("Uploading..."));

//     // Compress file
//     const compressedFile = await imageCompression(file, options);

//     // Upload to Cloudinary (Convert to AVIF)
//     const cloudinaryAvifUrl = await uploadToCloudinary(compressedFile);

//     // Fetch AVIF file as a blob
//     const avifResponse = await fetch(cloudinaryAvifUrl);
//     const avifBlob = await avifResponse.blob();

//     // Upload AVIF to Firebase
//     const storageRef = ref(storage, `${path}${name}`);
//     const snapshot = await uploadBytes(storageRef, avifBlob);
//     const firebaseUrl = await getDownloadURL(snapshot.ref);

//     if (dispatch) dispatch(setSpinnerLoading(false));

//     console.log("Cloudinary AVIF URL:", cloudinaryAvifUrl);
//     console.log("Firebase URL:", firebaseUrl);

//     return { cloudinaryAvifUrl, firebaseUrl };
//   } catch (error) {
//     console.error("Upload failed:", error);
//     if (dispatch) dispatch(setSpinnerLoading(false));
//     throw error;
//   }
// };

const UploadFile = async (file, path, name, dispatch) => {
  console.log("uploadingPath", path + name);
  const options = {
    maxSizeMB: 1, // Max size in MB
    maxWidthOrHeight: 270, // Resize image
    useWebWorker: true,
    alwaysKeepResolution: true, // Ensures it sticks to the exact resolution
  };

  const compressedFile = await imageCompression(file, options);

  if (dispatch) {
    dispatch(setSpinnerLoading("Uploading"));
  }

  const storageRef = ref(storage, path + name);
  try {
    console.log("Uploading to Firebase Storage...");
    const snapshot = await uploadBytes(storageRef, compressedFile);

    if (dispatch) {
      dispatch(setSpinnerLoading(false));
    }

    return snapshot;
  } catch (error) {
    console.error("Error uploading file:", error);

    if (dispatch) {
      dispatch(setSpinnerLoading(false));
    }

    throw error;
  }
};

const UploadFileFull = async (file, path, name, dispatch) => {
  console.log("uploadingPath", path + name);
  const options = {
    maxSizeMB: 1, // Max size in MB
    maxWidthOrHeight: 270, // Resize image
    useWebWorker: true,
    alwaysKeepResolution: true, // Ensures it sticks to the exact resolution
  };

  if (dispatch) {
    dispatch(setSpinnerLoading("Uploading"));
  }

  const storageRef = ref(storage, path + name);
  try {
    console.log("Uploading to Firebase Storage...");
    const snapshot = await uploadBytes(storageRef, file);

    if (dispatch) {
      dispatch(setSpinnerLoading(false));
    }

    return snapshot;
  } catch (error) {
    console.error("Error uploading file:", error);

    if (dispatch) {
      dispatch(setSpinnerLoading(false));
    }

    throw error;
  }
};
async function deleteFile(path) {
  const storageRef = ref(storage, path);

  try {
    // Delete the file
    await deleteObject(storageRef);
    console.log("File deleted successfully");
  } catch (error) {
    console.error("Error deleting file:", error);
    throw error;
  }
}

export const renameFile = async (oldPath, newPath, name) => {
  // Get the old file's data
  DownloadFile(oldPath)
    .then(async (url) => {
      const response = await fetch(url);
      const fileBlob = await response.blob();

      // Reference the new file location

      // Upload the file with the new name
      UploadFile(fileBlob, newPath, name);
      // await uploadBytes(newRef, fileBlob);

      // Delete the old file
      // await deleteFile(oldPath);

      console.log(`File renamed from ${oldPath} to ${newPath}`);
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      console.log("final");
    });
};

const DownloadFile = async (path) => {
  console.log(`Downloading ${path}`);
  const imageRef = ref(storage, path);
  try {
    const imageUrl = await getDownloadURL(imageRef);

    return imageUrl;
    // Now you can use `imageUrl` to display the image in your application.
  } catch (error) {
    return error;
  }
};

export { UploadFile, DownloadFile, deleteFile, database, UploadFileFull };
