import React from "react";
import reatilIcon from "../utility/Logo/icons/blackIcon.svg";
import blackText from "../utility/Logo/TextLogo/blackTextLogo.svg";
import whiteText from "../utility/Logo/TextLogo/whiteTextLogo.svg";
import reatilIcon2 from "../utility/Logo/icons/whiteIcon.svg";
const RetailxLogo = ({ type, hideBottom }) => {
  const size = 50;
  return (
    <div>
      <div
        style={{
          color: type ? type : "#272626",
        }}
        className="flex justify-center items-center  "
      >
        <img
          src={
            type
              ? reatilIcon2
              : "https://firebasestorage.googleapis.com/v0/b/retailx-126e1.appspot.com/o/retailx%2Fapp%2Fblack-icon?alt=media&token=3c355e16-e7bd-449a-a729-b39ac1dacb1e"
          }
          alt=""
          height={size}
          className="pt-2"
        />
        <img
          src={
            type
              ? whiteText
              : "https://firebasestorage.googleapis.com/v0/b/retailx-126e1.appspot.com/o/retailx%2Fapp%2Fblack-text?alt=media&token=3c355e16-e7bd-449a-a729-b39ac1dacb1e"
          }
          alt=""
          style={{
            height: 50,
          }}
          className="ml-2"
        />

        {/* <div className="  justify-start  flex flex-col  ">
          <div className="flex font-constantia -mt-2">
            <div className="text-[34px] font-bold ">Retail</div>
            <div className="text-[21px]   pt-[16px]">x</div>
          </div>

          {!hideBottom && (
            <div className="text-[12px] -mt-2 sm:ml-1 font-constantia ">
              Bill. Manage. Grow.
            </div>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default RetailxLogo;
