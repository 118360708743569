import React, { useEffect, useRef, useState } from "react";
import ProfilePicFrame from "../../utility/component/styles/profilePicFrame";
import UploadImageButtons from "../../utility/component/UploadImageButtons";
import { DownloadFile, UploadFile } from "../../utility/functions/firebase";
import { updateItemImages } from "./func";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "../../utility/Redux/security";
import spinner from "../../utility/json/spinerWhite.json";
import Lottie from "lottie-react";
import { CallAPIPromise } from "../../billing/common";
import axios from "axios";
import { selectPrefix } from "../../utility/Redux/profile";

const UploadImagesModal = ({
  isOpen,
  setUploadImgStatus,
  onClose,
  data,
  setdata,
  setUploadImgList,
}) => {
  const [images, setImages] = useState([]);
  const [customerFirebasePic, setFirebaseUrl] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [webcamStatus, setWebcamStatus] = useState("");
  const [capturedImage, setCapturedImage] = useState("");
  const [Path, setpath] = useState("");
  const [loading, setLoading] = useState(false);
  const [customerFirebasePic2, setFirebaseUrl2] = useState("");
  const [selectedFile2, setSelectedFile2] = useState("");
  const [webcamStatus2, setWebcamStatus2] = useState("");
  const [capturedImage2, setCapturedImage2] = useState("");
  const [Path2, setpath2] = useState("");
  const [reletedImgs, setRelatedImgs] = useState([]);
  const [customerFirebasePic3, setFirebaseUrl3] = useState("");
  const [selectedFile3, setSelectedFile3] = useState("");
  const [webcamStatus3, setWebcamStatus3] = useState("");
  const [capturedImage3, setCapturedImage3] = useState("");
  const [Path3, setpath3] = useState("");
  const [searchImgList, setSearchImgList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const webcamRef = useRef(null);
  const webcamRef2 = useRef(null);
  const webcamRef3 = useRef(null);
  const token = useSelector(selectToken);
  const prefix = useSelector(selectPrefix);
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    setLoading(true);
    const camera = capturedImage
      ? fetch(capturedImage).then((response) => response.blob())
      : "";
    const file1 = customerFirebasePic
      ? customerFirebasePic
      : selectedFile
      ? selectedFile
      : camera;

    const camera2 = capturedImage2
      ? fetch(capturedImage2).then((response) => response.blob())
      : "";
    const file2 = customerFirebasePic2
      ? customerFirebasePic2
      : selectedFile2
      ? selectedFile2
      : camera2;

    const camera3 = capturedImage3
      ? fetch(capturedImage3).then((response) => response.blob())
      : "";
    const file3 = customerFirebasePic3
      ? customerFirebasePic3
      : selectedFile3
      ? selectedFile3
      : camera3;
    const imgList = [file1, file2, file3];

    setImages(imgList);
    if (data.ICODE) {
      setUploadImgList([]);
      let firebaseImgLink = [];
      for (let index = 0; index < imgList.length; index++) {
        const i = imgList[index];

        if (i) {
          const name =
            index === 0
              ? prefix.replace(/_.+_/, "_") + data.ICODE
              : prefix.replace(/_.+_/, "_") + data.ICODE + `_${index + 1}`;
          const path = "retailx/items/";

          try {
            if (typeof i === "string") {
              firebaseImgLink[index] = i; // Update the corresponding index
            } else {
              await UploadFile(i, path, name);
              // Get the download URL
              const url = await DownloadFile(path + name);
              firebaseImgLink[index] = url; // Update the corresponding index
            }
            // Upload the file
          } catch (error) {
            console.error(`Error uploading image at index ${index}:`, error);
          }
        } else {
          console.log(
            `Image at index ${index} is not available or upload canceled.`
          );
        }
      }

      // Once all images are processed, update state and backend
      setImages(firebaseImgLink);
      updateItemImages(
        firebaseImgLink,
        prefix,
        data.ICODE,
        token,
        dispatch
      ).finally(() => {
        setLoading(false);
        setUploadImgStatus(false);
      });
    } else {
      setUploadImgList(imgList);
      setUploadImgStatus(false);
      setLoading(false);
    }
  };
  const handlePexelesSearch = async () => {
    const PEXELS_API_URL = "https://api.pexels.com/v1/search";
    const PEXELS_API_KEY =
      "sIQFj6AGEmTRP1yXJQqQo4dotB5WNAWaPBCnF5ApWgkCssU4wg4dh9jN"; // Replace with your Pexels API key

    try {
      const response = await axios.get(PEXELS_API_URL, {
        params: {
          query: searchText ? searchText : data.ITEMNAME,
        },
        headers: {
          Authorization: PEXELS_API_KEY,
        },
      });
      if (response.status === 200) {
        setSearchImgList(response.data.photos);
        CallAPIPromise(
          "/api/images/listImages",
          {
            image_tag: searchText ? searchText : data.ITEMNAME.toLowerCase(),
          },
          token,
          dispatch
        ).then((res) => {
          if (res?.data?.images) {
            setRelatedImgs(res.data.images);
          }
        });
      }
    } catch (err) {
      console.log("Error fetching images. Please try again.");
    }
  };

  useEffect(() => {
    if (isOpen) {
      setRelatedImgs([]);

      setSelectedFile("");
      setSelectedFile2("");
      setSelectedFile3("");
      handlePexelesSearch();
      setSearchText("");
    }
  }, [isOpen]);
  const handleDrop = async (e, setSelectedFile, setFirebaseUrl) => {
    // Prevent default behavior (Prevent opening the image)
    e.preventDefault();

    // Get the image URL from the dataTransfer object
    const imageUrl = e.dataTransfer.getData("imageUrl");
    if (imageUrl.includes("https://firebasestorage.googleapis.com")) {
      setSelectedFile("");
      setFirebaseUrl(imageUrl);

      return;
    }
    const response = await fetch(imageUrl);
    const fileBlob = await response.blob();
    setFirebaseUrl("");

    setSelectedFile(fileBlob);
    // Set the  image URL to the ProfilePicFrame (or trigger the necessary state change)
    // setSelectedFile(imageUrl); // Assuming setSelectedFile is the function used to set the selected image in ProfilePicFrame
  };

  const handleDragOver = (e) => {
    // Prevent default behavior to allow the drop
    e.preventDefault();
  };

  useEffect(() => {
    if (isOpen) {
      setFirebaseUrl(data.IMAGE_1);
      setFirebaseUrl2(data.IMAGE_2);
      setFirebaseUrl3(data.IMAGE_3);
    }
  }, [isOpen]);
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-6 ">
        <div className="flex justify-between ">
          <h2 className="text-xl font-semibold ">
            Search & Update Item Images
          </h2>
          <button fontSize={"medium"} onClick={onClose}>
            {" "}
            <i className="bi bi-x-square-fill  text-[22px] text-red-500 font-bold p-1"></i>
          </button>
        </div>
        <div className="text-red-500 font-semibold text-[15px] mb-2 ">
          {data.ITEMNAME}
        </div>
        <div className="flex border flex-1 rounded-sm overflow-hidden">
          <input
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter") {
                handlePexelesSearch();
              }
            }}
            className="flex flex-1 p-2 outline-none"
          />
          <button
            onClick={handlePexelesSearch}
            className="bg-orange px-2 text-black"
          >
            Search Images
          </button>
        </div>
        <div className="flex mt-3">
          <div>
            <div className="flex">
              <div>
                <div
                  style={{
                    border: "1px solid #b5b3b3",
                    width: "320px",
                    height: "320px",
                  }}
                  onDrop={(e) => {
                    handleDrop(e, setSelectedFile, setFirebaseUrl);
                  }}
                  onDragOver={handleDragOver}
                  className=" p-2 rounded-sm mt-3  "
                >
                  <ProfilePicFrame
                    customerFirebasePic={customerFirebasePic}
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                    webcamStatus={webcamStatus}
                    setWebcamStatus={setWebcamStatus}
                    capturedImage={capturedImage}
                    setCapturedImage={setCapturedImage}
                    webcamRef={webcamRef}
                    path={`retailx/items/${
                      prefix.replace(/_.+_/, "_") + data.ICODE
                    }`}
                    afterDelete={() => {
                      setFirebaseUrl("");
                      updateItemImages(
                        ["", customerFirebasePic2, customerFirebasePic3],
                        prefix,
                        data.ICODE,
                        token,
                        dispatch
                      ).then(() => {
                        setdata((d) => {
                          return {
                            ...d,
                            IMAGE_1: "",
                          };
                        });
                      });
                    }}
                    setFirebaseUrl={setFirebaseUrl}
                  />
                </div>
                <div
                  className="flex mt-2"
                  style={{
                    width: "320px",
                  }}
                >
                  <UploadImageButtons
                    title={"Upload Image"}
                    setSelectedFile={setSelectedFile}
                    webcamStatus={webcamStatus}
                    setWebcamStatus={setWebcamStatus}
                  />
                </div>
              </div>
              <div className="ml-4">
                <div className="">
                  <div
                    style={{
                      border: "1px solid #b5b3b3",
                      width: "130px",
                      height: "130px",
                    }}
                    onDrop={(e) => {
                      handleDrop(e, setSelectedFile2, setFirebaseUrl2);
                    }}
                    onDragOver={handleDragOver}
                    className=" p-2 rounded-sm mt-3  "
                  >
                    <ProfilePicFrame
                      customerFirebasePic={customerFirebasePic2}
                      selectedFile={selectedFile2}
                      setSelectedFile={setSelectedFile2}
                      webcamStatus={webcamStatus2}
                      setWebcamStatus={setWebcamStatus2}
                      capturedImage={capturedImage2}
                      setCapturedImage={setCapturedImage2}
                      webcamRef={webcamRef2}
                      path={`retailx/items/${
                        prefix.replace(/_.+_/, "_") + data.ICODE + "_2"
                      }`}
                      afterDelete={() => {
                        setFirebaseUrl2("");
                        updateItemImages(
                          [customerFirebasePic, "", customerFirebasePic3],
                          prefix,
                          data.ICODE,
                          token,
                          dispatch
                        ).then(() => {
                          console.log("hii");
                          setdata((d) => {
                            return {
                              ...d,
                              IMAGE_2: "",
                            };
                          });
                        });
                      }}
                      setFirebaseUrl={setFirebaseUrl2}
                    />
                  </div>
                  <div
                    className="flex mt-2"
                    style={{
                      width: "130px",
                    }}
                  >
                    <UploadImageButtons
                      title={"Upload Image"}
                      setSelectedFile={setSelectedFile2}
                      webcamStatus={webcamStatus2}
                      setWebcamStatus={setWebcamStatus2}
                    />
                  </div>
                </div>

                <div className="">
                  <div
                    style={{
                      border: "1px solid #b5b3b3",
                      width: "130px",
                      height: "130px",
                    }}
                    onDrop={(e) => {
                      handleDrop(e, setSelectedFile3, setFirebaseUrl3);
                    }}
                    onDragOver={handleDragOver}
                    className=" p-2 rounded-sm mt-3  "
                  >
                    <ProfilePicFrame
                      customerFirebasePic={customerFirebasePic3}
                      selectedFile={selectedFile3}
                      setSelectedFile={setSelectedFile3}
                      webcamStatus={webcamStatus3}
                      setWebcamStatus={setWebcamStatus3}
                      capturedImage={capturedImage3}
                      setCapturedImage={setCapturedImage3}
                      webcamRef={webcamRef3}
                      path={`retailx/items/${
                        prefix.replace(/_.+_/, "_") + data.ICODE + "_3"
                      }`}
                      afterDelete={() => {
                        setFirebaseUrl3("");
                        updateItemImages(
                          [customerFirebasePic, customerFirebasePic2, ""],
                          prefix,
                          data.ICODE,
                          token,
                          dispatch
                        ).then(() => {
                          setdata((d) => {
                            return {
                              ...d,
                              IMAGE_3: "",
                            };
                          });
                        });
                      }}
                      setFirebaseUrl={setFirebaseUrl3}
                    />
                  </div>
                  <div
                    className="flex mt-2"
                    style={{
                      width: "130px",
                    }}
                  >
                    <UploadImageButtons
                      title={"Upload Image"}
                      setSelectedFile={setSelectedFile3}
                      webcamStatus={webcamStatus3}
                      setWebcamStatus={setWebcamStatus3}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                height: "100px",
              }}
              className="overflow-y-auto mt-4"
            >
              <div className="grid grid-cols-4  gap-3  ">
                {reletedImgs.map((i) => {
                  return (
                    <div
                      style={{
                        height: 100,
                        width: 100,
                        border: "1px dashed gray",
                      }}
                      className="flex justify-center items-center"
                      onDragStart={(e) => {
                        // Store the image URL in the dataTransfer object
                        e.dataTransfer.setData("imageUrl", e.target.src);
                      }}
                    >
                      <img
                        style={{
                          width: 80,
                          height: 80,
                        }}
                        className="overflow-hidden"
                        src={`https://firebasestorage.googleapis.com/v0/b/retailx-126e1.appspot.com/o/retailx%2Fimage_library%2F${i.IMAGE_ID}?alt=media&token=f7547088-289b-4819-a479-2304e392be86`}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="ml-2">
            <div>Pexels Images</div>
            <div className="overflow-y-auto flex-1 ll:h-[580px] h-[200px] ">
              <div className="grid grid-cols-1 gap-3">
                {searchImgList.map((i) => {
                  return (
                    <div>
                      <img
                        onDragStart={(e) => {
                          // Store the image URL in the dataTransfer object
                          e.dataTransfer.setData("imageUrl", e.target.src);
                        }}
                        src={i.src.tiny}
                        alt=""
                        className="w-[100px] h-[100px] object-cover mb-4"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 flex"
          >
            {loading && (
              <div className="mr-2">
                <div className="h-[18px] w-[18px]  flex items-center ">
                  <Lottie
                    animationData={spinner}
                    loop={true}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  />
                </div>
              </div>
            )}
            <div>Save Image</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadImagesModal;
