import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  selectPrefix,
  selectWidth,
  selectreportFromDate,
  selectreportToDate,
  setreportsFromDate,
  setreportsTodate,
} from "../../utility/Redux/profile";
import { FromToDate } from "../../utility/component/fromToDate";
import { GetSaleSummary } from "./func";
import { selectToken } from "../../utility/Redux/security";
import { SummaryColumns } from "./columns";
import Footer from "./footer";
import { useReactToPrint } from "react-to-print";
import GeniusTableReports from "../../utility/component/geniusComponents/geniusTableData";
import { VoucherPrintColumns } from "./printColumns";
import VoucherPrint from "../../print/voucher";
import IsLocalData from "../../utility/component/isLocalData";
import FromToDateMobile from "../../utility/component/fromToDateMobile";
import GeniusTableForReports from "../../utility/component/printReport";
import EmployeeHelp from "../../help/employeeHelp";
import {
  setAlertWithTitle,
  setSpinnerLoading,
} from "../../utility/Redux/modal";
import { SaveButton } from "../../utility/component/buttons";
import { CallAPIPromise } from "../../billing/common";

const PendingDeliverdPayments = () => {
  const prefix = useSelector(selectPrefix);
  const [summaryList, setSummaryList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [employeeDetails, setEmployeeDetails] = useState("");
  const [helpStatus, setHelpStatus] = useState(false);
  const fromDate = useSelector(selectreportFromDate);
  const ToDate = useSelector(selectreportToDate);
  const width = useSelector(selectWidth);
  const printRef = useRef(null);
  const [getCustomerData, setCustomerData] = useState({
    table_prefix: prefix,
    FDATE: moment(fromDate).format("YYYY-MM-DD"),
    TDATE: moment(ToDate).format("YYYY-MM-DD"),
    EMPLOYEE_CODE: 1,
  });
  const [voucherNo, setVoucherNo] = useState("");
  const handleSelect = (item) => {
    // Check if the item is already selected
    const isSelected = selectedList.some(
      (selectedItem) => selectedItem.INVNO === item.INVNO
    );

    if (isSelected) {
      // Remove item from selected list
      setSelectedList(
        selectedList.filter((selectedItem) => selectedItem.INVNO !== item.INVNO)
      );
    } else {
      // Add item to selected list
      setSelectedList([...selectedList, item]);
    }
  };
  console.log(selectedList);
  const columns = SummaryColumns(width, selectedList, handleSelect);
  const printColumns = VoucherPrintColumns(width);

  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const okButton = () => {
    if (employeeDetails.code) {
      setSelectedList([]);

      GetSaleSummary(
        {
          ...getCustomerData,
          EMPLOYEE_CODE: employeeDetails.code,
        },
        token,
        dispatch,
        setSummaryList
      );
    } else {
      dispatch(
        setAlertWithTitle({
          title: "Alert",
          msg: "Please select employee details",
        })
      );
    }
  };
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: `
  @page {
    size: landscape;
  }
`,
  });

  useEffect(() => {
    setCustomerData((old) => {
      return {
        ...old,
        FDATE: moment(fromDate).format("YYYY-MM-DD"),
        TDATE: moment(ToDate).format("YYYY-MM-DD"),
      };
    });
  }, [fromDate, ToDate]);

  useEffect(() => {
    const callback = (details, data) => {
      setCustomerData(details[0]);
      setSummaryList(data);
      dispatch(setreportsFromDate(details[0].FDATE));
      dispatch(setreportsTodate(details[0].TDATE));
    };
    const elseFunc = () => {
      console.log("nothing");
    };
    IsLocalData("listEmployeeOrder", callback, elseFunc);
  }, []);

  return (
    <div className=" bg-white flex flex-col h-full overflow-y-auto   ">
      <div className=" flex flex-col h-full p-3 ">
        <div>
          {window.innerWidth <= 700 ? (
            <>
              <FromToDateMobile
                onEmployeeSearchClick={() => {
                  setHelpStatus(true);
                }}
                EmployeeData={{
                  first_name: employeeDetails
                    ? employeeDetails.employee_name
                    : "",
                }}
                otherButton={
                  <button
                    className="flex items-center shadow-sm rounded-sm mx-2 "
                    style={{
                      backgroundColor: "#a8cf45",
                      paddingLeft: 40,
                      paddingRight: 40,
                      paddingTop: 4,
                      paddingBottom: 4,
                      color: "black",
                      height: "32px",
                    }}
                  >
                    <div className="flex items-center text-sm">Save</div>
                  </button>
                }
                onPrintClick={handlePrint}
                submit={okButton}
              />
            </>
          ) : (
            <FromToDate
              onEmployeeSearchClick={() => {
                setHelpStatus(true);
              }}
              EmployeeData={{
                first_name: employeeDetails
                  ? employeeDetails.employee_name
                  : "",
              }}
              otherButton={
                <button
                  className="flex items-center shadow-sm rounded-sm "
                  style={{
                    backgroundColor: "#a8cf45",
                    paddingLeft: 40,
                    paddingRight: 40,
                    paddingTop: 4,
                    paddingBottom: 4,
                    color: "black",
                    height: "32px",
                  }}
                  onClick={() => {
                    if (selectedList[0]) {
                      dispatch(setSpinnerLoading("Loading"));
                      CallAPIPromise("/api/dispatch/UpdateDriverPayments", {
                        table_prefix: prefix,
                        DELIVERY_AMOUNT_RECEIVED: "Yes",
                        INVNO: selectedList.map((s) => s.INVNO),
                      })
                        .then(() => {
                          setSelectedList([]);
                          dispatch(
                            setAlertWithTitle({
                              title: "Success",
                              msg: "Data has been updated successfully",
                            })
                          );
                        })
                        .finally(() => {
                          dispatch(setSpinnerLoading(false));
                        });
                    } else {
                      dispatch(
                        setAlertWithTitle({
                          title: "Alert",
                          msg: "Please select any data first to save",
                        })
                      );
                    }
                  }}
                >
                  <div className="flex items-center text-sm">Save</div>
                </button>
              }
              hideReset={true}
              onPrintClick={handlePrint}
              submit={okButton}
              reset={() => {
                setSummaryList([]);
              }}
            />
          )}
        </div>

        {summaryList[0] ? (
          <div
            style={{ height: `calc(100% - 0px)`, width: "100%", marginTop: 10 }}
            className=" mb-2  "
          >
            <GeniusTableReports
              onRowDoubleClick={(e) => {
                console.log("click");
                setVoucherNo(e.original.VOUCHER_NO);
              }}
              data={summaryList}
              columns={columns}
              footer={<Footer columns={columns} rows={summaryList} />}
            />

            <div className="w-full mt-3 ">
              <div
                style={{
                  display: "none",
                }}
              >
                <div ref={printRef}>
                  <GeniusTableForReports
                    customeHeader={
                      <div className="items-center  ">
                        <div className="flex flex-col items-center">
                          <div className="mr-1 text-sm font-semibold flex justify-center ">
                            Voucher Summary
                          </div>
                          <div className="flex">
                            <div className="text-sm font-semibold">From :</div>
                            <div className="ml-1 text-sm">
                              {moment(fromDate).format("DD-MM-YYYY")}{" "}
                            </div>
                            <div className="text-sm font-semibold ml-2">
                              To :
                            </div>
                            <div className="ml-1 text-sm">
                              {moment(ToDate).format("DD-MM-YYYY")}{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    data={summaryList}
                    columns={printColumns}
                    marginTop={"120px"}
                    headerWidth={true}
                    footer={
                      <Footer columns={printColumns} rows={summaryList} />
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="h-full flex justify-center items-center text-sm text-gray-400 border mt-3 rounded-sm">
            No Records Found
          </div>
        )}
      </div>

      <VoucherPrint IsVoucher={voucherNo} setVoucherNo={setVoucherNo} />
      <EmployeeHelp
        status={helpStatus}
        onClose={() => {
          setHelpStatus(false);
        }}
        onRowClick={(p) => {
          setEmployeeDetails(p.row);
          setHelpStatus(false);
          setSelectedList([]);
        }}
      />
    </div>
  );
};

export default PendingDeliverdPayments;
