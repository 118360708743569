import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import { Menu, X } from "lucide-react";

export default function Navbar({ isShowShadow }) {
  const [hasScrolled, setHasScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setHasScrolled(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const menuItems = [
    { name: "Home", path: "/" },
    { name: "Terms & Conditions", path: "/terms_conditions" },
    { name: "Contact Us", path: "/contact" },
    { name: "Sign Up", path: "/auth/mobile_signup" },
    { name: "Login", path: "/auth/login" },
  ];

  return (
    <motion.header
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, ease: "easeInOut" }}
      style={{ zIndex: 1000 }}
      className={`fixed left-0 right-0 py-4  flex items-center justify-between transition-shadow ${
        hasScrolled || isShowShadow ? "shadow-md bg-[#f9fafb]" : ""
      }`}
    >
      <div className="flex w-full justify-between items-center mx-auto px-4">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.6 }}
          onClick={() => navigate("/auth/login")}
          className="cursor-pointer flex items-center"
        >
          <img
            src="https://firebasestorage.googleapis.com/v0/b/retailx-126e1.appspot.com/o/retailx%2Fapp%2Fblack-icon?alt=media&token=3c355e16-e7bd-449a-a729-b39ac1dacb1e"
            className="h-[50px]"
            alt="Logo Icon"
          />
          <img
            src="https://firebasestorage.googleapis.com/v0/b/retailx-126e1.appspot.com/o/retailx%2Fapp%2Fblack-text?alt=media&token=3c355e16-e7bd-449a-a729-b39ac1dacb1e"
            className="h-[40px] ml-2"
            alt="Logo Text"
          />
        </motion.div>

        {/* Desktop Menu */}
        <div className="hidden md:flex space-x-6">
          {menuItems.map((item) => (
            <button
              key={item.path}
              onClick={() => {
                if (item.name !== "Home") {
                  navigate(item.path);
                } else {
                  if (item.name === "Login") {
                    window.open("https://login.retailxapp.com/auth/login");
                  } else {
                    window.open("https://retailxapp.com/landing");
                  }
                }
              }}
              className="p-2 rounded-sm relative"
            >
              {item.name}
              {location.pathname === item.path && (
                <div className="h-[2px] mt-1 bg-orange-500 w-full absolute bottom-0"></div>
              )}
              <div
                className={`${
                  location.pathname === item.path
                    ? "bg-orange"
                    : "bg-transparent"
                }  h-[2px] mt-1`}
              ></div>
            </button>
          ))}
        </div>

        {/* Mobile Menu Button */}
        <div className="md:hidden">
          <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
            {isMenuOpen ? <X size={28} /> : <Menu size={28} />}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-white shadow-lg flex flex-col p-5 md:hidden z-40">
          {menuItems.map((item) => (
            <button
              key={item.path}
              onClick={() => {
                navigate(item.path);
                setIsMenuOpen(false);
              }}
              className="py-3 border-b border-gray-200 text-lg"
            >
              {item.name}
            </button>
          ))}
        </div>
      )}
    </motion.header>
  );
}
