import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import spinner from "../utility/json/spinerWhite.json";
import box from "../utility/images/box.svg";

import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import { CallAPIPromise } from "../billing/common";
import { selectWidth } from "../utility/Redux/profile";
import MainFooter from "../utility/mainFooter";
import Navbar from "../utility/navbar";
import SuccessModal from "../utility/succesMidal";

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [isCleint, setIsClient] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    mobile: "",
    business_type: "",
    business_name: "",
    bisuness_address: "",
    pin_code: "",
  });
  const [error, setError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !formData.name ||
      !formData.email ||
      !formData.message ||
      !formData.mobile
    ) {
      setError("All fields are required!");
      return;
    }
    setError("");
    setLoading(true);
    CallAPIPromise("/api/contactUs/contactUsEmail", {
      name: formData.name,
      email: formData.email,
      mobile: formData.mobile,
      message: formData.message,
      email_from: "Retailx",
      business_type: formData.business_type,
      business_name: formData.business_name,
      bisuness_address: formData.bisuness_address,
      pin_code: formData.pin_code,
    })
      .then(() => {
        setIsModalOpen(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const bussinesTypes = [
    {
      business_type: "Apparel and Fashion Retailing",
    },
    {
      business_type: "Books and Stationery",
    },
    {
      business_type: "Convenience Stores",
    },
    {
      business_type: "Department Stores",
    },
    {
      business_type: "Discount Stores",
    },
    {
      business_type: "Electronics Retailing",
    },
    {
      business_type: "Factory Outlets",
    },
    {
      business_type: "Fast Food",
    },
    {
      business_type: "Flowers Shop",
    },
    {
      business_type: "Grocery Retailing",
    },
    {
      business_type: "Restaurants and Cafes",
    },
    {
      business_type: "Specialty Stores",
    },
    {
      business_type: "Supermarkets",
    },
    {
      business_type: "Warehouse",
    },
    {
      business_type: "Wholesale Retailers",
    },
  ];
  const bussinesINquery = [
    {
      title: "Contact Support",
      msg: "   Our support team is available to assist you with any issues or questions.",
    },
    {
      title: "Feedback and Suggestions",
      msg: "We value your feedback to improve our services. Feel free share your thoughts!",
    },
    {
      title: "Bussiness Inquiries",
      msg: "For bussiness-related questions, please get in touch with support team.",
    },
  ];

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsClient(true);
    }
  }, []);

  const width = typeof window !== "undefined" ? window.innerWidth : "1000";
  return (
    <div
      style={{
        position: "relative",
      }}
      className="h-screen w-screen   items-center bg-gradient-to-b from-[#f8e8d2] to-white"
    >
      <div className="w-full">
        <Navbar isShowShadow={true} />
        {isCleint ? (
          <div className="flex justify-center mb-[30px]">
            <div className="">
              <div className="t:container px-3 t:px-0 pt-[90px]">
                <div className="l:flex w-full mt-10  ">
                  <div className="flex  ">
                    <img
                      src={box}
                      style={{
                        opacity: 0.1,
                      }}
                      alt=""
                      className="absolute w-[350px] -left-[110px]"
                    />
                    <div
                      style={{
                        height: width > 1024 ? "514px" : "auto",
                      }}
                      className="l:mr-10 flex flex-col  "
                    >
                      <div className="border-b border-[#b99b72]   pb-[10px]">
                        <div className="text-[28px] font-semibold text-gray-700">
                          Schedule a free demo
                        </div>
                        <div className="text-gray-500 pt-2 max-w-[700px]">
                          Get in touch with our team to clarify your queries
                        </div>
                      </div>

                      <div className="grid ms:hidden t:block l:grid-cols-1 grid-cols-1  gap-3 mt-5">
                        {bussinesINquery.map((o, k) => {
                          return (
                            <div key={k}>
                              <div className="font-semibold text-gray-700">
                                {o.title}
                              </div>
                              <div className="text-gray-500 mt-1">{o.msg}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="flex  justify-center d:min-w-[700px] ms:mt-4 l:mt-0 ">
                    <form
                      onSubmit={handleSubmit}
                      className="border rounded-lg bg-white shadow-md p-6  w-full mb-6 "
                    >
                      {error && (
                        <p className="text-red-500 text-sm border border-red-500 p-2 rounded mb-4">
                          {error}
                        </p>
                      )}
                      <div className="grid ms:grid-cols-1 t:grid-cols-2  ms:gap-3 t:gap-3">
                        <div className="">
                          <label
                            htmlFor="name"
                            className="block text-gray-700 font-semibold mb-1"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="border border-gray-300 rounded-md w-full p-2 focus:outline-none focus:ring-2 focus:ring-orange"
                            placeholder="Your Name"
                            required // Added required for form validation
                          />
                        </div>

                        <div className="">
                          <label
                            htmlFor="email"
                            className="block text-gray-700 font-semibold mb-1"
                          >
                            Email
                          </label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="border border-gray-300 rounded-md w-full p-2 focus:outline-none focus:ring-2 focus:ring-orange"
                            placeholder="Your Email"
                            required // Added required for form validation
                          />
                        </div>

                        <div className="">
                          <label
                            htmlFor="mobile"
                            className="block text-gray-700 font-semibold mb-1"
                          >
                            Mobile
                          </label>
                          <input
                            type="tel"
                            name="mobile"
                            id="mobile"
                            value={formData.mobile}
                            onChange={handleChange}
                            className="border border-gray-300 rounded-md w-full p-2 focus:outline-none focus:ring-2 focus:ring-orange"
                            placeholder="Your Mobile Number"
                            pattern="[0-9]*" // Allows only numeric input
                            required // Added required for form validation
                          />
                        </div>
                        <div className="">
                          <label
                            htmlFor="business_type"
                            className="block text-gray-700 font-semibold mb-1"
                          >
                            Business Type
                          </label>
                          <select
                            name="business_type"
                            id="business_type"
                            value={formData.business_type}
                            onChange={handleChange}
                            className="border border-gray-300 rounded-md w-full px-2 py-2 focus:outline-none focus:ring-2 focus:ring-orange"
                            required
                          >
                            <option value="">Select Business Type</option>
                            {bussinesTypes.map((b) => {
                              return (
                                <option value={b.business_type}>
                                  {b.business_type}
                                </option>
                              );
                            })}
                          </select>
                        </div>

                        <div className="">
                          <label
                            htmlFor="mobile"
                            className="block text-gray-700 font-semibold mb-1"
                          >
                            Bussines Name
                          </label>
                          <input
                            type="text"
                            name="business_name"
                            id="business_name"
                            value={formData.business_name}
                            onChange={handleChange}
                            className="border border-gray-300 rounded-md w-full p-2 focus:outline-none focus:ring-2 focus:ring-orange"
                            placeholder="Bussiness Name"
                            required // Added required for form validation
                          />
                        </div>
                        <div className="">
                          <label
                            htmlFor="mobile"
                            className="block text-gray-700 font-semibold mb-1"
                          >
                            Pin Code
                          </label>
                          <input
                            type="text"
                            name="pin_code"
                            id="pin_code"
                            value={formData.pin_code}
                            onChange={handleChange}
                            className="border border-gray-300 rounded-md w-full p-2 focus:outline-none focus:ring-2 focus:ring-orange"
                            placeholder="Bussiness Address"
                            required // Added required for form validation
                          />
                        </div>
                        <div className="">
                          <label
                            htmlFor="mobile"
                            className="block text-gray-700 font-semibold mb-1"
                          >
                            Bussines Address
                          </label>
                          <textarea
                            type="text"
                            name="bisuness_address"
                            id="bisuness_address"
                            value={formData.bisuness_address}
                            onChange={handleChange}
                            className="border border-gray-300 rounded-md w-full p-2 focus:outline-none focus:ring-2 focus:ring-orange"
                            placeholder="Bussiness Address"
                            rows="2"
                            required // Added required for form validation
                          />
                        </div>

                        <div className="">
                          <label
                            htmlFor="message"
                            className="block text-gray-700 font-semibold mb-1"
                          >
                            Message
                          </label>
                          <textarea
                            name="message"
                            id="message"
                            value={formData.message}
                            onChange={handleChange}
                            className="border border-gray-300 rounded-md w-full p-2 focus:outline-none focus:ring-2 focus:ring-orange"
                            placeholder="Your Message"
                            rows="2"
                            required // Added required for form validation
                          ></textarea>
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="bg-orange flex mt-3 justify-center text-white font-semibold py-3 rounded-md w-full hover:bg-[#e77c05] transition duration-200"
                      >
                        {loading && (
                          <div
                            style={{
                              height: 25,
                              width: 25,
                            }}
                            className="mr-2"
                          >
                            <Lottie
                              animationData={spinner}
                              loop={true}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            />
                          </div>
                        )}
                        {loading ? "Sending" : " Send Message"}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="w-full  mt-8 ">
                <div className="container flex flex-col md:flex-row">
                  <div className="flex flex-1 rounded-md shadow-sm bg-white overflow-hidden mr-3">
                    <div className="w-full">
                      <h2 className="text-xl font-semibold text-gray-600 py-3 px-4">
                        genius office (India)
                      </h2>
                      <div className="h-[380px] w-full bg-white p-2 border">
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3409.6681420359378!2d75.58266108780737!3d31.285273492524976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391a5b97a31d51b1%3A0x5dd3d1407762182!2sgenius%20office!5e0!3m2!1sen!2sin!4v1730099822620!5m2!1sen!2sin"
                          width="100%"
                          height="100%"
                          allowFullScreen=""
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                          className="w-full h-full border-0"
                        ></iframe>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-1 rounded-sm bg-white overflow-hidden">
                    <div className="w-full">
                      <h2 className="text-xl font-semibold text-gray-600 py-3 px-4">
                        genius office (Canada)
                      </h2>
                      <div className="h-[380px] w-full border bg-white p-2">
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3211.920315244614!2d-122.84733642314131!3d49.13750018102351!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4bb52c3df58d85f9%3A0x83999f5606bc2746!2sgenius%20office%20-%20since%201994%20%7C%20Software%20Company%20%7C%20Startup%20Solutions%20%7C%20ERP%20Solutions%20%7C%20Web%20%26%20Mobile%20App%20Development!5e1!3m2!1sen!2sin!4v1730183534033!5m2!1sen!2sin"
                          width="100%"
                          height="100%"
                          allowFullScreen=""
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                          className="w-full h-full border-0"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="h-screen"></div>
        )}
        <MainFooter />
        <SuccessModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      </div>
    </div>
  );
};

export default ContactUs;
