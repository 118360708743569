import React, { useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import ModalHeaderStyle from "../utility/component/styles/modalHeader";
import { useNavigate } from "react-router-dom";
import { deleteFile, UploadFile } from "../utility/functions/firebase";
import { CallAPIPromise } from "../billing/common";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "../utility/Redux/security";
import { setAlertWithTitle, setSpinnerLoading } from "../utility/Redux/modal";
import { uploadToCloudinary } from "../utility/functions/common";
import { Close } from "@mui/icons-material";

function ImageMaster() {
  const [images, setImages] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [title, setTitle] = useState("");
  const [savedImagesList, setSavedImagesList] = useState([]);
  // Handle files dropped
  const onDrop = (acceptedFiles) => {
    const resizeImage = (file) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = 270;
          canvas.height = 270;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, 270, 270);
          canvas.toBlob((blob) => {
            resolve({
              IMAGE_ID: Date.now(),
              url: URL.createObjectURL(blob), // Resized image URL
              file: blob, // Keeping resized file
              IMAGE_TAG: "",
              BARCODE: file.name
                .replace(".png", "")
                .replace(".jpg", "")
                .replace(".avf", "")
                .replace(".svg", ""),
            });
          }, file.type);
        };
      });
    };

    Promise.all(acceptedFiles.map(resizeImage)).then((newImages) => {
      setImages((prevImages) => [...prevImages, ...newImages]);
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
      "image/avif": [".avif"], // ✅ Corrected from "avf" to "avif"
    },
    multiple: true,
  });
  // Update tags for images
  const updateTags = (id, newTags) => {
    setImages((prevImages) =>
      prevImages.map((img) =>
        img.IMAGE_ID === id ? { ...img, BARCODE: newTags } : img
      )
    );
  };

  // Remove an image
  const removeImage = (id) => {
    setImages((prevImages) => prevImages.filter((img) => img.IMAGE_ID !== id));
  };
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  // Save images to backend
  const saveImages = async () => {
    const isEmptyImageAvailvle = images.filter((i) => i.BARCODE === "");
    if (!isEmptyImageAvailvle[0] && title) {
      images.map((img) => {
        const name = img.BARCODE;
        const path = "retailx/image_library/";
        UploadFile(img.file, path, name);
      });
      dispatch(setSpinnerLoading("Uploading"));
      CallAPIPromise(
        `/api/images/saveImage`,
        {
          image_cart: images.map((img) => {
            return {
              IMAGE_ID: img.BARCODE,
              IMAGE_TAG: title,
            };
          }),
        },
        token,
        dispatch
      )
        .then(() => {
          setTitle("");
          dispatch(
            setAlertWithTitle({
              title: "Alert",
              msg: "Image saved successfully",
            })
          );
          setImages([]);
        })
        .finally(() => {
          dispatch(setSpinnerLoading(false));
        });
    } else {
      if (!title) {
        dispatch(
          setAlertWithTitle({
            title: "Missing Title",
            msg: "Please Enter Title for images",
          })
        );
        return;
      }
      dispatch(
        setAlertWithTitle({
          title: "Missing Barcode",
          msg: "Please add barcode for every image ",
        })
      );
    }
  };
  const headerRef = useRef();
  const navigate = useNavigate();
  const handleClose = () => {
    navigate("/masters");
  };
  return (
    <div className="w-full h-full  bg-[#f5f5f7]   max-sm:py-2 ">
      <div
        style={{
          paddingTop: "16px",
          paddingBottom: "16px",
        }}
        className="flex justify-center  h-full "
      >
        <div
          style={{ borderRadius: "12px", height: "100%" }}
          className=" flex items-center h-full "
        >
          <div className="h-full flex flex-col overflow-y-auto">
            <div ref={headerRef}>
              <ModalHeaderStyle
                header={"Image Library"}
                onClose={handleClose}
              />
            </div>
            <div
              style={{
                height: `calc(100% - 90px)`,
              }}
              className="container min-w-[900px] p-4 bg-white  "
            >
              <div className="flex h-full flex-1">
                <div className="flex flex-1">
                  <div className="w-full">
                    <div className="mb-4">
                      <div className="flex">
                        <input
                          type="text"
                          placeholder="Enter a title for the image set"
                          className="border p-2 w-full"
                          value={title}
                          onKeyDown={(e) => {
                            if (
                              e.code === "Enter" ||
                              e.code === "NumpadEnter"
                            ) {
                              CallAPIPromise(
                                "/api/images/listImages",
                                {
                                  image_tag: e.target.value,
                                },
                                token,
                                dispatch
                              ).then((res) => {
                                if (res?.data?.images) {
                                  setSavedImagesList(res.data.images);
                                }
                              });
                            }
                          }}
                          onChange={(e) => {
                            setTitle(e.target.value);
                            if (e.target.value) {
                              let lastChar = e.target.value.slice(-1); // Gets the last character
                              if (lastChar === ",") {
                                CallAPIPromise("/api/images/listImageTag", {
                                  image_tag: e.target.value.replace(",", ""),
                                }).then((res) => {
                                  let tagsList = [];
                                  const data = res.data.response;
                                  for (
                                    let index = 0;
                                    index < data.length;
                                    index++
                                  ) {
                                    const element = data[index];
                                    const l = element.image_tag.includes(",")
                                      ? element.image_tag.split(",")
                                      : [element.image_tag];
                                    l.map((o) => {
                                      console.log(o);
                                      tagsList.push(o);
                                    });
                                    console.log(element.image_tag);
                                  }
                                  console.log(tagsList);
                                  setTagList([...new Set(tagsList)]);
                                });
                              }
                            }
                          }}
                        />
                        <button
                          onClick={() => {
                            dispatch(setSpinnerLoading("Searching"));
                            CallAPIPromise(
                              "/api/images/listImages",
                              {
                                image_tag: title,
                              },
                              token,
                              dispatch
                            )
                              .then((res) => {
                                if (res?.data?.images) {
                                  setSavedImagesList(res.data.images);
                                }
                              })
                              .finally(() => {
                                dispatch(setSpinnerLoading(false));
                              });
                          }}
                          className="bg-orange text-white py-2 px-3 ml-3 rounded-sm"
                        >
                          Search
                        </button>
                      </div>

                      <div className="text-sm ">
                        Press Enter to load saved images
                      </div>
                      <div className="flex mt-2">
                        {tagList.map((t) => {
                          return (
                            <button
                              onClick={() => {
                                setTitle((p) => p + "," + t);
                              }}
                              className="text-sm mr-2 bg-red-200  py-1 px-2 rounded-sm"
                            >
                              {t}
                            </button>
                          );
                        })}
                      </div>
                    </div>

                    {/* Drag-and-Drop Zone */}
                    <div
                      {...getRootProps()}
                      className={`border-2 border-dashed p-6 h-[130px] text-center ${
                        isDragActive ? "bg-blue-100" : "bg-gray-100"
                      }`}
                      // onDrop={async (e) => {
                      //   // Get the image URL from the dataTransfer object
                      //   console.log(e);
                      //   const imageUrl = e.dataTransfer.getData("imageUrl");
                      //   if (imageUrl) {
                      //     e.preventDefault();

                      //     const IMAGE_ID = e.dataTransfer.getData("IMAGE_ID");

                      //   }
                      // }}
                    >
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <p>Drop the files here...</p>
                      ) : (
                        <p>
                          Drag & drop some files here, or click to select files
                        </p>
                      )}
                    </div>

                    {/* Preview Section */}
                    <div
                      className="image-grid grid grid-cols-1 t:grid-cols-2 l:grid-cols-3 gap-4 mt-4 overflow-y-auto"
                      style={{ maxHeight: "300px" }}
                    >
                      {images.map((img) => (
                        <div
                          key={img.IMAGE_ID}
                          className="image-card border p-2 relative"
                        >
                          <img
                            src={img.url}
                            alt="Preview"
                            className=" h-[200px] w-[200px] object-cover mb-2"
                          />
                          <input
                            type="text"
                            placeholder="Bar Code"
                            className="border p-1 w-full"
                            value={img.BARCODE}
                            onChange={(e) =>
                              updateTags(img.IMAGE_ID, e.target.value)
                            }
                          />
                          {/* Remove Button */}
                          <button
                            className="absolute top-1 right-1 bg-red-500 text-white p-1 rounded text-xs"
                            onClick={() => removeImage(img.IMAGE_ID)}
                          >
                            Remove
                          </button>
                        </div>
                      ))}
                    </div>

                    <div className="flex justify-end">
                      <button
                        className="bg-blue-500 text-white p-2 mt-4 rounded"
                        onClick={saveImages}
                      >
                        Save Images
                      </button>
                    </div>
                  </div>
                </div>

                {savedImagesList.length > 0 && (
                  <div className="ml-3 pr-5 h-full overflow-hidden w-[220px] overflow-y-auto">
                    {savedImagesList.map((i) => {
                      return (
                        <div
                          style={{
                            height: 200,
                            width: 200,
                            border: "1px dashed gray",
                            position: "relative",
                          }}
                          className="flex justify-center items-center"
                        >
                          <button
                            onClick={() => {
                              dispatch(setSpinnerLoading("Deleting"));
                              CallAPIPromise(
                                "/api/images/deleteImages",
                                {
                                  imageIds: i.IMAGE_ID,
                                },
                                token,
                                dispatch
                              )
                                .then((res) => {
                                  const path = `retailx/image_library/${i.IMAGE_ID}`;
                                  deleteFile(path);
                                  setSavedImagesList((s) =>
                                    s.filter((d) => d.IMAGE_ID !== i.IMAGE_ID)
                                  );
                                })
                                .finally(() => {
                                  dispatch(setSpinnerLoading(false));
                                });
                            }}
                            style={{
                              position: "absolute",
                              right: 0,
                              top: 0,
                              background: "red",
                            }}
                          >
                            <Close
                              style={{
                                position: "absolute",
                                top: "0",
                                right: "0",
                                color: "white",
                                backgroundColor: "red",
                                zIndex: "50",
                                fontSize: "20px",
                              }}
                            />
                          </button>
                          <img
                            style={{
                              width: 180,
                              height: 180,
                            }}
                            onClick={async (e) => {
                              const response = await fetch(e.target.src);
                              const fileBlob = await response.blob();
                              setImages((pev) => {
                                return [
                                  ...pev,
                                  {
                                    IMAGE_ID: i.IMAGE_ID,
                                    url: URL.createObjectURL(fileBlob), // Resized image URL
                                    file: fileBlob, // Keeping resized file
                                    IMAGE_TAG: "",
                                    BARCODE: i.IMAGE_ID,
                                  },
                                ];
                              });
                            }}
                            onDragStart={async (e) => {
                              const response = await fetch(e.target.src);
                              const fileBlob = await response.blob();
                              setImages((pev) => {
                                return [
                                  ...pev,
                                  {
                                    IMAGE_ID: i.IMAGE_ID,
                                    url: URL.createObjectURL(fileBlob), // Resized image URL
                                    file: fileBlob, // Keeping resized file
                                    IMAGE_TAG: "",
                                    BARCODE: i.IMAGE_ID,
                                  },
                                ];
                              });
                            }}
                            className="overflow-hidden"
                            src={`https://firebasestorage.googleapis.com/v0/b/retailx-126e1.appspot.com/o/retailx%2Fimage_library%2F${i.IMAGE_ID}?alt=media&token=f7547088-289b-4819-a479-2304e392be86`}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageMaster;
