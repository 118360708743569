import { motion } from "framer-motion";
import { CheckCircle2 } from "lucide-react";

export default function SuccessModal({ isOpen, onClose }) {
  //   useEffect(() => {
  //     if (isOpen) {
  //       setTimeout(onClose, 3000); // Auto close after 3 sec
  //     }
  //   }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center backdrop-blur-md">
      <motion.div
        initial={{ scale: 0.7, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.3, ease: "easeOut" }}
        className="bg-white rounded-3xl shadow-lg p-6 w-[90%] max-w-sm text-center"
      >
        <CheckCircle2 className="text-green-500 w-16 h-16 mx-auto mb-3" />
        <h2 className="text-2xl font-semibold text-gray-800">
          Request Submitted
        </h2>
        <p className="text-gray-500 mt-2">We will contact you soon.</p>

        <button
          onClick={onClose}
          className="mt-4 px-6 py-2 bg-gradient-to-r from-[#ff7f50] to-[#ff4500] text-white font-medium rounded-xl shadow-md hover:scale-105 transition-all"
        >
          OK, Got It!
        </button>
      </motion.div>
    </div>
  );
}
