import React from "react";
import {
  ModifyButton,
  SaveButton,
  UndoButton,
} from "../utility/component/buttons";

import ItemBox from "./itemBox";
import { GetListItemMaster, onBillSave, itemClick } from "./function";
import moment from "moment";
import PlaceHolderTextInput from "../utility/component/geniusComponents/placeTextInput";
import { FormatNumber2 } from "../utility/functions/formatNumber.js";
import {
  setAlertWithTitle,
  setIssueStockItem,
  setnumericKeyboardStatus,
  setnumericKeyboardType,
} from "../utility/Redux/modal.js";
import { enqueueSnackbar } from "notistack";
import "./animat.css";
import {
  fastMovingMovieItem,
  FontSizeWidth,
  getItemByAlios,
  getItemByCode,
  getItemByName,
} from "../utility/constant.js";
import EditableDataGrid from "../utility/component/datagrid/editableDatagrid.js";
import { getCustomerByMobile } from "../utility/functions/common.js";
import { toProperCase } from "../utility/component/format.js";
import axios from "axios";
import LabelTextInput from "../utility/component/geniusComponents/labelTextInput.js";
import Cookies from "js-cookie";
import { setToken } from "../utility/Redux/security.js";
import Store from "../utility/Redux/store.js";
import { Pagination, PaginationItem } from "@mui/material";
export const mobileViewWidth = 1020;
export const tabViewMinWidth = 765;

const isLocalhost =
  window.location.hostname === "localhost" ||
  window.location.hostname.includes("192");
export const apiClient = axios.create({
  // baseURL: isLocalhost ? "http://192.168.1.7:5000" : "https://retailxapp.com",
  baseURL: "https://retailxapp.com", // Replace with your API base URL
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true, // Send cookies with requests if needed
});

export const getCurrency = () => {
  const companyAddress = Store.getState().profile.companyAddress;
  const currency = companyAddress?.currency_symbol
    ? companyAddress?.currency_symbol
    : "₹";
  return currency ? currency : "₹";
};
export const CallAPIPromise = (path, data, token) => {
  const state = Store.getState();
  const tokenLocal = state.security.token;

  return new Promise((resolve, reject) => {
    apiClient.defaults.headers.common["Authorization"] = `Bearer ${
      token ? token : tokenLocal
    }`;

    try {
      apiClient
        .post(path, data)
        .then((t) => {
          resolve(t);
        })
        .catch(async (error) => {
          // dispatch(setSpinnerLoading(false));
          console.log(error);

          if (error.response.data.name === "TokenExpiredError") {
            try {
              const refreshToken = Cookies.get("refreshToken");

              const refreshTokenResponse = await apiClient.post(
                "/api/users/refresh-token",
                {
                  refreshToken: refreshToken,
                }
              );

              const newToken = refreshTokenResponse.data.accessToken;
              // Update Authorization Header with New Token
              apiClient.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${newToken}`;

              // Retry Original Request
              const retryResponse = await apiClient.post(path, data);
              Store.dispatch(setToken(newToken));

              resolve(retryResponse);
            } catch (refreshError) {
              console.error("Refresh Token Error:", refreshError);
              // dispatch(setSessionExpired(true));
              reject(refreshError);
            }
          } else {
            if (error.response.data?.message) {
              Store.dispatch(
                setAlertWithTitle({
                  title: "Alert",
                  msg: error.response.data?.message,
                })
              );
              return;
            }
            reject(error);
          }
        });
    } catch {
      reject("Try Catch");
    }
  });
};
function billSettelemntBilling(
  fromref,
  c,
  setisTextActive,
  customerDetails,
  TotalAmount,
  setCustomerDetails,
  customerList,
  prefix,
  token,
  setCustomerList,
  dispatch,
  setBillingDetails,
  inputFields,
  setCustomermasetrHelp,
  grandTotalWithTax,
  width,
  billingDetails,
  setListAccountMasterHelp,
  remainingTotal,
  hideHeader
) {
  const secWidth =
    width > mobileViewWidth
      ? c[1].width + c[2].width + c[3].width + c[4].width
      : c[6].width + c[5].width;
  const thirWidth =
    width > mobileViewWidth && width < 1280
      ? c[6].width + c[5].width + 80
      : c[6].width + c[5].width;

  //   <LabelTextInput
  //   label={"Grand Total"}
  //   readOnly={true}
  //   value={grandTotalWithTax && FormatNumber2(grandTotalWithTax)}
  // />
  return (
    <div
      ref={fromref}
      className=" mt-[1px]  overflow-hidden bg-white  border-b  min-w-[350px]"
    >
      {!hideHeader && (
        <div
          style={{
            display: width > mobileViewWidth ? "flex" : "none",
          }}
        >
          <div
            style={{
              width:
                c[0].width + c[1].width + c[2].width + c[3].width + c[4].width,
            }}
            className=" bg-[#383535] py-[4px] rounded-tr-sm flex items-center justify-center rounded-tl-sm p-1 text-white text-center"
          >
            Bill Settlement
          </div>
          <div className="flex   border-b    border-r bg-[#f7f7fd]  ">
            <div
              style={{
                width: c[5].width,
              }}
              className=" flex text-[16px] border-l   font-semibold  pr-1 items-center justify-end text-right"
            >
              Points
            </div>
            <div
              style={{
                width: c[6].width,
              }}
              className=" text-[17px] border-l font-semibold  text-end p-1"
            >
              {0}
            </div>
          </div>
        </div>
      )}

      <div>
        <div
          style={{
            border: "1px solid #f3f4f6",
            boxShadow: "1px 1px 6px 1px #f3f4f6",
          }}
          className=" mx-3   mt-4 pt-4 bg-white   rounded-sm"
        >
          <div
            className={
              "text-[15px] font-[600]" +
              " -mt-[34px] w-fit px-1  ml-3 pb-1 bg-white"
            }
          >
            {" "}
            Customer Info
          </div>
          <div className="grid  md:grid-cols-3 ms:grid-cols-1 sm:grid-cols-2 gap-3 pb-3   px-3">
            <LabelTextInput
              type={"number"}
              id={"Customer Mobile"}
              label={"Mobile"}
              onClick={() => {
                dispatch(setnumericKeyboardType("CUSTOMER_MOBILE"));
                dispatch(setnumericKeyboardStatus(true));
                dispatch(
                  setIssueStockItem({
                    title: "CUSTOMER_MOBILE",
                    id: "1",
                    value: customerDetails.CUSTOMER_MOBILE,
                  })
                );
              }}
              onFocus={() => {
                setisTextActive(true);
              }}
              onLostFocus={(t) => {
                if (t.target.value.length > 9) {
                  getCustomerByMobile(
                    t.target.value,
                    prefix,
                    token,
                    dispatch
                  ).then((customerData) => {
                    if (customerData[0]) {
                      setCustomerDetails(customerData[0]);
                    }
                  });
                }
              }}
              placeholder={"1234567890"}
              value={customerDetails.CUSTOMER_MOBILE}
              onChange={(t) => {
                if (TotalAmount > 0) {
                  setCustomerDetails((e) => {
                    return {
                      ...e,
                      CUSTOMER_MOBILE: t.target.value,
                    };
                  });
                } else {
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: `Please add items to do bill settlement`,
                    })
                  );
                }
              }}
              onKeyDown={(e) => {
                if (e.code === "Enter" || e.code === "NumpadEnter") {
                  if (TotalAmount > 0) {
                    if (e.target.value) {
                      getCustomerByMobile(
                        e.target.value,
                        prefix,
                        token,
                        dispatch
                      ).then((customerData) => {
                        if (customerData[0]) {
                          setCustomerDetails(customerData[0]);
                        }
                      });
                    }
                    inputFields.forEach((element) => {
                      if (element.id === "Cash_amount") {
                        element.focus();
                      }
                    });
                  } else {
                    dispatch(
                      setAlertWithTitle({
                        title: "Alert",
                        msg: "Please add some items to create bill.",
                      })
                    );
                  }
                } else {
                  if (e.code === "ArrowRight") {
                    e.preventDefault();
                    inputFields.forEach((element) => {
                      if (element.id === "Customer_name") {
                        element.focus();
                      }
                    });
                  } else {
                    if (e.code === "ArrowDown") {
                      e.preventDefault();

                      inputFields.forEach((element) => {
                        if (element.id === "Cash_amount") {
                          element.focus();
                        }
                      });
                    } else {
                      if (e.code === "ArrowUp") {
                        e.preventDefault();
                      }
                    }
                  }
                }
              }}
              onchange={(t) => {
                if (TotalAmount > 0) {
                  if (!customerDetails.CUSTOMER_CODE) {
                    setCustomerDetails((e) => {
                      return {
                        ...e,
                        CUSTOMER_MOBILE: t.target.value,
                      };
                    });
                    if (t.target.value.length === 10) {
                      const isOldCustomer = customerList.filter(
                        (customer) =>
                          customer.CUSTOMER_MOBILE === t.target.value
                      );
                      if (isOldCustomer[0]) {
                        setCustomerDetails({
                          ...isOldCustomer[0],
                        });
                        setBillingDetails((old) => ({
                          ...old,
                          btcName: isOldCustomer[0].CUSTOMER_NAME,
                        }));
                      }
                    }
                  } else {
                    setCustomerDetails((cus) => {
                      return {
                        ...cus,
                        CUSTOMER_CODE: "",
                        CUSTOMER_NAME: "",
                        CUSTOMER_ADDRESS: "",
                        CUSTOMER_EMAIL: "",
                        CUSTOMER_TYPE: "",
                        CITY: "",
                        COUNTRY: "",
                        CUSTOMER_MOBILE: "",
                        PIN_CODE: "",
                        DISQ_RATE: "0",
                        LOYALTY_POINTS: 0,
                        CUSTOMER_STATUS: "",
                        PRV_BAL: 0,
                        BAL_TYPE: "",
                      };
                    });
                  }
                } else {
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: `Please add items to do bill settlement`,
                    })
                  );
                }
              }}
            />

            <LabelTextInput
              label={"Name"}
              onKeyDown={(e) => {
                if (e.code === "Enter" || e.code === "NumpadEnter") {
                  if (TotalAmount > 0) {
                    // setCustomermasetrHelp(true);
                  } else {
                    dispatch(
                      setAlertWithTitle({
                        title: "Alert",
                        msg: `Please add items to do bill settlement`,
                      })
                    );
                  }
                } else {
                  if (e.code === "ArrowRight") {
                    inputFields.forEach((element) => {
                      if (element.id === "Cash_amount") {
                        element.focus();
                      }
                    });
                  } else {
                    if (e.code === "ArrowDown") {
                      inputFields.forEach((element) => {
                        if (element.id === "Card_amount") {
                          element.focus();
                        }
                      });
                    } else {
                      if (e.code === "ArrowLeft") {
                        inputFields.forEach((element) => {
                          if (element.id === "Customer_mobile") {
                            element.focus();
                          }
                        });
                      }
                    }
                  }
                }
              }}
              onFocus={() => {
                setisTextActive(true);
              }}
              onBlur={() => {
                setisTextActive(false);
              }}
              type={"text"}
              id={"Customer_name"}
              placeholder={"eg. Jhon dev"}
              value={customerDetails.CUSTOMER_NAME}
              rightIcon={
                <button
                  onClick={() => {
                    setCustomermasetrHelp(true);
                  }}
                  className="ml-2"
                >
                  <i className="bi bi-search flex items-center text-orange"></i>
                </button>
              }
              onchange={(t) => {
                if (TotalAmount > 0) {
                  if (!customerDetails.CUSTOMER_CODE) {
                    setCustomerDetails((e) => {
                      return {
                        ...e,
                        CUSTOMER_NAME: toProperCase(t.target.value),
                      };
                    });
                  } else {
                    setCustomerDetails((cus) => {
                      return {
                        ...cus,
                        CUSTOMER_CODE: "",
                        CUSTOMER_NAME: "",
                        CUSTOMER_ADDRESS: "",
                        CUSTOMER_EMAIL: "",
                        CUSTOMER_TYPE: "",
                        CITY: "",
                        COUNTRY: "",
                        CUSTOMER_MOBILE: "",
                        PIN_CODE: "",
                        DISQ_RATE: "0",
                        LOYALTY_POINTS: 0,
                        CUSTOMER_STATUS: "",
                        PRV_BAL: 0,
                        BAL_TYPE: "",
                      };
                    });
                  }
                } else {
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: `Please add items to do bill settlement`,
                    })
                  );
                }
              }}
            />

            <LabelTextInput
              label={"GST"}
              readOnly={true}
              type={"text"}
              id={"Customer_GST"}
              placeholder={"22AAAAA0000A1Z5"}
              value={customerDetails.TAX_NUMBER}
            />
          </div>
        </div>

        <div
          style={{
            border: "1px solid #f3f4f6",
            boxShadow: "1px 1px 6px 1px #f3f4f6",
          }}
          className=" mx-3  mt-4 pt-4 bg-white  rounded-sm"
        >
          <div
            className={
              "text-[15px] font-[600]" +
              " -mt-[34px] w-fit px-1  ml-3 pb-1 bg-white"
            }
          >
            {" "}
            Charges
          </div>
          <div className="grid  md:grid-cols-3 ms:grid-cols-1 sm:grid-cols-2 gap-3 pb-3   px-3">
            <LabelTextInput
              label={"Handling Charges"}
              id={"HANDLING_CHARGES"}
              borderColor={"#ff9a68"}
              onKeyDown={(e) => {
                if (e.code === "ArrowLeft") {
                  inputFields.forEach((element) => {
                    if (element.id === "Coupan_amount") {
                      element.focus();
                    }
                  });
                } else {
                  if (e.code === "ArrowUp") {
                    inputFields.forEach((element) => {
                      if (element.id === "BTC_amount") {
                        element.focus();
                      }
                    });
                  }
                }
              }}
              onFocus={() => {
                setisTextActive(true);
              }}
              onBlur={() => {
                setisTextActive(false);
              }}
              readOnly={width <= 1280}
              onClick={() => {
                dispatch(setnumericKeyboardType("HANDLING_CHARGES"));
                dispatch(setnumericKeyboardStatus(true));
                dispatch(
                  setIssueStockItem({
                    title: "HANDLING_CHARGES",
                    id: "1",
                    value:
                      billingDetails.HANDLING_CHARGES !== 0
                        ? billingDetails.HANDLING_CHARGES
                        : 0,
                  })
                );
              }}
              placeholder={"0.00"}
              value={
                billingDetails.HANDLING_CHARGES !== 0
                  ? billingDetails.HANDLING_CHARGES
                  : ""
              }
              type={"number"}
              onchange={(t) => {
                if (TotalAmount > 0) {
                  setBillingDetails((e) => {
                    return {
                      ...e,
                      HANDLING_CHARGES:
                        t.target.value === "" ? 0 : t.target.value,
                    };
                  });
                } else {
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: `Please add items to do bill settlement`,
                    })
                  );
                }
              }}
            />

            <LabelTextInput
              id={"DELIVERY_CHARGES"}
              type={"number"}
              label={"Delivery Charges"}
              borderColor={"#ff9a68"}
              onKeyDown={(e) => {
                if (e.code === "ArrowLeft") {
                  inputFields.forEach((element) => {
                    if (element.id === "DELIVERY_CHARGES") {
                      element.focus();
                    }
                  });
                } else {
                  if (e.code === "ArrowUp") {
                    inputFields.forEach((element) => {
                      if (element.id === "BTC_amount") {
                        element.focus();
                      }
                    });
                  }
                }
              }}
              onFocus={() => {
                setisTextActive(true);
              }}
              onBlur={() => {
                setisTextActive(false);
              }}
              readOnly={width <= 1280}
              onClick={() => {
                dispatch(setnumericKeyboardType("DELIVERY_CHARGES"));
                dispatch(setnumericKeyboardStatus(true));
                dispatch(
                  setIssueStockItem({
                    title: "DELIVERY_CHARGES",
                    id: "1",
                    value:
                      billingDetails.DELIVERY_CHARGES !== 0
                        ? billingDetails.DELIVERY_CHARGES
                        : 0,
                  })
                );
              }}
              placeholder={"0.00"}
              value={
                billingDetails.DELIVERY_CHARGES !== 0
                  ? billingDetails.DELIVERY_CHARGES
                  : ""
              }
              onchange={(t) => {
                if (TotalAmount > 0) {
                  setBillingDetails((e) => {
                    return {
                      ...e,
                      DELIVERY_CHARGES:
                        t.target.value === "" ? 0 : t.target.value,
                    };
                  });
                } else {
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: `Please add items to do bill settlement`,
                    })
                  );
                }
              }}
            />
          </div>
        </div>

        <div
          style={{
            border: "1px solid #f3f4f6",
            boxShadow: "1px 1px 6px 1px #f3f4f6",
          }}
          className=" mx-3  mt-4 pt-4 bg-white mb-4  rounded-sm"
        >
          <div className="flex justify-between pb-3">
            <div
              className={
                "text-[15px] font-[600]" + " -mt-[34px]  px-1  mx-3  bg-white"
              }
            >
              Payment Info
            </div>
            <div className="ml-1 text-sm  ms:hidden sm:flex font-[400] -mt-[34px]  mx-3 h-fit bg-red-500 text-white px-3 py-1 rounded-sm">
              Amount to Settle :
              <div className="ml-1">
                {grandTotalWithTax && FormatNumber2(grandTotalWithTax)}
              </div>
            </div>
          </div>
          <div className="grid  md:grid-cols-3 ms:grid-cols-1 sm:grid-cols-2 gap-3 pb-3   px-3">
            <div className=" sm:hidden ms:flex font-[400]  h-fit text-sm  bg-red-500 text-white px-3 py-1 rounded-sm">
              Amount to Settle :
              <div className="ml-1">
                {grandTotalWithTax && FormatNumber2(grandTotalWithTax)}
              </div>
            </div>
            <LabelTextInput
              label={"Cash Amount"}
              readOnly={width <= 1280}
              onClick={() => {
                dispatch(setnumericKeyboardType("CASH"));
                dispatch(setnumericKeyboardStatus(true));
                dispatch(
                  setIssueStockItem({
                    title: "CASH",
                    id: "1",
                    value:
                      billingDetails.cashAmount !== 0
                        ? billingDetails.cashAmount
                        : 0,
                  })
                );
              }}
              autoFocus={true}
              onFocus={(r) => {
                r.target.select();
                setisTextActive(true);
              }}
              onBlur={() => {
                setisTextActive(false);
              }}
              align={"right"}
              onDoubleClick={() => {
                setBillingDetails((e) => {
                  return {
                    ...e,
                    cashAmount: remainingTotal,
                  };
                });
              }}
              onKeyDown={(e) => {
                if (e.code === "Enter" || e.code === "NumpadEnter") {
                  const inputFields =
                    fromref.current.querySelectorAll("input, select");
                  inputFields.forEach((element) => {
                    if (element.id === "Card_amount") {
                      element.focus();
                    }
                  });
                }
              }}
              id={"Cash_amount"}
              type={"number"}
              placeholder={"0.00"}
              value={
                billingDetails.cashAmount !== 0 ? billingDetails.cashAmount : ""
              }
              onchange={(t) => {
                if (TotalAmount > 0) {
                  setBillingDetails((e) => {
                    return {
                      ...e,
                      cashAmount: t.target.value === "" ? 0 : t.target.value,
                    };
                  });
                } else {
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: `Please add items to do bill settlement`,
                    })
                  );
                }
              }}
            />

            <LabelTextInput
              onFocus={() => {
                setisTextActive(true);
              }}
              label={"BTC Amount"}
              align={"right"}
              onBlur={() => {
                setisTextActive(false);
              }}
              onKeyDown={(e) => {
                if (e.code === "Enter" || e.code === "NumpadEnter") {
                  if (billingDetails.btcAmount > 0) {
                    const inputFields =
                      fromref.current.querySelectorAll("input, select");
                    inputFields.forEach((element) => {
                      if (element.id === "Coupan_amount") {
                        element.focus();
                      }
                    });
                  } else {
                    setListAccountMasterHelp(true);
                  }
                }
                if (e.code === "ArrowDown") {
                  inputFields.forEach((element) => {
                    if (element.id === "Coupan_no") {
                      element.focus();
                    }
                  });
                } else {
                  if (e.code === "ArrowUp") {
                    inputFields.forEach((element) => {
                      if (element.id === "Card_amount") {
                        element.focus();
                      }
                    });
                  } else {
                    if (e.code === "ArrowRight") {
                      inputFields.forEach((element) => {
                        if (element.id === "Coupan_amount") {
                          element.focus();
                        }
                      });
                    } else {
                      if (e.code === "ArrowLeft") {
                        inputFields.forEach((element) => {
                          if (element.id === "eAmount") {
                            element.focus();
                          }
                        });
                      }
                    }
                  }
                }
              }}
              id={"BTC_amount"}
              type={"number"}
              onDoubleClick={() => {
                const remainingTotal =
                  Number(grandTotalWithTax) -
                  (Number(billingDetails.cashAmount) +
                    Number(billingDetails.cardAmount) +
                    Number(billingDetails.eAmount) +
                    Number(billingDetails.couponAmount));

                setBillingDetails((e) => {
                  return {
                    ...e,
                    btcAmount: remainingTotal,
                  };
                });
              }}
              readOnly={width <= 1280}
              onClick={() => {
                dispatch(setnumericKeyboardType("BTC_AMT"));
                dispatch(setnumericKeyboardStatus(true));
                dispatch(
                  setIssueStockItem({
                    title: "BTC_AMT",
                    id: "1",
                    value:
                      billingDetails.btcAmount !== 0
                        ? billingDetails.btcAmount
                        : 0,
                  })
                );
              }}
              placeholder={"0.00"}
              value={
                billingDetails.btcAmount !== 0 ? billingDetails.btcAmount : ""
              }
              onchange={(t) => {
                if (TotalAmount > 0) {
                  setBillingDetails((e) => {
                    return {
                      ...e,
                      btcAmount: t.target.value === "" ? 0 : t.target.value,
                      btcName: e.customerName,
                    };
                  });
                } else {
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: `Please add items to do bill settlement`,
                    })
                  );
                }
              }}
            />

            <LabelTextInput
              onFocus={() => {
                setisTextActive(true);
              }}
              onBlur={() => {
                setisTextActive(false);
              }}
              label={"BTC Name"}
              id={"Btc_name"}
              readOnly={true}
              rightIcon={
                <button
                  onClick={() => {
                    setCustomermasetrHelp(true);
                  }}
                  className="ml-2 "
                >
                  <i className="bi bi-search flex items-center text-orange"></i>
                </button>
              }
              type={"text"}
              placeholder={"eg. Abc company"}
              value={billingDetails.btcName}
            />

            <LabelTextInput
              onDoubleClick={() => {
                const remainingTotal =
                  Number(grandTotalWithTax) -
                  (Number(billingDetails.cardAmount) +
                    Number(billingDetails.eAmount) +
                    Number(billingDetails.btcAmount) +
                    Number(billingDetails.cashAmount));
                setBillingDetails((e) => {
                  return {
                    ...e,
                    couponAmount: remainingTotal,
                  };
                });
              }}
              label={"Coupon Amount"}
              align={"right"}
              onFocus={() => {
                setisTextActive(true);
              }}
              onBlur={() => {
                setisTextActive(false);
              }}
              readOnly={width <= 1280}
              onClick={() => {
                dispatch(setnumericKeyboardType("COUPAN_AMT"));
                dispatch(setnumericKeyboardStatus(true));
                dispatch(
                  setIssueStockItem({
                    title: "COUPAN_AMT",
                    id: "1",
                    value:
                      billingDetails.couponAmount !== 0
                        ? billingDetails.couponAmount
                        : 0,
                  })
                );
              }}
              onKeyDown={(e) => {
                if (e.code === "Enter" || e.code === "NumpadEnter") {
                  const inputFields =
                    fromref.current.querySelectorAll("input, select");
                  inputFields.forEach((element) => {
                    if (element.id === "Coupan_no") {
                      element.focus();
                    }
                  });
                }
                if (e.code === "ArrowRight") {
                  inputFields.forEach((element) => {
                    if (element.id === "Coupan_no") {
                      element.focus();
                    }
                  });
                } else {
                  if (e.code === "ArrowUp") {
                    inputFields.forEach((element) => {
                      if (element.id === "eAmount") {
                        element.focus();
                      }
                    });
                  }
                }
              }}
              type={"number"}
              placeholder={"0.00"}
              id={"Coupan_amount"}
              value={
                billingDetails.couponAmount !== 0
                  ? billingDetails.couponAmount
                  : ""
              }
              onchange={(t) => {
                if (TotalAmount > 0) {
                  setBillingDetails((e) => {
                    return {
                      ...e,
                      couponAmount: t.target.value === "" ? 0 : t.target.value,
                    };
                  });
                } else {
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: `Please add items to do bill settlement`,
                    })
                  );
                }
              }}
            />

            <LabelTextInput
              id={"Coupan_no"}
              label={"Coupan No."}
              onKeyDown={(e) => {
                if (e.code === "ArrowLeft") {
                  inputFields.forEach((element) => {
                    if (element.id === "Coupan_amount") {
                      element.focus();
                    }
                  });
                } else {
                  if (e.code === "ArrowUp") {
                    inputFields.forEach((element) => {
                      if (element.id === "BTC_amount") {
                        element.focus();
                      }
                    });
                  }
                }
              }}
              onFocus={() => {
                setisTextActive(true);
              }}
              onBlur={() => {
                setisTextActive(false);
              }}
              readOnly={width <= 1280}
              onClick={() => {
                dispatch(setnumericKeyboardType("COUPAN_NO"));
                dispatch(setnumericKeyboardStatus(true));
                dispatch(
                  setIssueStockItem({
                    title: "COUPAN_NO",
                    id: "1",
                    value:
                      billingDetails.couponNo !== 0
                        ? billingDetails.couponNo
                        : 0,
                  })
                );
              }}
              placeholder={"123456789"}
              value={
                billingDetails.couponNo !== 0 ? billingDetails.couponNo : ""
              }
              onchange={(t) => {
                if (TotalAmount > 0) {
                  setBillingDetails((e) => {
                    return {
                      ...e,
                      couponNo: t.target.value === "" ? 0 : t.target.value,
                    };
                  });
                } else {
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: `Please add items to do bill settlement`,
                    })
                  );
                }
              }}
            />

            <LabelTextInput
              icon={
                <div>
                  <i className="bi bi-currency-rupee text-[#b5b3b3]  flex items-center"></i>
                </div>
              }
              label={"Balance"}
              id={"Balance"}
              onKeyDown={(e) => {
                if (e.code === "ArrowLeft") {
                  inputFields.forEach((element) => {
                    if (element.id === "Coupan_amount") {
                      element.focus();
                    }
                  });
                } else {
                  if (e.code === "ArrowUp") {
                    inputFields.forEach((element) => {
                      if (element.id === "BTC_amount") {
                        element.focus();
                      }
                    });
                  }
                }
              }}
              onFocus={() => {
                setisTextActive(true);
              }}
              onBlur={() => {
                setisTextActive(false);
              }}
              readOnly={true}
              borderColor={"#262626"}
              placeholder={"Balance"}
              value={`${
                TotalAmount &&
                FormatNumber2(
                  grandTotalWithTax -
                    (Number(billingDetails.couponAmount) +
                      Number(billingDetails.btcAmount) +
                      Number(billingDetails.cashAmount) +
                      Number(billingDetails.cardAmount) +
                      Number(billingDetails.eAmount))
                )
              }`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
function ActionButtons(
  billCartList,
  billingDetails,
  grandTotalWithTax,
  dispatch,
  prefix,
  token,
  clearBilling,
  setBillNo,
  setAlertTwoButtonModal,
  setViewBillStatus,
  inputFields,
  customerDetails,
  SaleType,
  setModalStatus,
  longtitude,
  latitude,
  Undo,
  setIsOriginalBill,
  roundOffAmt,
  editBillFields,
  setBillCartList,
  totalDiscount,
  lastCustomerDiscountrate,
  setBillingDetails,
  profileSetting,
  setLastCustomerDiscountrate,
  isCrossOrder,
  navigate,
  setCustomerDetails,
  setPrintData,
  onClickPrintBill
) {
  return (
    <div className="flex justify-between flex-1 py-2 px-2 ">
      {(totalDiscount < 1 || lastCustomerDiscountrate > 0) &&
        customerDetails?.DISQ_RATE > 0 && (
          <div className="flex items-center text-sm">
            <input
              type="checkbox"
              checked={customerDetails?.DISQ_RATE === billCartList[0].DISQ_RATE}
              className="mr-1 flex items-center"
              onChange={(e) => {
                if (e.target.checked) {
                  editBillFields(
                    {
                      field: "DISQ_RATE_ALL",
                      value: customerDetails?.DISQ_RATE,
                      id: 1,
                    },
                    setBillCartList,
                    dispatch,
                    billCartList,
                    billingDetails,
                    customerDetails,
                    setAlertTwoButtonModal,
                    setBillingDetails,
                    profileSetting,
                    setCustomerDetails
                  );
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: `Customer Discount ${customerDetails?.DISQ_RATE}% Applied `,
                    })
                  );
                  setLastCustomerDiscountrate(customerDetails?.DISQ_RATE);
                } else {
                  editBillFields(
                    {
                      field: "DISQ_RATE_ALL",
                      value: 0,
                      id: 1,
                    },
                    setBillCartList,
                    dispatch,
                    billCartList,
                    billingDetails,
                    customerDetails,
                    setAlertTwoButtonModal,
                    setBillingDetails,
                    profileSetting,
                    setCustomerDetails
                  );
                }
              }}
            />
            <div className="text-red-500 flex items-center -mt-[3px]">
              {`Apply ${customerDetails.DISQ_RATE}% Customer Discount.`}
            </div>
          </div>
        )}

      <div className="flex-1 justify-end flex">
        <div className="flex">
          {billCartList[0]?.INVNO &&
          billCartList[0]?.ORDER_TYPE.toLowerCase() !== "pickup" &&
          billCartList[0]?.ORDER_TYPE.toLowerCase() !== "delivery" ? (
            <ModifyButton
              onClick={() => {
                const TotalreceivedAmt =
                  Number(billingDetails.cashAmount) +
                  Number(billingDetails.cardAmount) +
                  Number(billingDetails.btcAmount) +
                  Number(billingDetails.eAmount) +
                  Number(billingDetails.couponAmount);

                if (TotalreceivedAmt >= Number(grandTotalWithTax)) {
                  if (TotalreceivedAmt === Number(grandTotalWithTax)) {
                    onBillSave(
                      billCartList,
                      dispatch,
                      prefix,
                      token,
                      Undo,
                      grandTotalWithTax,
                      setBillNo,
                      setAlertTwoButtonModal,
                      setViewBillStatus,
                      inputFields,
                      billCartList[0].SALE_TYPE,
                      setModalStatus,
                      "",
                      longtitude,
                      latitude,
                      false,
                      customerDetails,
                      setIsOriginalBill,
                      roundOffAmt,
                      isCrossOrder,
                      navigate,
                      setPrintData,
                      onClickPrintBill
                    );
                  } else {
                    dispatch(
                      setAlertWithTitle({
                        title: "Alert",
                        msg: `Recived Amount cannot be greater then bill amount.`,
                      })
                    );
                  }
                } else {
                  //  inputFields.forEach((element) => {
                  //    if (element.id === "Cash_amount") {
                  //      element.focus();
                  //    }
                  //  });
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: `Bill Settlement not cleared.`,
                    })
                  );
                }
              }}
            />
          ) : (
            <SaveButton
              onClick={() => {
                const phoneRegex = /^[6-9]\d{9}$/;
                if (
                  (customerDetails.CUSTOMER_MOBILE &&
                    phoneRegex.test(customerDetails.CUSTOMER_MOBILE)) ||
                  !customerDetails.CUSTOMER_MOBILE
                ) {
                  const TotalreceivedAmt =
                    Number(billingDetails.cashAmount) +
                    Number(billingDetails.cardAmount) +
                    Number(billingDetails.btcAmount) +
                    Number(billingDetails.eAmount) +
                    Number(billingDetails.couponAmount);

                  if (TotalreceivedAmt >= Number(grandTotalWithTax)) {
                    if (TotalreceivedAmt === Number(grandTotalWithTax)) {
                      onBillSave(
                        billCartList,
                        dispatch,
                        prefix,
                        token,
                        Undo,
                        grandTotalWithTax,
                        setBillNo,
                        setAlertTwoButtonModal,
                        setViewBillStatus,
                        inputFields,
                        SaleType,
                        setModalStatus,
                        "",
                        longtitude,
                        latitude,
                        false,
                        customerDetails,
                        setIsOriginalBill,
                        roundOffAmt,
                        isCrossOrder,
                        navigate,
                        setPrintData,
                        onClickPrintBill
                      );
                    } else {
                      dispatch(
                        setAlertWithTitle({
                          title: "Alert",
                          msg: `Recived Amount cannot be greater then Bill amount.`,
                        })
                      );
                    }
                  } else {
                    inputFields.forEach((element) => {
                      if (element.id === "Cash_amount") {
                        element.focus();
                      }
                    });
                    dispatch(
                      setAlertWithTitle({
                        title: "Alert",
                        msg: `Bill Settlement not cleared.`,
                      })
                    );
                  }
                } else {
                  inputFields.forEach((element) => {
                    if (element.id === "Customer_mobile") {
                      element.focus();
                    }
                  });
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: "Customer mobile is Invalid.",
                    })
                  );
                }
              }}
            />
          )}
          <UndoButton onClick={clearBilling} />
        </div>
      </div>
    </div>
  );
}

function itemListComp(
  itemList,
  billCartList,
  setBillCartList,
  billingDetails,
  customerDetails,
  dispatch,
  setAlertTwoButtonModal,
  isStockCheck,
  setItem_Box,
  setSalePriceModalStatus,
  itemImgList,
  prefix,
  token,
  setSectionName,
  setItemList,
  width,
  profileSetting,
  totalItemsPage,
  currentItemsPage,
  onChangePagination
) {
  return (
    <div
      style={{
        // height: `calc(100% - ${width < 640 ? 50 : 9}px)`,
        height: `calc(100% - ${
          totalItemsPage > 1 ? (width < 1324 ? 0 : 10) : width < 1035 ? 0 : 10
        }px)`,
      }}
      className=" border rounded-sm p-2 shadow-sm flex flex-col mt-2  bg-[#f8f8fd] overflow-y-auto  pr-1"
    >
      {itemList[0] ? (
        <div
          // style={{

          // }}
          className="grid grid-cols-1 gap-2 sm:grid-cols-2 t:grid-cols-2 xl:grid-cols-2 lt:grid-cols-1 2xl:grid-cols-2 3xl:grid-cols-3
             bg-[#f8f8fd] "
        >
          {itemList?.map((box, index) => {
            return (
              <ItemBox
                onClick={(e) => {
                  const isReturnAvailable = billCartList.filter(
                    (e) => e.ICODE === box.ICODE && Number(e.RQNTY) > 0
                  );
                  if (!isReturnAvailable[0]) {
                    if (box.SALE_PRICE > 0) {
                      itemClick(
                        e,
                        box,
                        setBillCartList,
                        billingDetails,
                        customerDetails,
                        1,
                        dispatch,
                        setAlertTwoButtonModal,
                        false,
                        isStockCheck,
                        prefix,
                        token,
                        false,
                        profileSetting,
                        billCartList
                      );
                    } else {
                      const isInBillCart = billCartList.filter(
                        (t) => t.ICODE === box.ICODE
                      );

                      if (isInBillCart[0]) {
                        const data = {
                          ...box,
                          SALE_PRICE: isInBillCart[0].SALE_PRICE,
                        };

                        itemClick(
                          data,
                          data,
                          setBillCartList,
                          billingDetails,
                          customerDetails,
                          1,
                          dispatch,
                          setAlertTwoButtonModal,
                          false,
                          isStockCheck,
                          prefix,
                          token,
                          false,
                          profileSetting,
                          billCartList
                        );
                      } else {
                        setItem_Box(box);

                        setSalePriceModalStatus(true);
                      }
                    }
                  } else {
                    dispatch(
                      setAlertWithTitle({
                        title: "Alert",
                        msg: "You cannot enter a value for Qnty after entering Return. Please remove the value from Return before entering Qnty.",
                        horizontal: true,
                      })
                    );
                  }
                }}
                box={box}
                key={index}
                index={index}
                mobileWidth={mobileViewWidth}
                imgCard={itemImgList}
              />
            );
          })}
        </div>
      ) : (
        <div className="w-full h-full  flex items-center justify-center">
          <LoadItems
            prefix={prefix}
            token={token}
            dispatch={dispatch}
            setSectionName={setSectionName}
            setItemList={setItemList}
          ></LoadItems>
        </div>
      )}
      {totalItemsPage > 1 && (
        <div className="flex justify-end pt-3 pb-2">
          <Pagination
            page={currentItemsPage}
            count={totalItemsPage}
            onChange={(e, newPage) => {
              onChangePagination(newPage);
            }}
            renderItem={(item) => (
              <PaginationItem
                {...item}
                sx={{
                  backgroundColor: item.selected ? "#ff4500" : "#e6e6e3", // Custom colors
                  color: item.selected ? "black" : "black",
                  fontWeight: item.selected ? "bold" : "normal",
                  borderRadius: "10px",
                  height: 40,
                  width: 40,
                  "&:hover": { backgroundColor: "#f5874f", color: "#fff" },
                }}
              />
            )}
            siblingCount={0} // Hides the page numbers
            boundaryCount={0} // Hides the first and last page buttons
            showFirstButton={false} // Hide "First" button
            showLastButton={false} // Hide "Last" button
          />
        </div>
      )}
    </div>
  );
}
function LoadItems(props) {
  return <div className="text-gray-400">No items found. </div>;
}
function totalQntyComponent(
  c,
  TotalQnty,
  totalReturn,
  billCartList,
  TotalAmount,
  totalReturnAmount,
  width,
  grandTotalWithTax,
  modal,
  dispatch
) {
  return (
    <div className="text-[12px]  t:text-[13px]">
      <div className="  flex rounded-sm  rounded-br-none      bg-[#f7f7fd]">
        {c[0]?.width && (
          <div
            style={{
              width: c[0].width,
            }}
            className=" pr-1 pl-2 border-r py-2  rounded-tl-sm  rounded-bl-sm    text-red-500 rounded-l-sm flex items-center   border-l border-b  border-t text-right  font-semibold "
          >
            <div className="pr-1 py-2">Sale Qnty</div>:
            <div className="ml-2 py-2">{TotalQnty}</div>
          </div>
        )}

        {c[1]?.width && width > mobileViewWidth && (
          <div
            style={{
              width: c[1].width,
            }}
            className=" p-1 border-r py-2  border-b flex items-center justify-end  border-t text-right  font-semibold "
          >
            Refund
          </div>
        )}

        {c[2]?.width && (
          <div
            style={{
              width: c[2].width,
            }}
            className=" p-1 border-r py-2 border-b flex items-center justify-end  border-t text-right  font-semibold "
          >
            {width > mobileViewWidth || modal
              ? FormatNumber2(totalReturnAmount)
              : ""}
          </div>
        )}

        {c[3]?.width && (
          <div
            style={{
              width: c[3].width,
            }}
            className="  flex items-center  justify-end  border-r  border-b border-t text-right  font-semibold "
          >
            {" "}
            <button
              disabled={!billCartList[0]}
              className="bg-orange w-full h-full"
              onClick={() => {
                dispatch(setnumericKeyboardType("DISQ_RATE_ALL"));
                dispatch(setnumericKeyboardStatus(true));
                dispatch(
                  setIssueStockItem({
                    title: "DISQ_RATE_ALL",
                    id: "DISQ_RATE_ALL",
                    value: 0,
                  })
                );
              }}
            >
              Disc. %
            </button>
          </div>
        )}

        {c[5]?.width && (
          <div
            style={{
              width: c[4].width + c[5].width,
            }}
            className=" border-r  border-b flex items-center justify-end  border-t text-right  font-semibold "
          >
            {modal ? (
              FormatNumber2(TotalAmount)
            ) : (
              <button
                disabled={!billCartList[0]}
                className="bg-orange w-full h-full"
                onClick={() => {
                  dispatch(setnumericKeyboardType("DISQ_AMT_ALL"));
                  dispatch(setnumericKeyboardStatus(true));
                  dispatch(
                    setIssueStockItem({
                      title: "DISQ_AMT_ALL",
                      id: "DISQ_AMT_ALL",
                      value: 0,
                    })
                  );
                }}
              >
                Disc. Amt.
              </button>
            )}
          </div>
        )}

        {c[6]?.width && (
          <div
            style={{
              width: c[6].width,
            }}
            className=" p-1  border-r py-2 flex items-center justify-end border-b  border-t  rounded-br-none  text-right  font-semibold  "
          >
            <div>Grand Total</div>
          </div>
        )}

        {c[7]?.width && (
          <div
            style={{
              width: c[7].width,
            }}
            className=" pl-1  border-r py-2 pr-2 flex items-center justify-end border-b  border-t rounded-tr-sm  rounded-br-sm  text-right  font-semibold  "
          >
            {FormatNumber2(grandTotalWithTax)}
          </div>
        )}
      </div>
      <div
        style={{
          fontSize: FontSizeWidth(width),
        }}
        className="flex  font-semibold    w-full     bg-[#f7f7fd]"
      >
        {c[8]?.width && (
          <div className="rounded-t-sm rounded-tl-none text-[12px] w-full t:text-[14px] rounded-tr-none">
            <div className="flex   justify-end  ">
              <div className="flex bg-[#f7f7fd] "></div>
            </div>
            <div className="flex   justify-end  ">
              <div className="flex bg-[#f7f7fd] ">
                <div
                  style={{
                    width: c[0].width + c[1].width,
                  }}
                  className="text-end p-1 py-2 border-r "
                >
                  Grand Total
                </div>
                <div
                  style={{
                    width: c[7].width + c[8].width,
                  }}
                  className="  text-right p-1 border-r py-2 text-[12px]  t:text-[16px] "
                >
                  {FormatNumber2(grandTotalWithTax)}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
function searchItemInput(
  searchBoxRef,
  searchRef,
  width,
  setBarCodeScannerStatus,
  itemSearchText,
  billCartList,
  dispatch,
  setBillCartList,
  billingDetails,
  customerDetails,
  setAlertTwoButtonModal,
  isStockCheck,
  setSearchQnty,
  setItem_Box,
  setSalePriceModalStatus,
  setItemSearchText,
  prefix,
  token,
  setItemList,
  setSectionName,
  profileSetting,
  setTotalItemsPage,
  currentItemsPage
) {
  return (
    <div ref={searchBoxRef} className=" shadow-sm rounded-sm sm:flex flex-1">
      <PlaceHolderTextInput
        icon={
          <i className="bi mx-2 bi-search text-[#b5b3b3]  flex  items-center"></i>
        }
        autoFocus={window.innerWidth > 1280}
        id={"search"}
        autoFill={"off"}
        reffrence={searchRef}
        type={"text"}
        rightIcon={
          width <= 1280 ? (
            <button
              onClick={() => {
                setBarCodeScannerStatus(true);
              }}
            >
              <i className="bi bi-upc-scan text-orange"></i>
            </button>
          ) : null
        }
        placeholder={"Search Items"}
        value={itemSearchText}
        onKeyDown={(e) => {
          if (
            e.code === "Enter" ||
            e.key === "Enter" ||
            (e.code === "NumpadEnter" && e.target.value)
          ) {
            if (e.target.value.includes("+")) {
              const numbers = e.target.value.split("+"); // Split the string by "+"
              const secondNumber = numbers[1];
              const ICODE = numbers[0];

              const s = secondNumber ? secondNumber : 1;
              getItemByCode(prefix, ICODE, token, dispatch, true).then(
                (list) => {
                  if (list[0]) {
                    const filterd = billCartList.filter(
                      (item) => item.ICODE === ICODE && item.RQNTY > 0
                    );
                    if (filterd[0]) {
                      enqueueSnackbar(`Not Permitted`, {
                        variant: "warning",
                      });
                      dispatch(
                        setAlertWithTitle({
                          title: "Alert",
                          msg: "You cannot enter a value for Qnty after entering Return. Please remove the value from Return before entering Qnty.",
                          horizontal: true,
                        })
                      );
                    } else {
                      if (list[0].SALE_PRICE > 0) {
                        itemClick(
                          list[0],
                          list[0],
                          setBillCartList,
                          billingDetails,
                          customerDetails,
                          s,
                          dispatch,
                          setAlertTwoButtonModal,
                          false,
                          isStockCheck,
                          prefix,
                          token,
                          false,
                          profileSetting,
                          billCartList
                        );
                      } else {
                        const isInBillCart = billCartList.filter(
                          (t) => t.ICODE === list[0].ICODE
                        );
                        if (isInBillCart[0]) {
                          const data = {
                            ...list[0],
                            SALE_PRICE: isInBillCart[0].SALE_PRICE,
                          };
                          itemClick(
                            data,
                            data,
                            setBillCartList,
                            billingDetails,
                            customerDetails,
                            s,
                            dispatch,
                            setAlertTwoButtonModal,
                            false,
                            isStockCheck,
                            prefix,
                            token,
                            false,
                            profileSetting,
                            billCartList
                          );
                        } else {
                          setSearchQnty(s);
                          setItem_Box(list[0]);

                          setSalePriceModalStatus(true);
                        }
                      }
                    }
                    setItemSearchText("");
                  } else {
                    enqueueSnackbar(`Item Not Found.`, {
                      variant: "error",
                    });
                    setItemSearchText("");
                  }
                }
              );
            } else {
              const isNotANumber = /^[^0-9]/;
              if (isNotANumber.test(e.target.value)) {
                getItemByName(prefix, e.target.value, token, dispatch).then(
                  (items) => {
                    setItemList(items);
                    setItemSearchText("");
                  }
                );
              } else {
                const isInBillList = billCartList.filter(
                  (o) => o.ICODE === Number(e.target.value)
                );
                if (!isInBillList[0]) {
                  getItemByCode(
                    prefix,
                    e.target.value,
                    token,
                    dispatch,
                    true
                  ).then((list) => {
                    if (list[0]) {
                      const filterd = billCartList.filter(
                        (item) => item.ICODE === list[0].ICODE && item.RQNTY > 0
                      );
                      if (filterd[0]) {
                        enqueueSnackbar(`Not Permitted`, {
                          variant: "warning",
                        });
                        dispatch(
                          setAlertWithTitle({
                            title: "Alert",
                            msg: "You cannot enter a value for Qnty after entering Return. Please remove the value from Return before entering Qnty.",
                            horizontal: true,
                          })
                        );
                      } else {
                        if (list[0].SALE_PRICE > 0) {
                          itemClick(
                            list[0],
                            list[0],
                            setBillCartList,
                            billingDetails,
                            customerDetails,
                            1,
                            dispatch,
                            setAlertTwoButtonModal,
                            false,
                            isStockCheck,
                            prefix,
                            token,
                            false,
                            profileSetting,
                            billCartList
                          );
                        } else {
                          const isInBillCart = billCartList.filter(
                            (t) => t.ICODE === list[0].ICODE
                          );
                          if (isInBillCart[0]) {
                            const data = {
                              ...list[0],
                              SALE_PRICE: isInBillCart[0].SALE_PRICE,
                            };

                            itemClick(
                              data,
                              data,
                              setBillCartList,
                              billingDetails,
                              customerDetails,
                              1,
                              dispatch,
                              setAlertTwoButtonModal,
                              false,
                              isStockCheck,
                              prefix,
                              token,
                              false,
                              profileSetting,
                              billCartList
                            );
                          } else {
                            setItem_Box(list[0]);

                            setSalePriceModalStatus(true);
                          }
                        }
                      }
                      setItemSearchText("");
                    } else {
                      getItemByAlios(
                        prefix,
                        e.target.value,
                        token,
                        dispatch,
                        true
                      ).then((list) => {
                        if (list[0]) {
                          const filterd = billCartList.filter(
                            (item) =>
                              item.ICODE === list[0].ICODE && item.RQNTY > 0
                          );
                          if (filterd[0]) {
                            enqueueSnackbar(`Not Permitted`, {
                              variant: "warning",
                            });
                            dispatch(
                              setAlertWithTitle({
                                title: "Alert",
                                msg: "You cannot enter a value for Qnty after entering Return. Please remove the value from Return before entering Qnty.",
                                horizontal: true,
                              })
                            );
                          } else {
                            if (list[0].SALE_PRICE > 0) {
                              itemClick(
                                list[0],
                                list[0],
                                setBillCartList,
                                billingDetails,
                                customerDetails,
                                1,
                                dispatch,
                                setAlertTwoButtonModal,
                                false,
                                isStockCheck,
                                prefix,
                                token,
                                false,
                                profileSetting,
                                billCartList
                              );
                            } else {
                              const isInBillCart = billCartList.filter(
                                (t) => t.ICODE === list[0].ICODE
                              );
                              if (isInBillCart[0]) {
                                const data = {
                                  ...list[0],
                                  SALE_PRICE: isInBillCart[0].SALE_PRICE,
                                };

                                itemClick(
                                  data,
                                  data,
                                  setBillCartList,
                                  billingDetails,
                                  customerDetails,
                                  1,
                                  dispatch,
                                  setAlertTwoButtonModal,
                                  false,
                                  isStockCheck,
                                  prefix,
                                  token,
                                  false,
                                  profileSetting,
                                  billCartList
                                );
                              } else {
                                setItem_Box(list[0]);

                                setSalePriceModalStatus(true);
                              }
                            }
                          }
                          setItemSearchText("");
                        } else {
                          dispatch(
                            setAlertWithTitle({
                              title: "Alert",
                              msg: "Item not found",
                            })
                          );
                        }
                      });
                    }
                  });
                } else {
                  const list = isInBillList;
                  if (list[0]) {
                    const filterd = billCartList.filter(
                      (item) => item.ICODE === list[0].ICODE && item.RQNTY > 0
                    );
                    if (filterd[0]) {
                      enqueueSnackbar(`Not Permitted`, {
                        variant: "warning",
                      });
                      dispatch(
                        setAlertWithTitle({
                          title: "Alert",
                          msg: "You cannot enter a value for Qnty after entering Return. Please remove the value from Return before entering Qnty.",
                          horizontal: true,
                        })
                      );
                    } else {
                      if (list[0].SALE_PRICE > 0) {
                        itemClick(
                          list[0],
                          list[0],
                          setBillCartList,
                          billingDetails,
                          customerDetails,
                          1,
                          dispatch,
                          setAlertTwoButtonModal,
                          false,
                          isStockCheck,
                          prefix,
                          token,
                          false,
                          profileSetting,
                          billCartList
                        );
                      } else {
                        const isInBillCart = billCartList.filter(
                          (t) => t.ICODE === list[0].ICODE
                        );
                        if (isInBillCart[0]) {
                          const data = {
                            ...list[0],
                            SALE_PRICE: isInBillCart[0].SALE_PRICE,
                          };

                          itemClick(
                            data,
                            data,
                            setBillCartList,
                            billingDetails,
                            customerDetails,
                            1,
                            dispatch,
                            setAlertTwoButtonModal,
                            false,
                            isStockCheck,
                            prefix,
                            token,
                            false,
                            profileSetting,
                            billCartList
                          );
                        } else {
                          setItem_Box(list[0]);

                          setSalePriceModalStatus(true);
                        }
                      }
                    }
                    setItemSearchText("");
                  } else {
                    dispatch(
                      setAlertWithTitle({
                        title: "Alert",
                        msg: "Item not found",
                      })
                    );
                  }
                }
              }
            }
          }
        }}
        onchange={(t) => {
          setItemSearchText(() => t.target.value);
        }}
      />
      <button
        style={{
          marginTop: window.innerWidth >= 640 ? "0px" : "8px",
          backgroundColor: "#f7b03e",
          color: "black",
        }}
        onClick={() => {
          setSectionName(fastMovingMovieItem);
          GetListItemMaster(
            prefix,
            fastMovingMovieItem,
            token,
            dispatch,
            "",
            "",
            setItemList
          );
        }}
        className=" whitespace-nowrap px-2 py-2 h-full sm:w-fit w-full   rounded-sm  sm:ml-2"
      >
        {" Fast Moving Items"}
      </button>
    </div>
  );
}

function billCart(c, billCartList, dispatch) {
  return (
    <EditableDataGrid
      columns={c}
      hideScroll={true}
      tableBgColor={"#f7f7fd"}
      cellOubleClickOpn={true}
      rowBgColor={billCartList.filter((e) => e.RQNTY > 0)}
      rowBgColor2={billCartList.filter((e) => e.IS_OFFER)}
      onCellDoubleClick={(e) => {
        if (e.column.field === "QNTY") {
          const item = e.row.values;

          if (Number(item.RQNTY) === 0) {
            dispatch(setnumericKeyboardType("QNTY"));
            dispatch(setnumericKeyboardStatus(true));
            dispatch(
              setIssueStockItem({
                title: item.ITEMNAME,
                id: item.ICODE,
                value: item.QNTY,
              })
            );
          } else {
            dispatch(
              setAlertWithTitle({
                title: "Alert",
                msg: "You cannot enter a value for Qnty after entering Return. Please remove the value from Return before entering Qnty.",
                horizontal: true,
              })
            );
          }
        }
        if (e.column.field === "SALE_PRICE") {
          const item = e.row.values;
          dispatch(setnumericKeyboardType("SALE_PRICE"));
          dispatch(setnumericKeyboardStatus(true));
          dispatch(
            setIssueStockItem({
              title: item.ITEMNAME,
              id: item.ICODE,
              value: item.SALE_PRICE,
            })
          );
        }
        if (e.column.field === "DISQ_AMT") {
          if (e.row.values.RQNTY > 0) {
            dispatch(
              setAlertWithTitle({
                title: "Alert",
                msg: "You cannot enter a value for Discount after entering Return. Please remove the value from Return before entering Qnty.",
                horizontal: true,
              })
            );
          } else {
            const item = e.row.values;
            dispatch(setnumericKeyboardType("DISQ_AMT"));
            dispatch(setnumericKeyboardStatus(true));
            dispatch(
              setIssueStockItem({
                title: item.ITEMNAME,
                id: item.ICODE,
                value: item.DISQ_AMT,
              })
            );
          }
        }
        if (e.column.field === "DISQ_RATE") {
          if (e.row.values.RQNTY > 0) {
            dispatch(
              setAlertWithTitle({
                title: "Alert",
                msg: "You cannot enter a value for Discount after entering Return. Please remove the value from Return before entering Qnty.",
                horizontal: true,
              })
            );
          } else {
            const item = e.row.values;
            dispatch(setnumericKeyboardType("DISQ_RATE"));
            dispatch(setnumericKeyboardStatus(true));
            dispatch(
              setIssueStockItem({
                title: item.ITEMNAME,
                id: item.ICODE,
                value: item.DISQ_RATE,
              })
            );
          }
        }
        if (e.column.field === "RQNTY") {
          const item = e.row.values;
          dispatch(setnumericKeyboardType("RQNTY"));
          dispatch(setnumericKeyboardStatus(true));
          dispatch(
            setIssueStockItem({
              title: item.ITEMNAME,
              id: item.ICODE,
              value: item.RQNTY,
            })
          );
        }
      }}
      isItalic={false}
      data={billCartList}
    />
  );
}

function billNoComp(
  setBillHelpStatus,
  billCartList,
  date,
  profileSetting,
  dispatch,
  setSettingModalStatus
) {
  return (
    <div className=" border   shadow-sm flex rounded-sm bg-[#f8f8fd]  items-center justify-between">
      <div className="flex p-2">
        <div
          onClick={() => {
            if (profileSetting?.MODIFY_SALE_BILL === "Yes") {
              setBillHelpStatus(true);
            } else {
              dispatch(
                setAlertWithTitle({
                  title: "Alert",
                  msg: `You don't have permission to modify the bill.`,
                })
              );
            }
          }}
          className="flex"
        >
          <button>
            <i className="bi bi-search text-orange mr-1 flex items-center"></i>
          </button>
          <div className="font-semibold flex text-center  ">
            Bill No. : {billCartList[0] ? billCartList[0].INVNO : ""}
          </div>
        </div>
      </div>
      <div className="flex  ">
        <div className="text-gray-500 p-2">
          {moment(date).format("DD/MM/YYYY")}
        </div>
        <button
          onClick={() => {
            setSettingModalStatus((e) => !e);
          }}
          className="bg-[#3e3e3e] h-full py-2 px-3 rounded-r-sm"
        >
          <i className="bi bi-gear-fill text-white text-[15px]"></i>
        </button>
      </div>
    </div>
  );
}
export const dummyBill = [
  {
    INVNO: 2527,
    BILL_STATUS: "Settled",
    SALE_TYPE: "Exclusive",
    ORDER_TYPE: "Counter Sale",
    DATE: "2025-02-12T12:08:18.000Z",
    USER: "",
    BILL_TYPE: "Retail",
    ACCOUNT_NO: 0,
    NAME: "",
    ADDRESS: "",
    MOBILE: "",
    TAX_NUMBER: "",
    CUSTOMER_CODE: 0,
    CUSTOMER_NAME: "",
    CUSTOMER_ADDRESS: "",
    CUSTOMER_MOBILE: "",
    CUSTOMER_EMAIL: "",
    ICODE: 3,
    ITEMNAME: "ASSAM TEA",
    ITEM_INSTRUCTIONS: "",
    ITEM_DESC: "",
    PACKING: "1",
    UNIT: "PCS",
    SECTION_NAME: "TEA & COFFEE",
    SUB_SEC_NAME: "SALEABLE ITEMS",
    COLOR: "",
    SIZE: "",
    CATEGORY: "",
    BRAND_NAME: "",
    PUR_PRICE: 459,
    MRP_RATE: 500,
    SALE_PRICE: 530,
    QNTY: 2,
    RQNTY: 0,
    AMOUNT: 1060,
    DISQ_RATE: 0,
    DISQ_AMT: 0,
    NET_AMOUNT: 1060,
    HSN_CODE: "996321",
    TAX1_RATE: "2.5",
    TAX1_AMT: 26.5,
    TAX2_RATE: "2.5",
    TAX2_AMT: 26.5,
    TAX3_RATE: "0",
    TAX3_AMT: 0,
    CESS_RATE: 0,
    CESS_AMOUNT: 0,
    ROUND_OFF: -0.16,
    TOTAL: 47277,
    HANDLING_CHARGES: 0,
    DELIVERY_CHARGES: 0,
    GTOTAL: 55191,
    REMARKS: "",
    CARD_NO: "",
    CARD_NAME: "",
    SALES_MAN: "",
    NO_POINTS: 0,
    LOYALTY_AMOUNT: 0,
    LESS_LOYALITY: 0,
    AMOUNT_RCV: 0,
    ORDER_NO: 0,
    PRINT_ORDER_SLIP: "No",
    CASH_AMOUNT: 0,
    E_AMOUNT: 55191,
    CARD_AMOUNT: 0,
    BTC_AMOUNT: 0,
    PUR_BILL_NO: 0,
    COUPAN_AMOUNT: 0,
    COUPAN_NO: "",
    DELIVERY_DATE: null,
    DELIVERY_TIME: "",
    DELIVERED_BY: "",
    DELIVERED_TIME: null,
  },
  {
    INVNO: 2527,
    BILL_STATUS: "Settled",
    SALE_TYPE: "Exclusive",
    ORDER_TYPE: "Counter Sale",
    DATE: "2025-02-12T12:08:19.000Z",
    USER: "",
    BILL_TYPE: "Retail",
    ACCOUNT_NO: 0,
    NAME: "",
    ADDRESS: "",
    MOBILE: "",
    TAX_NUMBER: "",
    CUSTOMER_CODE: 0,
    CUSTOMER_NAME: "",
    CUSTOMER_ADDRESS: "",
    CUSTOMER_MOBILE: "",
    CUSTOMER_EMAIL: "",
    ICODE: 4,
    ITEMNAME: "BATATA GRILLED SANDWICH 35/-",
    ITEM_INSTRUCTIONS: "",
    ITEM_DESC: "",
    PACKING: "1",
    UNIT: "UNIT",
    SECTION_NAME: "SNACKES",
    SUB_SEC_NAME: "SALEABLE ITEMS",
    COLOR: "",
    SIZE: "",
    CATEGORY: "",
    BRAND_NAME: "",
    PUR_PRICE: 122,
    MRP_RATE: 0,
    SALE_PRICE: 128,
    QNTY: 2,
    RQNTY: 0,
    AMOUNT: 256,
    DISQ_RATE: 0,
    DISQ_AMT: 0,
    NET_AMOUNT: 256,
    HSN_CODE: "996321",
    TAX1_RATE: "2.5",
    TAX1_AMT: 6.4,
    TAX2_RATE: "2.5",
    TAX2_AMT: 6.4,
    TAX3_RATE: "0",
    TAX3_AMT: 0,
    CESS_RATE: 0,
    CESS_AMOUNT: 0,
    ROUND_OFF: -0.16,
    TOTAL: 47277,
    HANDLING_CHARGES: 0,
    DELIVERY_CHARGES: 0,
    GTOTAL: 55191,
    REMARKS: "",
    CARD_NO: "",
    CARD_NAME: "",
    SALES_MAN: "",
    NO_POINTS: 0,
    LOYALTY_AMOUNT: 0,
    LESS_LOYALITY: 0,
    AMOUNT_RCV: 0,
    ORDER_NO: 0,
    PRINT_ORDER_SLIP: "No",
    CASH_AMOUNT: 0,
    E_AMOUNT: 55191,
    CARD_AMOUNT: 0,
    BTC_AMOUNT: 0,
    PUR_BILL_NO: 125,
    COUPAN_AMOUNT: 0,
    COUPAN_NO: "",
    DELIVERY_DATE: null,
    DELIVERY_TIME: "",
    DELIVERED_BY: "",
    DELIVERED_TIME: null,
  },
];
export {
  billNoComp,
  billSettelemntBilling,
  ActionButtons,
  itemListComp,
  totalQntyComponent,
  billCart,
  searchItemInput,
};
