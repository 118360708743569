import React, { useState } from "react";
import GeniustextInput from "../../utility/component/mastertext";
import { useDispatch, useSelector } from "react-redux";
import { selectPrefix, selectWidth } from "../../utility/Redux/profile";
import { FormatNumber2 } from "../../utility/functions/formatNumber";
import { CallAPIPromise } from "../../billing/common";
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from "../../utility/Redux/modal";
import { selectToken } from "../../utility/Redux/security";
import GeniusDatagrid from "../../utility/component/datagrid/geniusDatagrid";
import {
  DepartmentMasterHelp,
  SectionMasterHelp,
  SubSecMasterHelp,
} from "../../help";
import { LocalButton } from "../../utility/component/buttons";
import AlertModalTwoButton from "../../utility/component/modals/alertWithTwoButton";
import { Pagination } from "@mui/material";

function BulkItemMaster() {
  const [itemList, setItemList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sectionMasterHelpStatus, setSeactionMasterHelpStatus] =
    useState(false);
  const [subSectionMasterHelpStatus, setSubSeactionMasterHelpStatus] =
    useState(false);
  const [dephelpStatus, setDepHelpStatus] = useState(false);
  const [brandHelpStatus, setBrandHelpStatus] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: "",
    msg: "",
    status: false,
    setStatus: "",
    button1: "",
    button1Click: "",
    button2: "",
    button2Click: "",
  });

  const [searchData, setSearchData] = useState({
    ITEMNAME: "",
    SECTION_NAME: "",
    DEP_NAME: "",
    BRAND_NAME: "",
    SUB_SECTION_NAME: "",
  });
  const [updateData, setUpdateData] = useState({
    SECTION_NAME: "",
    DEP_NAME: "",
    BRAND_NAME: "",
    SUB_SECTION_NAME: "",
  });
  const [totalPages, settotalPages] = useState(1);
  const width = useSelector(selectWidth);
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const [selectedList, setSelectedList] = useState([]);
  const handleSelect = (item) => {
    // Check if the item is already selected
    const isSelected = selectedList.some(
      (selectedItem) => selectedItem.ICODE === item.ICODE
    );

    if (isSelected) {
      // Remove item from selected list
      setSelectedList(
        selectedList.filter((selectedItem) => selectedItem.ICODE !== item.ICODE)
      );
    } else {
      // Add item to selected list
      setSelectedList([...selectedList, item]);
    }
  };
  const columns = [
    {
      field: "Select",
      renderHeader: (params) => <div>Select</div>,
      width: 90,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const item = params.row;

        return (
          <div>
            <input
              type="checkbox"
              checked={selectedList.some(
                (selectedItem) => selectedItem.ICODE === item.ICODE
              )}
              onChange={() => handleSelect(item)} // Toggle selection
            />
          </div>
        );
      },
    },
    {
      width: 80,
      headerAlign: "center",
      align: "left",
      field: "ICODE",
      headerName: "Code",
      renderHeader: (params) => <div>Code</div>,
    },
    {
      width:
        width <= 768 ? 100 : width <= 1024 ? 100 : width <= 1440 ? 150 : 280,
      headerAlign: "center",
      align: "left",
      field: "ITEMNAME",
      headerName: "Item Name",
      renderHeader: (params) => <div>Item Name</div>,
    },
    {
      width: 90,
      headerAlign: "center",
      align: "right",
      field: "SALE_PRICE",
      headerName: "Rate",
      renderCell: (param) => {
        if (param.value === 0) {
          return <div> {""}</div>;
        } else {
          return FormatNumber2(param.value);
        }
      },
      renderHeader: (params) => <div>Rate</div>,
    },
    {
      field: "DEP_NAME",
      renderHeader: (params) => <div>Department</div>,
      width: 200,
      headerAlign: "center",
      align: "left",
    },
    {
      field: "SECTION_NAME",
      renderHeader: (params) => <div>Section</div>,
      width: 200,
      headerAlign: "center",
      align: "left",
    },
    {
      field: "SUB_SEC_NAME",
      renderHeader: (params) => <div>Sub-Section</div>,
      width: 200,
      headerAlign: "center",
      align: "left",
    },
    {
      field: "BRAND_NAME",
      renderHeader: (params) => <div>Brand </div>,
      width: 100,
      headerAlign: "center",
      align: "left",
    },
  ];
  const getData = async (
    page,
    item,
    hideloaing,
    ICODE,
    section,
    dep,
    brand,
    subSec
  ) => {
    setSearchText("");
    if (!hideloaing) {
      dispatch(setSpinnerLoading("Loading"));
    }
    setSelectedList([]);
    const data = {
      table_prefix: prefix,
      SECTION_NAME: section ? section : searchData.SECTION_NAME,
      SUB_SEC_NAME: subSec ? subSec : "",
      ICODE: ICODE ? ICODE : "",
      ALIAS_CODE: "",
      ITEMNAME: item ? item : searchData.ITEMNAME,
      BRAND_NAME: brand ? brand : searchData.BRAND_NAME,
      DEP_NAME: dep ? dep : searchData.BRAND_NAME,
      page: page ? page : currentPage,
      limit: 100,
    };
    CallAPIPromise("/api/master/listItemMaster", data, token, dispatch)
      .then((p) => {
        if (!p.data.error) {
          settotalPages(p.data.response.totalPages);

          setItemList(p.data.response.data);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };
  const updateBulkChanges = () => {
    const updateD = selectedList.map((i) => {
      return {
        ICODE: i.ICODE,
        SECTION_NAME: updateData.SECTION_NAME,
        SUB_SEC_NAME: updateData.SUB_SECTION_NAME,
        BRAND_NAME: updateData.BRAND_NAME,
        DEP_NAME: updateData.DEP_NAME,
      };
    });
    dispatch(setSpinnerLoading("Updating"));
    CallAPIPromise(`/api/master/update_Sec_Sub_Dep_Brand`, {
      table_prefix: prefix,
      ITEM_LIST_CART: updateD,
    })
      .then((res) => {
        setUpdateData({
          SECTION_NAME: "",
          DEP_NAME: "",
          BRAND_NAME: "",
          SUB_SECTION_NAME: "",
        });
        setSelectedList([]);
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Data updated successfully",
          })
        );
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };
  return (
    <div>
      <div className="grid grid-cols-[150px,1fr] ">
        <div>
          <div className="text-[16px] font-semibold">Bulk Update By</div>
        </div>

        <div className="grid grid-cols-5 gap-2">
          <GeniustextInput
            max={15}
            autofocus={true}
            onChange={(e) => {
              setSearchData((o) => {
                return {
                  ITEMNAME: e.target.value,
                  SECTION_NAME: "",
                  SUB_SECTION_NAME: "",

                  DEP_NAME: "",
                  BRAND_NAME: "",
                };
              });
            }}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter") {
                setCurrentPage(1);
                getData("", searchData.ITEMNAME);
              }
            }}
            value={searchData.ITEMNAME}
            title={"Item Name"}
            type={"text"}
            rightIcon={
              <button
                onClick={() => {
                  setCurrentPage(1);
                  getData("", searchData.ITEMNAME);
                }}
                className="bg-gray-700 h-full px-3"
              >
                <i className="bi bi-search text-white" />
              </button>
            }
            grid={true}
          />

          <GeniustextInput
            max={15}
            autofocus={true}
            onChange={(e) => {
              setSearchData((o) => {
                return {
                  SECTION_NAME: e.target.value,
                  ITEMNAME: "",
                  DEP_NAME: "",
                  SUB_SECTION_NAME: "",

                  BRAND_NAME: "",
                };
              });
            }}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter") {
                setCurrentPage(1);
                getData("", "", false, "", searchData.SECTION_NAME);
              }
            }}
            value={searchData.SECTION_NAME}
            title={"Section Name"}
            type={"text"}
            grid={true}
            rightIcon={
              <button
                onClick={() => {
                  setCurrentPage(1);
                  getData("", "", false, "", searchData.SECTION_NAME);
                }}
                className="bg-gray-700 h-full px-3"
              >
                <i className="bi bi-search text-white" />
              </button>
            }
          />

          <GeniustextInput
            max={15}
            autofocus={true}
            onChange={(e) => {
              setSearchData((o) => {
                return {
                  SUB_SECTION_NAME: e.target.value,
                  ITEMNAME: "",
                  DEP_NAME: "",
                  SECTION_NAME: "",

                  BRAND_NAME: "",
                };
              });
            }}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter") {
                setCurrentPage(1);
                getData(
                  "",
                  "",
                  false,
                  "",
                  "",
                  "",
                  "",
                  searchData.SUB_SECTION_NAME
                );
              }
            }}
            value={searchData.SUB_SECTION_NAME}
            title={"Sub-Section Name"}
            type={"text"}
            grid={true}
            rightIcon={
              <button
                onClick={() => {
                  setCurrentPage(1);
                  getData(
                    "",
                    "",
                    false,
                    "",
                    "",
                    "",
                    "",
                    searchData.SUB_SECTION_NAME
                  );
                }}
                className="bg-gray-700 h-full px-3"
              >
                <i className="bi bi-search text-white" />
              </button>
            }
          />

          <GeniustextInput
            max={15}
            autofocus={true}
            onChange={(e) => {
              setSearchData((o) => {
                return {
                  DEP_NAME: e.target.value,
                  ITEMNAME: "",
                  SECTION_NAME: "",
                  BRAND_NAME: "",
                  SUB_SECTION_NAME: "",
                };
              });
            }}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter") {
                setCurrentPage(1);
                getData("", "", false, "", "", searchData.DEP_NAME);
              }
            }}
            value={searchData.DEP_NAME}
            title={"Department Name"}
            type={"text"}
            grid={true}
            rightIcon={
              <button
                onClick={() => {
                  setCurrentPage(1);
                  getData("", "", false, "", "", searchData.DEP_NAME);
                }}
                className="bg-gray-700 h-full px-3"
              >
                <i className="bi bi-search text-white" />
              </button>
            }
          />
          <GeniustextInput
            max={15}
            autofocus={true}
            title={"Brand Name"}
            type={"text"}
            onChange={(e) => {
              setSearchData((o) => {
                return {
                  BRAND_NAME: e.target.value,
                  ITEMNAME: "",
                  SECTION_NAME: "",
                  DEP_NAME: "",
                  SUB_SECTION_NAME: "",
                };
              });
            }}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter") {
                setCurrentPage(1);
                getData("", "", false, "", "", "", searchData.BRAND_NAME);
              }
            }}
            value={searchData.BRAND_NAME}
            grid={true}
            rightIcon={
              <button
                onClick={() => {
                  setCurrentPage(1);
                  getData("", "", false, "", "", "", searchData.BRAND_NAME);
                }}
                className="bg-gray-700 h-full px-3"
              >
                <i className="bi bi-search text-white" />
              </button>
            }
          />
        </div>
      </div>

      <div className="grid grid-cols-[150px,1fr]  border-t mt-2 pt-2">
        <div>
          <div className="text-[16px] font-semibold">New Details</div>
        </div>

        <div className="grid grid-cols-5 gap-2 ">
          <GeniustextInput
            max={15}
            autofocus={true}
            onClick={() => {
              setSeactionMasterHelpStatus(true);
            }}
            value={updateData.SECTION_NAME}
            title={"New Section"}
            type={"text"}
            grid={true}
            search={() => {
              setSeactionMasterHelpStatus(true);
            }}
            rightIcon={
              updateData.SECTION_NAME && (
                <button
                  onClick={() => {
                    setUpdateData((o) => {
                      return {
                        ...o,
                        SECTION_NAME: "",
                      };
                    });
                  }}
                  className="pr-2"
                >
                  <i className=" bi bi-x-lg text-red-500" />
                </button>
              )
            }
          />
          <GeniustextInput
            max={15}
            autofocus={true}
            onClick={() => {
              setSubSeactionMasterHelpStatus(true);
            }}
            search={() => {
              setSubSeactionMasterHelpStatus(true);
            }}
            value={updateData.SUB_SECTION_NAME}
            title={"New Sub-Section "}
            type={"text"}
            grid={true}
            rightIcon={
              updateData.SUB_SECTION_NAME && (
                <button
                  onClick={() => {
                    setUpdateData((o) => {
                      return {
                        ...o,
                        SUB_SECTION_NAME: "",
                      };
                    });
                  }}
                  className="pr-2"
                >
                  <i className=" bi bi-x-lg text-red-500" />
                </button>
              )
            }
          />

          <GeniustextInput
            max={15}
            autofocus={true}
            onClick={() => {
              setDepHelpStatus(true);
            }}
            search={() => {
              setDepHelpStatus(true);
            }}
            value={updateData.DEP_NAME}
            title={"New Department"}
            type={"text"}
            grid={true}
            rightIcon={
              updateData.DEP_NAME && (
                <button
                  onClick={() => {
                    setUpdateData((o) => {
                      return {
                        ...o,
                        DEP_NAME: "",
                      };
                    });
                  }}
                  className="pr-2"
                >
                  <i className=" bi bi-x-lg text-red-500" />
                </button>
              )
            }
          />
          <GeniustextInput
            max={15}
            autofocus={true}
            title={"New Brand"}
            type={"text"}
            placeholder={"Type"}
            onChange={(e) => {
              setUpdateData((u) => {
                return {
                  ...u,
                  BRAND_NAME: e.target.value,
                };
              });
            }}
            rightIcon={
              updateData.BRAND_NAME && (
                <button
                  onClick={() => {
                    setUpdateData((o) => {
                      return {
                        ...o,
                        BRAND_NAME: "",
                      };
                    });
                  }}
                  className="pr-2"
                >
                  <i className=" bi bi-x-lg text-red-500" />
                </button>
              )
            }
            value={updateData.BRAND_NAME}
            grid={true}
          />
          <div className="flex items-center mt-3">
            <LocalButton
              onClick={() => {
                if (selectedList[0]) {
                  if (
                    updateData.SECTION_NAME ||
                    updateData.DEP_NAME ||
                    updateData.BRAND_NAME ||
                    updateData.SUB_SECTION_NAME
                  ) {
                    setAlertTwoButtonModal({
                      ...alertTwoButtonModal,
                      status: true,
                      title: "Alert",
                      button1: "Yes",
                      button2: "Cancel",
                      button1Click: () => {
                        setAlertTwoButtonModal({
                          ...alertTwoButtonModal,
                          status: false,
                        });
                        updateBulkChanges();
                      },
                      button2Click: () => {
                        setAlertTwoButtonModal({
                          ...alertTwoButtonModal,
                          status: false,
                        });
                      },
                      msg: "Are you sure you want to update bulk chnages in selected item ?",
                    });
                  } else {
                    dispatch(
                      setAlertWithTitle({
                        title: "Alert",
                        msg: "Select new details to update ",
                      })
                    );
                  }
                } else {
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: "Not founded any items to update",
                    })
                  );
                }
              }}
              bg={"orange"}
              title={"Update"}
              color={"black"}
              width={"100%  "}
            />
          </div>
        </div>
      </div>

      <div
        style={{
          height: "400px",
        }}
        className="mt-3 "
      >
        <GeniusDatagrid
          rowID={(r) => r.ICODE}
          list={itemList.filter((i) => {
            if (searchText) {
              return i.ITEMNAME.toLowerCase().includes(
                searchText.toLowerCase()
              );
            } else {
              return true;
            }
          })}
          columns={columns}
          dataGridHeader={true}
        />
      </div>
      <div className="flex justify-between  pt-2">
        <div>
          <GeniustextInput
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            placeholder={"Search"}
            value={searchText}
            hideHeader={true}
            type={"text"}
          />
        </div>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(e, p) => {
            setCurrentPage(p);
            getData();
          }}
        />
      </div>

      <SectionMasterHelp
        status={sectionMasterHelpStatus}
        onClose={() => {
          setSeactionMasterHelpStatus(false);
        }}
        onRowClick={(p) => {
          setSeactionMasterHelpStatus(false);
          setUpdateData((o) => {
            return {
              ...o,
              SECTION_NAME: p.row.SECTION_NAME,
            };
          });
        }}
      />

      <SubSecMasterHelp
        status={subSectionMasterHelpStatus}
        onClose={() => {
          setSubSeactionMasterHelpStatus(false);
        }}
        section={updateData.SECTION_NAME}
        onRowClick={(p) => {
          setSubSeactionMasterHelpStatus(false);
          setUpdateData((o) => {
            return {
              ...o,
              SUB_SECTION_NAME: p.row.SUB_SEC_NAME,
            };
          });
        }}
      />
      <DepartmentMasterHelp
        status={dephelpStatus}
        onClose={() => {
          setDepHelpStatus(false);
        }}
        section={updateData.SECTION_NAME}
        onRowClick={(p) => {
          setDepHelpStatus(false);
          setUpdateData((o) => {
            return {
              ...o,
              DEP_NAME: p.row.DEP_NAME,
            };
          });
        }}
      />
      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
    </div>
  );
}

export default BulkItemMaster;
