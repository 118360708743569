import React, { useEffect, useState } from "react";
import { toProperCase } from "../../utility/component/format";
import { useSelector } from "react-redux";
import { selectPrefix } from "../../utility/Redux/profile";
const ItemBoxCross = ({ box, onClick, onDrop }) => {
  const prefix = useSelector(selectPrefix);
  const billCartList = [];
  const isinBillCart = billCartList.filter((o) => o.ICODE === box.ICODE);
  const item = box;
  const [img, setImg] = useState("");
  useEffect(() => {
    if (box.IMAGE_1) {
      setImg(box.IMAGE_1);
    } else {
      setImg(
        `https://firebasestorage.googleapis.com/v0/b/retailx-126e1.appspot.com/o/retailx%2Fitems%2F${prefix.replace(
          /_.+_/,
          "_"
        )}${
          box.ICODE
        }?alt=media&token=${"49cc9f81-1858-4029-a612-67d2c918e7ff"}`
      );
    }
  }, [box]);
  return (
    <div
      style={{
        minWidth: "180px",
      }}
      onDrop={(e) => {
        onDrop(e).then((i) => {
          setImg(i);
        });
      }}
      className="border mx-2 hover:cursor-pointer rounded-sm "
    >
      <div
        onClick={onClick}
        className="w-full   flex items-center justify-center "
      >
        <div
          style={{
            width: "100%",
            height: "150px",
          }}
          className="flex justify-center items-center"
        >
          {" "}
          {img && (
            <img
              src={img}
              style={{
                width: "100%",
                height: "150px",
              }}
              className=" max-h-full rounded-t-sm"
              onError={() => {
                setImg("");
              }}
            />
          )}
        </div>
      </div>
      <div className="p-2">
        <div
          onClick={onClick}
          className="text-[13px] h-[35px] overflow-hidden font-semibold"
        >
          {toProperCase(box.ITEMNAME)}
        </div>
        <div
          onClick={onClick}
          className="text-gray-500 text-[12px] h-[30px] py-2"
        >
          {box.PACKING && box.PACKING} {box.PACKING && toProperCase(box.UNIT)}
        </div>
        <div className="flex justify-between">
          <div className="">
            <div className="text-[12px] font-semibold">${box.SALE_PRICE}</div>
            {box.MRP_RATE < box.SALE_PRICE && (
              <div className="text-[12px] text-gray-400 font-semibold line-through">
                ${box.MRP_RATE}
              </div>
            )}
          </div>
          <button>Add</button>
        </div>
      </div>
    </div>
  );
};

export default ItemBoxCross;
