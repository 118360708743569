import React, { useRef, useState } from "react";
import ModalHeaderStyle from "../utility/component/styles/modalHeader";
import { useNavigate } from "react-router-dom";
import ProfilePicFrame from "../utility/component/styles/profilePicFrame";
import { UploadFile } from "../utility/functions/firebase";
import { useDispatch } from "react-redux";
import { setAlertWithTitle, setSpinnerLoading } from "../utility/Redux/modal";

const AppSettings = () => {
  const navigate = useNavigate();
  const [firebaseImage, setFirebaseUrl] = useState(
    `https://firebasestorage.googleapis.com/v0/b/retailx-126e1.appspot.com/o/retailx%2Fapp%2Fblack-icon?alt=media&token=3c355e16-e7bd-449a-a729-b39ac1dacb1e`
  );
  const [selectedFile, setSelectedFile] = useState("");
  const [webcamStatus, setWebcamStatus] = useState("");
  const [capturedImage, setCapturedImage] = useState("");
  const [Path, setpath] = useState("retailx/app/black-icon");
  const webcamRef = useRef();

  const [firebaseImage2, setFirebaseUrl2] = useState(
    `https://firebasestorage.googleapis.com/v0/b/retailx-126e1.appspot.com/o/retailx%2Fapp%2Forange-icon?alt=media&token=3c355e16-e7bd-449a-a729-b39ac1dacb1e`
  );
  const [selectedFile2, setSelectedFile2] = useState("");
  const [webcamStatus2, setWebcamStatus2] = useState("");
  const [capturedImage2, setCapturedImage2] = useState("");
  const [Path2, setpath2] = useState("retailx/app/orange-icon");
  const webcamRef2 = useRef();

  const [firebaseImage3, setFirebaseUrl3] = useState(
    `https://firebasestorage.googleapis.com/v0/b/retailx-126e1.appspot.com/o/retailx%2Fapp%2Fwhite-icon?alt=media&token=3c355e16-e7bd-449a-a729-b39ac1dacb1e`
  );
  const [selectedFile3, setSelectedFile3] = useState("");
  const [webcamStatus3, setWebcamStatus3] = useState("");
  const [capturedImage3, setCapturedImage3] = useState("");
  const [Path3, setpath3] = useState("retailx/app/white-icon");
  const webcamRef3 = useRef();

  const [firebaseImage4, setFirebaseUrl4] = useState(
    `https://firebasestorage.googleapis.com/v0/b/retailx-126e1.appspot.com/o/retailx%2Fapp%2Fblack-text?alt=media&token=3c355e16-e7bd-449a-a729-b39ac1dacb1e`
  );
  const [selectedFile4, setSelectedFile4] = useState("");
  const [webcamStatus4, setWebcamStatus4] = useState("");
  const [capturedImage4, setCapturedImage4] = useState("");
  const [Path4, setpath4] = useState("retailx/app/black-text");
  const webcamRef4 = useRef();

  const [firebaseImage5, setFirebaseUrl5] = useState(
    `https://firebasestorage.googleapis.com/v0/b/retailx-126e1.appspot.com/o/retailx%2Fapp%2Forange-text?alt=media&token=3c355e16-e7bd-449a-a729-b39ac1dacb1e`
  );

  const [firebaseImage6, setFirebaseUrl6] = useState(
    `https://firebasestorage.googleapis.com/v0/b/retailx-126e1.appspot.com/o/retailx%2Fapp%2Fwhite-text?alt=media&token=3c355e16-e7bd-449a-a729-b39ac1dacb1e`
  );
  const [selectedFile6, setSelectedFile6] = useState("");
  const [webcamStatus6, setWebcamStatus6] = useState("");
  const [capturedImage6, setCapturedImage6] = useState("");
  const [Path6, setpath6] = useState("retailx/app/white-text");
  const webcamRef6 = useRef();

  const [selectedFile5, setSelectedFile5] = useState("");
  const [webcamStatus5, setWebcamStatus5] = useState("");
  const [capturedImage5, setCapturedImage5] = useState("");
  const [Path5, setpath5] = useState("retailx/app/orange-text");
  const webcamRef5 = useRef();
  const dispatch = useDispatch();
  return (
    <div className="w-full  flex justify-center bg-[#f5f5f7] h-full py-3 ">
      <div className="text-sm min-w-[340px]  rounded-sm   flex flex-col   h-full px-3 ">
        <div>
          <ModalHeaderStyle
            header={"App Settings"}
            onClose={() => {
              navigate("/settings");
            }}
          />
        </div>
        <div className="flex justify-center pt-3 bg-white">
          First delete old icon and then upload new icon and update.
        </div>
        <div className="grid grid-cols-1 p-3 t:grid-cols-3 gap-2 min-h-[600px] rounded-b-sm  bg-white border-brown px-4 max-sm:px-2">
          <div className="flex justify-center">
            <div
              style={{
                border: "1px solid #b5b3b3",
                width: "180px",
                height: "180px",
              }}
              className=" p-2 rounded-sm"
            >
              <ProfilePicFrame
                customerFirebasePic={firebaseImage}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                webcamStatus={webcamStatus}
                setWebcamStatus={setWebcamStatus}
                capturedImage={capturedImage}
                setCapturedImage={setCapturedImage}
                webcamRef={webcamRef}
                path={Path}
                setFirebaseUrl={setFirebaseUrl}
              />
              <div className="text-[20px] font-semibold mt-2 text-center">
                Black App Icon
              </div>
              <button
                onClick={() => {
                  if (selectedFile) {
                    dispatch(setSpinnerLoading("Uploading..."));
                    UploadFile(selectedFile, "retailx/app/", "black-icon")
                      .then((e) => {
                        dispatch(setSpinnerLoading(false));
                        dispatch(
                          setAlertWithTitle({
                            title: "success",
                            msg: "App Icon Uploaded Successfully",
                          })
                        );
                      })
                      .finally(() => {
                        dispatch(setSpinnerLoading(false));
                      });
                  } else {
                    dispatch(
                      setAlertWithTitle({
                        title: "Alert",
                        msg: "No Image Found",
                      })
                    );
                  }
                }}
                className="bg-black h-fit w-full mt-3 p-2 text-white rounded-sm"
              >
                Update App Icon
              </button>
              <div
                style={{
                  border: "1px solid #b5b3b3",
                  width: "180px",
                  height: "180px",
                }}
                className=" p-2 mt-3 rounded-sm"
              >
                <ProfilePicFrame
                  customerFirebasePic={firebaseImage4}
                  selectedFile={selectedFile4}
                  setSelectedFile={setSelectedFile4}
                  webcamStatus={webcamStatus4}
                  setWebcamStatus={setWebcamStatus4}
                  capturedImage={capturedImage4}
                  setCapturedImage={setCapturedImage4}
                  webcamRef={webcamRef4}
                  path={Path4}
                  setFirebaseUrl={setFirebaseUrl4}
                />
                <div className="text-[20px] font-semibold mt-2 text-center">
                  Black App Text
                </div>
                <button
                  onClick={() => {
                    if (selectedFile4) {
                      dispatch(setSpinnerLoading("Uploading..."));
                      UploadFile(selectedFile4, "retailx/app/", "black-text")
                        .then((e) => {
                          dispatch(setSpinnerLoading(false));
                          dispatch(
                            setAlertWithTitle({
                              title: "success",
                              msg: "App Icon Uploaded Successfully",
                            })
                          );
                        })
                        .finally(() => {
                          dispatch(setSpinnerLoading(false));
                        });
                    } else {
                      dispatch(
                        setAlertWithTitle({
                          title: "Alert",
                          msg: "No Image Found",
                        })
                      );
                    }
                  }}
                  className="bg-black h-fit w-full mt-3 p-2 text-white rounded-sm"
                >
                  Update App Text
                </button>
              </div>
            </div>
          </div>

          <div className="flex justify-center">
            <div
              style={{
                border: "1px solid #b5b3b3",
                width: "180px",
                height: "180px",
              }}
              className=" p-2 rounded-sm"
            >
              <ProfilePicFrame
                customerFirebasePic={firebaseImage2}
                selectedFile={selectedFile2}
                setSelectedFile={setSelectedFile2}
                webcamStatus={webcamStatus2}
                setWebcamStatus={setWebcamStatus2}
                capturedImage={capturedImage2}
                setCapturedImage={setCapturedImage2}
                webcamRef={webcamRef2}
                path={Path2}
                setFirebaseUrl={setFirebaseUrl2}
              />
              <div className="text-[20px] font-semibold mt-2 text-center">
                Orange App Icon
              </div>
              <button
                onClick={() => {
                  if (selectedFile2) {
                    dispatch(setSpinnerLoading("Uploading..."));
                    UploadFile(selectedFile2, "retailx/app/", "orange-icon")
                      .then((e) => {
                        dispatch(setSpinnerLoading(false));
                        dispatch(
                          setAlertWithTitle({
                            title: "success",
                            msg: "App Icon Uploaded Successfully",
                          })
                        );
                      })
                      .finally(() => {
                        dispatch(setSpinnerLoading(false));
                      });
                  } else {
                    dispatch(
                      setAlertWithTitle({
                        title: "Alert",
                        msg: "No Image Found",
                      })
                    );
                  }
                }}
                className="bg-orange h-fit w-full mt-3 p-2 text-black rounded-sm"
              >
                Update App Icon
              </button>
              <div
                style={{
                  border: "1px solid #b5b3b3",
                  width: "180px",
                  height: "180px",
                }}
                className=" p-2 mt-3 rounded-sm"
              >
                <ProfilePicFrame
                  customerFirebasePic={firebaseImage5}
                  selectedFile={selectedFile5}
                  setSelectedFile={setSelectedFile5}
                  webcamStatus={webcamStatus5}
                  setWebcamStatus={setWebcamStatus5}
                  capturedImage={capturedImage5}
                  setCapturedImage={setCapturedImage5}
                  webcamRef={webcamRef5}
                  path={Path5}
                  setFirebaseUrl={setFirebaseUrl5}
                />
                <div className="text-[20px] font-semibold mt-2 text-center">
                  Orange App Text
                </div>
                <button
                  onClick={() => {
                    if (selectedFile5) {
                      dispatch(setSpinnerLoading("Uploading..."));
                      UploadFile(selectedFile5, "retailx/app/", "orange-text")
                        .then((e) => {
                          dispatch(setSpinnerLoading(false));
                          dispatch(
                            setAlertWithTitle({
                              title: "success",
                              msg: "App Icon Uploaded Successfully",
                            })
                          );
                        })
                        .finally(() => {
                          dispatch(setSpinnerLoading(false));
                        });
                    } else {
                      dispatch(
                        setAlertWithTitle({
                          title: "Alert",
                          msg: "No Image Found",
                        })
                      );
                    }
                  }}
                  className="bg-orange h-fit w-full mt-3 p-2  rounded-sm"
                >
                  Update App Text
                </button>
              </div>
            </div>
          </div>

          <div className="flex justify-center">
            <div
              style={{
                border: "1px solid #b5b3b3",
                width: "180px",
                height: "180px",
                backgroundColor: "gray",
              }}
              className=" p-2 rounded-sm"
            >
              <ProfilePicFrame
                customerFirebasePic={firebaseImage3}
                selectedFile={selectedFile3}
                setSelectedFile={setSelectedFile3}
                webcamStatus={webcamStatus3}
                setWebcamStatus={setWebcamStatus3}
                capturedImage={capturedImage3}
                setCapturedImage={setCapturedImage3}
                webcamRef={webcamRef3}
                path={Path3}
                setFirebaseUrl={setFirebaseUrl3}
              />
              <div className="text-[20px] font-semibold mt-2 text-center">
                White App Icon
              </div>
              <button
                onClick={() => {
                  if (selectedFile3) {
                    dispatch(setSpinnerLoading("Uploading..."));
                    UploadFile(selectedFile3, "retailx/app/", "white-icon")
                      .then((e) => {
                        dispatch(setSpinnerLoading(false));
                        dispatch(
                          setAlertWithTitle({
                            title: "success",
                            msg: "App Icon Uploaded Successfully",
                          })
                        );
                      })
                      .finally(() => {
                        dispatch(setSpinnerLoading(false));
                      });
                  } else {
                    dispatch(
                      setAlertWithTitle({
                        title: "Alert",
                        msg: "No Image Found",
                      })
                    );
                  }
                }}
                className="bg-gray-200  h-fit w-full mt-3 p-2 text-black rounded-sm"
              >
                Update App Icon
              </button>

              <div
                style={{
                  border: "1px solid #b5b3b3",
                  width: "180px",
                  height: "180px",
                  backgroundColor: "gray",
                }}
                className=" p-2 mt-3 rounded-sm"
              >
                <ProfilePicFrame
                  customerFirebasePic={firebaseImage6}
                  selectedFile={selectedFile6}
                  setSelectedFile={setSelectedFile6}
                  webcamStatus={webcamStatus6}
                  setWebcamStatus={setWebcamStatus6}
                  capturedImage={capturedImage6}
                  setCapturedImage={setCapturedImage6}
                  webcamRef={webcamRef6}
                  path={Path6}
                  setFirebaseUrl={setFirebaseUrl6}
                />
                <div className="text-[20px] font-semibold mt-2 text-center">
                  White App Text
                </div>
                <button
                  onClick={() => {
                    if (selectedFile6) {
                      dispatch(setSpinnerLoading("Uploading..."));
                      UploadFile(selectedFile6, "retailx/app/", "white-text")
                        .then((e) => {
                          dispatch(setSpinnerLoading(false));
                          dispatch(
                            setAlertWithTitle({
                              title: "success",
                              msg: "App Icon Uploaded Successfully",
                            })
                          );
                        })
                        .finally(() => {
                          dispatch(setSpinnerLoading(false));
                        });
                    } else {
                      dispatch(
                        setAlertWithTitle({
                          title: "Alert",
                          msg: "No Image Found",
                        })
                      );
                    }
                  }}
                  className="bg-gray-200  h-fit w-full mt-3 p-2 text-black rounded-sm"
                >
                  Update App Text
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppSettings;
