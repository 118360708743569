// components/PricingTable.js
import React from "react";
const featursList = [
  "Billing (1 User)",
  "All Sale Reports",
  "SMS 100",
  "WhatsApp 100",
  "12/6 Support",
  "Purchase (1 User)",
  "Stock Management",
  "Manager Dashboard",
  "All Purchase Reports",
  "RetailxCross (Online Ordering Solution)",
];
const plans = [
  {
    name: "Standard",
    price: 8500,
    currency: "INR",
    desc: "+ Taxes Yearly",
    billing_period: "Yearly",
    color: "#fd7f19",
    features: [
      "Billing (1 User)",
      "All Sale Reports",
      "SMS 100",
      "WhatsApp 100",
      "12/6 Support",
      "Purchase (1 User)",
    ],
  },
  {
    name: "Business",
    price: "14,500",
    currency: "INR",
    billing_period: "Yearly",
    color: "#feae34",
    desc: " + Taxes Yearly with Stock & Purchase",

    features: [
      "Billing (1 User)",
      "All Sale Reports",
      "SMS 100",
      "WhatsApp 100",
      "12/6 Support",
      "Purchase (1 User)",
      "Stock Management",
      "Manager Dashboard",
      "All Purchase Reports",
    ],
  },
  {
    name: "Enterprise",
    price: "17,500",
    currency: "INR",
    billing_period: "Yearly",
    color: "#33a9fe",
    desc: " + Taxes Yearly Integration with Home Delivery App",

    features: [
      "Billing (1 User)",
      "All Sale Reports",
      "SMS 100",
      "WhatsApp 100",
      "12/6 Support",
      "Purchase (1 User)",
      "Stock Management",
      "Manager Dashboard",
      "All Purchase Reports",
      "RetailxCross (Online Ordering Solution)",
    ],
  },
];

const PricingTable = () => {
  return (
    <div className="flex flex-col items-center pt-20 container  mx-auto">
      <h2 className="text-3xl font-bold text-gray-800 mb-8">
        Pricing for Retailx
      </h2>
      <div className="grid grid-cols-1 sl:grid-cols-3 gap-[30px] ms:w-full sl:w-fit">
        {plans.map((o, key) => {
          return (
            <div
              key={key}
              style={{
                border: "1px solid #EAEAEA",
                borderStyle: "dashed",
                boxShadow: "0px 0px 12px 0px rgba(0,0,0,0.1)",
              }}
              className="border rounded-[16px]   min-w-full bg-white  pt-6 "
            >
              <div
                style={{
                  backgroundColor: o.color,
                  mixBlendMode: "darken",
                  padding: "5px 25px 5px 20px",
                }}
                className="flex  min-h-[81px] justify-between pr-6 items-start  mr-4 rounded-r-[56px]"
              >
                <div className=" text-white">
                  <div className=" text-[30px] font-[500] font-Proxima  ">
                    ₹ {o.price}/
                  </div>
                  <div className="text-[12px]">{o.desc}</div>
                </div>
                <div className=" text-[16px]  pt-2 uppercase text-white ">
                  {o.name}
                </div>
              </div>
              <div className="pb-6">
                <ul className="mt-4 text-sm font-semibold text-gray-600 ">
                  {featursList.map((f, key) => {
                    const isFeatureAvailvalForPlan = o.features.filter(
                      (PlacnF) => PlacnF === f
                    );
                    return (
                      <li key={key} className=" px-4 py-3 flex items-center">
                        {isFeatureAvailvalForPlan[0] ? (
                          <div className=" pr-4 text-[#fd7f19]">✓ </div>
                        ) : (
                          <div className="text-black  pr-4  ">✗</div>
                        )}{" "}
                        <div
                          className={`text-[14px] font-normal ${
                            isFeatureAvailvalForPlan[0] ? "" : "line-through"
                          } text-black`}
                        >
                          {f}
                        </div>
                      </li>
                    );
                  })}
                </ul>
                <div className="px-4">
                  <button
                    style={{
                      backgroundColor: o.color,
                    }}
                    className=" mt-[28px] w-full bg-[#fd7f19] hover:bg-[#ff9e3d] text-white py-3 rounded-[40px] font-semibold transition-colors"
                  >
                    Buy Now
                  </button>
                </div>
              </div>
            </div>
          );
        })}
        {/* Basic Plan */}

        {/* Standard Plan */}
      </div>
    </div>
  );
};

export default PricingTable;
