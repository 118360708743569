import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Logo from "./logo";
import { useNavigate } from "react-router-dom";

export default function Navbar({ isShowShadow }) {
  const [hasScrolled, setHasScrolled] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const handleScroll = () => {
      setHasScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <header
      style={{ zIndex: 100 }}
      className={`fixed left-0 right-0 py-4 flex items-center justify-between transition-shadow ${
        hasScrolled || isShowShadow ? "shadow-md bg-[#f9fafb]" : ""
      }`}
    >
      <div className="flex w-full justify-between  mx-auto px-4 items-center">
        <div
          onClick={() => {
            window.open("https://login.retailxapp.com/auth/login");
          }}
        >
          <Logo />
        </div>
        <div className="flex">
          <div
            style={{
              border: `1px solid ${
                hasScrolled || isShowShadow ? "#feae34" : "white"
              }`,
            }}
            className={`p-3 ms:hidden t:flex ${
              hasScrolled || isShowShadow ? "bg-white" : "bg-orange"
            }  rounded-full`}
          >
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              style={{
                border: "1px solid #FFA800",
              }}
              className={`${
                hasScrolled || isShowShadow
                  ? "  bg-orange text-whit hover:text-[#FFA800] e"
                  : "md:text-black md:border-white  bg-white  hover:text-black"
              }  w-[160px] py-2  rounded-r-[70px] rounded-l-[70px] hover:bg-white  `}
              onClick={() =>
                window.open("https://login.retailxapp.com/auth/login")
              }
            >
              Login Retailx
            </motion.button>
          </div>
        </div>

        <button
          onClick={() => window.open("https://login.retailxapp.com/auth/login")}
          className="text-white   bg-orange py-2 px-4 rounded-[70px] text-[16px] t:hidden"
        >
          Login
        </button>
      </div>
    </header>
  );
}
