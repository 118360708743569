import React from "react";
import {
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaPhone,
  FaEnvelope,
  FaMapMarkerAlt,
  FaInstagram,
} from "react-icons/fa"; // Social & Contact Icons
import GeniusFooterLogo from "../utility/images/GOROUND2.png";
import { useNavigate } from "react-router-dom";

const linkClasses =
  "text-gray-400 hover:text-gray-200 text-sm transition-colors duration-200";

const MainFooter = () => {
  const router = useNavigate();
  return (
    <footer className="bg-[#0d1b2a] p-4 md:p-8">
      {/* Top Section */}
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Useful Links */}
        <div>
          <h3 className="text-lg font-semibold text-gray-300 mb-4">
            Useful Links
          </h3>
          <div className="space-y-2">
            {/* <div>
              <button
                onClick={() => {
                  router("/about_us");
                }}
                className={linkClasses}
              >
                About Us
              </button>
            </div> */}
            <div>
              <button
                onClick={() => {
                  router("/auth/mobile_signup");
                }}
                className={linkClasses}
              >
                Create Account
              </button>
            </div>
            <div>
              <button
                onClick={() => {
                  router("/terms_conditions");
                }}
                className={linkClasses}
              >
                Terms & Conditions
              </button>
            </div>
          </div>
        </div>

        {/* Contact Details */}
        <div>
          <h3 className="text-lg font-semibold text-gray-300 mb-4">
            Contact Us
          </h3>
          <div className="space-y-3">
            <div className="flex items-center">
              <FaMapMarkerAlt className="text-gray-400 mr-2" />
              <span className="text-gray-400 text-sm">
                22 Kalgidhar Avenue, Jalandhar, Punjab 144022
              </span>
            </div>
            <li className="flex items-center">
              <FaPhone className="text-gray-400 mr-2 text-sm" />
              <span className="text-gray-400">+91 85589 51962</span>
            </li>
            <li className="flex items-center">
              <FaEnvelope className="text-gray-400 mr-2 text-sm" />
              <span className="text-gray-400">support@geniusoffice.com</span>
            </li>
          </div>
        </div>

        {/* Social Media Links */}
        <div>
          <h3 className="text-lg font-semibold text-gray-300 mb-4">
            Follow Us
          </h3>
          <div className="flex space-x-4">
            <a
              href="https://www.facebook.com/geniusoffice"
              target="_blank"
              className="text-gray-400 hover:text-blue-500"
            >
              <FaFacebook size={20} />
            </a>

            <a
              href="https://www.instagram.com/geniusoffice/"
              target="_blank"
              className="text-gray-400 hover:text-[#ff1355]"
            >
              <FaInstagram size={20} />
            </a>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="mt-6 border-t border-gray-700 pt-4">
        <div
          onClick={() => {
            window.open("https://www.geniusoffice.com/contact");
          }}
          className="flex flex-col items-center justify-center text-sm text-gray-400 sm:flex-row"
        >
          <div className="flex items-center">
            <span>A product of</span>

            <img
              src={GeniusFooterLogo}
              alt="Genius Footer Logo"
              width={16}
              height={16}
              className="mx-2  sm:my-0"
            />
            <span className="font-medium">genius office</span>
          </div>

          <span className="mt-1 sm:mt-0 sm:ml-1">
            &copy; 2024. All rights reserved.
          </span>
        </div>
      </div>
    </footer>
  );
};

export default MainFooter;
