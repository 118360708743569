import React, { useState } from "react";
import GeniustextInput from "../../utility/component/mastertext";
import { useDispatch, useSelector } from "react-redux";
import { selectPrefix, selectWidth } from "../../utility/Redux/profile";
import { FormatNumber2 } from "../../utility/functions/formatNumber";
import { CallAPIPromise } from "../../billing/common";
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from "../../utility/Redux/modal";
import { selectToken } from "../../utility/Redux/security";
import GeniusDatagrid from "../../utility/component/datagrid/geniusDatagrid";
import {
  DepartmentMasterHelp,
  SectionMasterHelp,
  SubSecMasterHelp,
} from "../../help";
import { LocalButton } from "../../utility/component/buttons";
import AlertModalTwoButton from "../../utility/component/modals/alertWithTwoButton";
import { Pagination } from "@mui/material";
import ItemBox2 from "./itemBox";
import ItemBoxCross from "./crossItemBox";
import { Close } from "@mui/icons-material";
import { updateItemImages } from "./func";
import { useDropzone } from "react-dropzone";
import { image } from "@nextui-org/react";

function UpdateItemImages() {
  const [itemList, setItemList] = useState([]);
  const [savedImagesList, setSavedImagesList] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [sectionMasterHelpStatus, setSeactionMasterHelpStatus] =
    useState(false);
  const [imageTags, setImageTags] = useState("");
  const [searchText, setSearchText] = useState("");
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: "",
    msg: "",
    status: false,
    setStatus: "",
    button1: "",
    button1Click: "",
    button2: "",
    button2Click: "",
  });

  const [searchData, setSearchData] = useState({
    ITEMNAME: "",
    SECTION_NAME: "",
    DEP_NAME: "",
    BRAND_NAME: "",
    SUB_SECTION_NAME: "",
  });
  const [updateData, setUpdateData] = useState({
    SECTION_NAME: "",
    DEP_NAME: "",
    BRAND_NAME: "",
    SUB_SECTION_NAME: "",
  });
  const [totalPages, settotalPages] = useState(1);
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const [selectedList, setSelectedList] = useState([]);
  const handleSelect = (item) => {
    // Check if the item is already selected
    const isSelected = selectedList.some(
      (selectedItem) => selectedItem.ICODE === item.ICODE
    );

    if (isSelected) {
      // Remove item from selected list
      setSelectedList(
        selectedList.filter((selectedItem) => selectedItem.ICODE !== item.ICODE)
      );
    } else {
      // Add item to selected list
      setSelectedList([...selectedList, item]);
    }
  };

  const getData = async (
    page,
    item,
    hideloaing,
    ICODE,
    section,
    dep,
    brand,
    subSec
  ) => {
    setSearchText("");
    if (!hideloaing) {
      dispatch(setSpinnerLoading("Loading"));
    }
    setSelectedList([]);
    const data = {
      table_prefix: prefix,
      SECTION_NAME: section ? section : searchData.SECTION_NAME,
      SUB_SEC_NAME: subSec ? subSec : "",
      ICODE: ICODE ? ICODE : "",
      ALIAS_CODE: "",
      ITEMNAME: item ? item : searchData.ITEMNAME,
      BRAND_NAME: brand ? brand : searchData.BRAND_NAME,
      DEP_NAME: dep ? dep : searchData.BRAND_NAME,
      page: page ? page : currentPage,
      limit: 100,
    };
    CallAPIPromise("/api/master/listItemMaster", data, token, dispatch)
      .then((p) => {
        if (!p.data.error) {
          settotalPages(p.data.response.totalPages);

          setItemList(p.data.response.data);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };
  const updateBulkChanges = () => {
    const updateD = selectedList.map((i) => {
      return {
        ICODE: i.ICODE,
        SECTION_NAME: updateData.SECTION_NAME,
        SUB_SEC_NAME: updateData.SUB_SECTION_NAME,
        BRAND_NAME: updateData.BRAND_NAME,
        DEP_NAME: updateData.DEP_NAME,
      };
    });
    dispatch(setSpinnerLoading("Updating"));
    CallAPIPromise(`/api/master/update_Sec_Sub_Dep_Brand`, {
      table_prefix: prefix,
      ITEM_LIST_CART: updateD,
    })
      .then((res) => {
        setUpdateData({
          SECTION_NAME: "",
          DEP_NAME: "",
          BRAND_NAME: "",
          SUB_SECTION_NAME: "",
        });
        setSelectedList([]);
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Data updated successfully",
          })
        );
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };
  const onDrop = (acceptedFiles) => {};
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
      "image/avif": [".avif"], // ✅ Corrected from "avf" to "avif"
    },
    multiple: true,
  });
  return (
    <div className="h-full ">
      <div className="h-full">
        <div className="grid grid-cols-2 h-full gap-2">
          <div className="grid grid-cols-1 gap-2 border-r pr-3 h-full">
            <GeniustextInput
              max={15}
              autofocus={true}
              onKeyDown={(e) => {
                if (e.code === "Enter" || e.code === "NumpadEnter") {
                  setCurrentPage(1);
                  setSeactionMasterHelpStatus(true);
                }
              }}
              onClick={() => {
                setSeactionMasterHelpStatus(true);
              }}
              value={searchData.SECTION_NAME}
              title={"Section Name"}
              type={"text"}
              grid={true}
              rightIcon={
                <button
                  onClick={() => {
                    setCurrentPage(1);
                    setSeactionMasterHelpStatus(true);
                  }}
                  className="bg-gray-700 h-full px-3"
                >
                  <i className="bi bi-search text-white" />
                </button>
              }
            />
            <div
              style={{
                height: `calc(100% - 10px)`,
              }}
              className="mt-3  flex flex-col justify-between "
            >
              <div
                style={{
                  height: `500px`,
                }}
                className="grid ll:grid-cols-3 grid-cols-2 overflow-y-auto gap-2"
              >
                {itemList
                  .filter((i) => {
                    if (searchText) {
                      return i.ITEMNAME.toLowerCase().includes(
                        searchText.toLowerCase()
                      );
                    } else {
                      return true;
                    }
                  })
                  .map((item) => {
                    return (
                      <ItemBoxCross
                        onDrop={async (e) => {
                          return new Promise((resolve, reject) => {
                            const imageUrl = e.dataTransfer.getData("imageUrl");
                            dispatch(setSpinnerLoading("Uploading"));
                            console.log(imageUrl);

                            updateItemImages(
                              [imageUrl],
                              prefix,
                              item.ICODE,
                              token,
                              dispatch
                            )
                              .then(() => {
                                resolve(imageUrl);
                              })
                              .finally(() => {
                                dispatch(setSpinnerLoading(false));
                              });
                          });
                        }}
                        box={item}
                      />
                    );
                  })}
              </div>
              <div className="mt-3">
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={(e, p) => {
                    setCurrentPage(p);
                    getData();
                  }}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              height: `500px`,
            }}
          >
            <GeniustextInput
              max={50}
              autofocus={true}
              value={imageTags}
              title={"Image Tag"}
              type={"text"}
              grid={true}
              onKeyDown={(e) => {
                if (e.code === "Enter" || e.code === "NumpadEnter") {
                  dispatch(setSpinnerLoading("Searching"));
                  CallAPIPromise(
                    "/api/images/listImages",
                    {
                      image_tag: imageTags,
                    },
                    token,
                    dispatch
                  )
                    .then((res) => {
                      if (res?.data?.images) {
                        setSavedImagesList(res.data.images);
                      }
                    })
                    .finally(() => {
                      dispatch(setSpinnerLoading(false));
                    });
                }
              }}
              onChange={(e) => {
                setImageTags(e.target.value);
              }}
              rightIcon={
                <button
                  onClick={() => {
                    dispatch(setSpinnerLoading("Searching"));
                    CallAPIPromise(
                      "/api/images/listImages",
                      {
                        image_tag: imageTags,
                      },
                      token,
                      dispatch
                    )
                      .then((res) => {
                        if (res?.data?.images) {
                          setSavedImagesList(res.data.images);
                        }
                      })
                      .finally(() => {
                        dispatch(setSpinnerLoading(false));
                      });
                  }}
                  className="bg-gray-700 h-full px-3"
                >
                  <i className="bi bi-search text-white" />
                </button>
              }
            />
            <div
              {...getRootProps()}
              className={`  h-[1px] text-center ${
                isDragActive ? "bg-blue-100" : "bg-gray-100"
              }`}
              // onDrop={async (e) => {
              //   // Get the image URL from the dataTransfer object
              //   console.log(e);
              //   const imageUrl = e.dataTransfer.getData("imageUrl");
              //   if (imageUrl) {
              //     e.preventDefault();

              //     const IMAGE_ID = e.dataTransfer.getData("IMAGE_ID");

              //   }
              // }}
            >
              <input {...getInputProps()} />
            </div>
            {savedImagesList.length > 0 && (
              <div className="ml-3  h-full grid grid-cols-2 ll:grid-cols-3 overflow-hidden  overflow-y-auto">
                {savedImagesList.map((i) => {
                  return (
                    <div
                      style={{
                        height: 200,
                        width: 200,
                        border: "1px dashed gray",
                        position: "relative",
                      }}
                      className="flex justify-center items-center"
                    >
                      <div className="p-2">
                        <img
                          style={{
                            width: 160,
                            height: 160,
                          }}
                          crossOrigin="anonymous"
                          onClick={(e) => {}}
                          onDragStart={(e) => {
                            e.dataTransfer.effectAllowed = "copyMove";
                            e.dataTransfer.setData("imageUrl", e.target.src);
                          }}
                          className="overflow-hidden"
                          src={`https://firebasestorage.googleapis.com/v0/b/retailx-126e1.appspot.com/o/retailx%2Fimage_library%2F${i.IMAGE_ID}?alt=media&token=f7547088-289b-4819-a479-2304e392be86`}
                        />
                        <div className="text-center">{i.IMAGE_ID}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>

      <SectionMasterHelp
        status={sectionMasterHelpStatus}
        onClose={() => {
          setSeactionMasterHelpStatus(false);
        }}
        onRowClick={(p) => {
          setSeactionMasterHelpStatus(false);
          getData("", "", false, "", p.row.SECTION_NAME);
          setSearchData((o) => {
            return {
              ...o,
              SECTION_NAME: p.row.SECTION_NAME,
            };
          });
        }}
      />

      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
    </div>
  );
}

export default UpdateItemImages;
