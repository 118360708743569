import moment from "moment";
import { FormatNumber2 } from "../../utility/functions/formatNumber";

export const SummaryColumns = (width, selectedList, handleSelect) => {
  const columns = [
    {
      INVNO: "INVNO",
      accessor: "INVNO",

      type: "string",
      headerAlign: "center",
      width: width < 768 ? 150 : width < 1400 ? 80 : 60,
      headerClassName: "super-app-theme--header",

      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          {"Invoice"}{" "}
        </div>
      ),
    },

    {
      field: "DATE",
      accessor: "DATE",
      type: "string",
      type: "text",
      headerAlign: "center",
      width: width < 768 ? 100 : 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div className={`h-full w-full flex items-center  `}>
            {moment(params.value).format("DD-MM-YYYY")}{" "}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          {"Date"}{" "}
        </div>
      ),
    },

    {
      field: "ORDER_TYPE",
      accessor: "ORDER_TYPE",
      type: "number",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      headerClassName: "super-app-theme--header",
      width: width < 768 ? 100 : 130,
      headerAlign: "center",
      align: "left",

      renderHeader: (params) => (
        <div className="text-white  whitespace-normal" style={{ fontSize: 13 }}>
          {" "}
          Order Type
        </div>
      ),
    },
    {
      field: "CUSTOMER_NAME",
      accessor: "CUSTOMER_NAME",
      type: "string",
      width: width < 768 ? 100 : width < 1400 ? 180 : 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          Customer Name
        </div>
      ),
    },
    {
      field: "CUSTOMER_MOBILE",
      accessor: "CUSTOMER_MOBILE",
      type: "string",
      width: width < 768 ? 100 : width < 1400 ? 180 : 120,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          Customer Mobile
        </div>
      ),
    },
    {
      field: "DELIVERY_ASSIGN_TO",
      accessor: "DELIVERY_ASSIGN_TO",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: width < 768 ? 100 : 90,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  `}>
            {param.value}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          Delivery Boy
        </div>
      ),
    },

    {
      field: "DELIVERY_DATE",
      accessor: "DELIVERY_DATE",
      type: "string",
      type: "text",
      headerAlign: "center",
      width: width < 768 ? 100 : 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div className={`h-full w-full flex items-center  `}>
            {moment(params.value).format("DD-MM-YYYY")}{" "}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          {"Delivery Date"}{" "}
        </div>
      ),
    },
    {
      field: "DELIVERY_TIME",
      accessor: "DELIVERY_TIME",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: width < 768 ? 100 : 130,
      align: "left",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  `}>
            {param.value}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          Deliverd Time
        </div>
      ),
    },
    {
      field: "AMOUNT",
      accessor: "AMOUNT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: width < 768 ? 100 : 90,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : ""}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          Bill Amount
        </div>
      ),
    },
    {
      field: "Select",
      accessor: "select",
      renderHeader: (params) => <div>Select</div>,
      width: 90,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const item = params.row.values;

        return (
          <div>
            <input
              type="checkbox"
              checked={selectedList.some(
                (selectedItem) => selectedItem.INVNO === item.INVNO
              )}
              onChange={() => handleSelect(item)} // Toggle selection
            />
          </div>
        );
      },
    },
  ];

  return columns;
};
