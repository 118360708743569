import ModalHeaderStyle from "../../utility/component/styles/modalHeader";
import { useNavigate } from "react-router-dom";
import DynamicBanner from "./banners";
import { useEffect, useState } from "react";
import { LocalButton } from "../../utility/component/buttons";
import {
  DownloadFile,
  UploadFile,
  UploadFileFull,
} from "../../utility/functions/firebase";
import { useDispatch } from "react-redux";
import {
  setAlertWithTitle,
  setSpinnerLoading,
} from "../../utility/Redux/modal";
import GeniustextInput from "../../utility/component/mastertext";
import CompanyHelp from "../../help/companyHelp";
import { CallAPIPromise } from "../../billing/common";

const AdminShopBanners = () => {
  const navigate = useNavigate();
  const [banners, setBanners] = useState([]);
  const [newBanner, setNewBanner] = useState("");
  const [uploading, setUploading] = useState(false);
  const [compnyHelpStatus, setCompanyHelpStatus] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [bannerType, setBannerType] = useState("retailxcross_website");
  const [companyCode, setCompanyCode] = useState("");
  const [bannerId, setBannerId] = useState("");
  const [companyPrefix, setCompanyPrefix] = useState("");
  const removeBanner = (index) => {
    setBanners(banners.filter((_, i) => i !== index));
  };
  const dispatch = useDispatch();
  const handleFileUpload = async (event) => {
    const path =
      bannerType === "retailxcross_website"
        ? "retailx/website/banner/"
        : "retailx/shop/banner/";
    const file = newBanner;
    if (!file) return;
    dispatch(setSpinnerLoading("Loading"));
    const timestamp = Date.now();
    UploadFileFull(file, path, timestamp).then(() => {
      DownloadFile(`${path}${timestamp}`)
        .then((url) => {
          if (!url.includes("FirebaseError")) {
            setBanners([
              ...banners,
              {
                name: timestamp,
                url: url,
              },
            ]);
            setNewBanner("");
          }

          setUploading(false);
        })
        .finally(() => {
          dispatch(setSpinnerLoading(false));
        });
    });
  };
  const saveImages = () => {
    if (banners[0]) {
      if (bannerType !== "retailxcross_website") {
        if (companyCode) {
          dispatch(setSpinnerLoading("Uploading"));
          CallAPIPromise("/api/pageBannerMaster/savePageBanner", {
            code: companyCode,
            comp_table_prefix: companyPrefix,
            banner_image: banners.map((item) => item.name).join(","),
            banner_for: "retailxcross_shop_page",
            banner_id: bannerId,
          })
            .then((res) => {
              dispatch(
                setAlertWithTitle({
                  title: "Success",
                  msg: "Image saved successfully",
                })
              );
            })
            .finally(() => {
              dispatch(setSpinnerLoading(false));
            });
        } else {
          dispatch(
            setAlertWithTitle({
              title: "Company",
              msg: "Please select company",
            })
          );
        }
      } else {
        dispatch(setSpinnerLoading("Uploading"));
        CallAPIPromise("/api/pageBannerMaster/savePageBanner", {
          code: "0",
          comp_table_prefix: "",
          banner_image: banners.map((item) => item.name).join(","),
          banner_for: "retailxcross_website",
          banner_id: bannerId,
        })
          .then((res) => {
            dispatch(
              setAlertWithTitle({
                title: "Success",
                msg: "Image saved successfully",
              })
            );
          })
          .finally(() => {
            dispatch(setSpinnerLoading(false));
          });
      }
    } else {
      dispatch(
        setAlertWithTitle({
          title: "No Image",
          msg: "No Any banner founded",
        })
      );
    }
  };

  useEffect(() => {
    if (bannerType === "retailxcross_website") {
      CallAPIPromise("/api/pageBannerMaster/listBanners", {
        comp_table_prefix: "",
        code: "",
      }).then((res) => {
        if (!res.data.error) {
          const firetoken = localStorage.getItem("fire");
          if (!res.data.response[0]) {
            setBanners([]);
            return;
          }
          const daata = res.data.response.filter(
            (r) => r.banner_for === "retailxcross_website"
          );
          console.log(daata);

          const images = daata[0]?.banner_image.split(",");
          setBannerId(daata[0].banner_id);
          setBanners(
            images.map((o) => {
              return {
                name: o,
                url: `https://firebasestorage.googleapis.com/v0/b/retailx-126e1.appspot.com/o/retailx%2Fwebsite%2Fbanner%2F${o}?alt=media&token=${
                  firetoken ? firetoken : "9c286088-2748-4b74-8630-56f4dfea73c3"
                }`,
              };
            })
          );
        }
      });
    }
  }, [bannerType]);
  return (
    <div className="w-full flex justify-center bg-[#f5f5f7] h-full px-3">
      <div className="text-sm rounded-sm flex flex-col w-[1024px]  h-full py-4 ">
        <div>
          <ModalHeaderStyle
            header={"Banner Image Management"}
            onClose={() => {
              navigate("/masters");
            }}
          />
        </div>
        <div className="bg-white p-4 overflow-y-scroll">
          <div>
            <GeniustextInput
              title={"Banner Type"}
              value={bannerType}
              readOnly={true}
              onChange={(e) => {
                setBannerType(e.target.value);
                setBanners([]);
              }}
              width={250}
              option={[
                {
                  name: "Cross Main Banner",
                  value: "retailxcross_website",
                  label: "Cross Main Banner",
                },
                {
                  name: "Cross Customer Banner",
                  value: "retailxcross_shop_page",
                  label: "Cross Customer Banner",
                },
              ]}
            />
          </div>
          {bannerType === "retailxcross_shop_page" && (
            <div className="mb-4">
              <GeniustextInput
                title={"Company Name"}
                value={companyName}
                readOnly={true}
                type={"text"}
                search={() => {
                  setCompanyHelpStatus(true);
                }}
              />
            </div>
          )}
          <div>Banner ID  {bannerId}</div>
          <DynamicBanner banners={banners} />
          <div className="bg-white mt-4">
            <div className="flex space-x-2">
              <input
                type="file"
                accept="image/*"
                onChange={(event) => {
                  setNewBanner(event.target.files[0]);
                }}
                className="border p-2 w-full rounded"
              />
              {uploading && <span>Uploading...</span>}
            </div>
            <div className="flex items-center">
              <img
                src={newBanner ? URL.createObjectURL(newBanner) : ""}
                className="h-[100px] "
              />
              <LocalButton
                onClick={() => {
                  handleFileUpload();
                }}
                ml={newBanner ? 20 : 0}
                title={"Add Image"}
                bg={"blue"}
              />
            </div>

            <ul className="space-y-2">
              {banners.map((banner, index) => (
                <li
                  key={index}
                  className="flex justify-between items-center p-2 bg-gray-100 rounded"
                >
                  <span className="truncate w-4/5">{banner.url}</span>
                  <button
                    onClick={() => removeBanner(index)}
                    className="text-red-500"
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>

            <div className="flex justify-end">
              <LocalButton title={"Submit"} bg={"green"} onClick={saveImages} />
            </div>
          </div>
        </div>
      </div>
      <CompanyHelp
        status={compnyHelpStatus}
        onRowClick={(p) => {
          setCompanyHelpStatus(false);
          setCompanyName(p.row.company_name);
          setCompanyPrefix(p.row.table_prefix);
          setCompanyCode(p.row.code);
          CallAPIPromise("/api/pageBannerMaster/listBanners", {
            comp_table_prefix: p.row.table_prefix,
            code: p.row.code,
          }).then((res) => {
            if (!res.data.error) {
              const firetoken = localStorage.getItem("fire");
              if (!res.data.response[0]) {
                setBanners([]);
                return;
              }
              const images = res.data.response[0]?.banner_image.split(",");
              setBannerId(res.data.response[0].banner_id);
              setBanners(
                images.map((o) => {
                  return {
                    name: o,
                    url: `https://firebasestorage.googleapis.com/v0/b/retailx-126e1.appspot.com/o/retailx%2Fshop%2Fbanner%2F${o}?alt=media&token=${
                      firetoken
                        ? firetoken
                        : "9c286088-2748-4b74-8630-56f4dfea73c3"
                    }`,
                  };
                })
              );
            }
          });
        }}
        onClose={() => {
          setCompanyHelpStatus(false);
        }}
      />
    </div>
  );
};

export default AdminShopBanners;
