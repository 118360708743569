"use client";
import img1 from "../utility/images/1.svg";
import img2 from "../utility/images/2.svg";
import img3 from "../utility/images/3.svg";
import img4 from "../utility/images/4.svg";
import img5 from "../utility/images/5.svg";
import img6 from "../utility/images/6.svg";
import img7 from "../utility/images/7.svg";
import img8 from "../utility/images/8.svg";
import img9 from "../utility/images/9.svg";
import { motion } from "framer-motion";
export default function FeatureGrid() {
  const features = [
    {
      title: "Flexible Billing",
      description: "Handle billing with or without stock management.",
      icon: "/1.svg", // Add paths to your icons here
    },
    {
      title: "Integrated Communication",
      description: "Send bills directly via WhatsApp & SMS.",
      icon: "/1.svg", // Add paths to your icons here
    },
    {
      title: "Promotions Made Easy",
      description: "Apply offers and discounts seamlessly during billing.",
      icon: "/1.svg", // Add paths to your icons here
    },
    {
      title: "Multiple Payment Options",
      description: "Manage both cash and credit transactions with ease.",
      icon: "/1.svg", // Add paths to your icons here
    },
    {
      title: "Comprehensive Reporting",
      description:
        "Generate detailed sales reports and tax reports of tax collected.",
      icon: "/1.svg", // Add paths to your icons here
    },
    {
      title: "Account Management",
      description:
        "Keep track of party balances and maintain accurate party ledgers.",
      icon: "/1.svg", // Add paths to your icons here
    },
    {
      title: "Stock Management",
      description: "Access detailed stock reports and item ledgers.",
      icon: "/1.svg", // Add paths to your icons here
    },
    {
      title: "Efficient Purchasing",
      description: "Manage purchases and refunds effortlessly.",
      icon: "/1.svg", // Add paths to your icons here
    },
    {
      title: " RetailxCross",
      description: " Your Ultimate Online Ordering Solution.",
      icon: "/1.svg", // Add paths to your icons here
      path: "https://www.retailxcross.com/",
    },
  ];

  return (
    <motion.section
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.4, delay: 0.5 }}
      className="px-[20px] pt-[60px] d:pt-0   mx-auto"
    >
      <h2 className="text-[36px]  font-[800] text-black text-center mb-[10px]">
        What is Retailx
      </h2>
      <p className="text-center text-[#666666] text-[16px] mb-[40px] md:px-20 ms:px-0 mx-auto leading-[1.5]">
        Retailx is a powerful WebApp designed to streamline Retail Billing and
        Stock management. It’s compatible with all Windows, Mac, and Android
        devices, making it a versatile tool for any retail business.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lt:grid-cols-3  gap-[20px]">
        {features.map((feature, index) => (
          <motion.div
            key={index}
            onClick={() => {
              if (feature.path) {
                window.open(feature.path, "_blank");
              }
            }}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.4, delay: index * 0.1 }}
            whileHover={{ scale: 1.05 }}
            className={`custom-dotted-border ${
              feature.path ? "cursor-pointer" : ""
            } rounded-[8px] flex items-start text-center shadow-md p-[33px] bg-[#F5F5F5]`}
          >
            <div className=" mr-[36px] ">
              <img
                src={
                  index === 0
                    ? img1
                    : index === 1
                    ? img2
                    : index === 2
                    ? img3
                    : index === 3
                    ? img4
                    : index === 4
                    ? img5
                    : index === 5
                    ? img6
                    : index === 6
                    ? img7
                    : index === 7
                    ? img8
                    : index === 8
                    ? img9
                    : img9
                }
                alt=""
                className="w-[64px]  "
              />
            </div>
            <div className=" flex flex-col items-start justify-start ">
              <div className="feature-text-style">{feature.title}</div>
              <p className="text-[#666666]  text-start text-[14px]  leading-[24px]">
                {feature.description}
              </p>
            </div>
          </motion.div>
        ))}
      </div>
    </motion.section>
  );
}
