import moment from "moment";
import React, { useState } from "react";
import GeniustextInput from "../../utility/component/mastertext";

import { useSelector } from "react-redux";
import { selectWidth } from "../../utility/Redux/profile";
import { FormatNumber2 } from "../../utility/functions/formatNumber";
import { AccountMasterHelp } from "../../help";

const ItemPrice = ({ data, setdata, setHsnMasterHelpStatus }) => {
  const width = useSelector(selectWidth);
  const inputWidth = width < 500 ? "240px" : "308px";
  const doubleGrid = "grid  grid-cols-2 gap-2";
  const [supplierType, setSupplearType] = useState("");
  const [partyMasterHelpStatus, setPartyMasterHelpStatus] = useState(false);
  const [dateFocus, setDateFocus] = useState(false);

  const CGST_AMOUNT = (Number(data.PUR_PRICE) * Number(data.TAX1_RATE)) / 100;
  const SGST_AMOUNT = (Number(data.PUR_PRICE) * Number(data.TAX2_RATE)) / 100;
  const CESS_AMOUNT = (Number(data.PUR_PRICE) * Number(data.CESS_RATE)) / 100;
  const NET_PURCHASE_PRICE =
    Number(data.PUR_PRICE) + SGST_AMOUNT + CGST_AMOUNT + CESS_AMOUNT;
  const MARGIN_AMOUNT = Number(data.MRP_RATE) - NET_PURCHASE_PRICE;
  return (
    <>
      <div
        id={"Item Price"}
        className="grid md:grid-cols-[200px,1fr] ll:grid-cols-[400px,1fr]  border-b pb-2 p-3"
      >
        <div>
          <div className="font-semibold text-[16px] text-black  w-fit ">
            Item Price
          </div>
          <div className="text-[12px] text-gray-600  w-fit ">
            Edit your item details
          </div>
        </div>
        <div className="   grid grid-cols-2 md:grid-cols-4 gap-2">
          <GeniustextInput
            id={"MRP_RATE"}
            grid={true}
            backgroundColor={data.ICODE ? "#e6e6e3" : "transparent"}
            titleColor={"red"}
            width={`${Number(inputWidth.replace("px", "")) / 2 - 5}px`}
            title={"MRP"}
            type={"number"}
            value={data.MRP_RATE}
            onChange={(e) =>
              setdata((p) => {
                return {
                  ...p,
                  MRP_RATE: e.target.value,
                };
              })
            }
          />

          <GeniustextInput
            id={"PUR_PRICE"}
            width={inputWidth}
            title={"Purchase Price"}
            type={"number"}
            backgroundColor={data.ICODE ? "#e6e6e3" : "transparent"}
            value={data.PUR_PRICE}
            grid={true}
            onChange={(e) =>
              setdata((p) => {
                return {
                  ...p,
                  PUR_PRICE: e.target.value ? Number(e.target.value) : "",
                };
              })
            }
          />
          <GeniustextInput
            grid={true}
            id={"MARK_RATE"}
            width={`${Number(inputWidth.replace("px", "")) / 2 - 5}px`}
            title={"Mark %"}
            type={"number"}
            value={data.MARK_RATE}
            onChange={(e) => {
              const markupAmount =
                (Number(data.PUR_PRICE) * Number(e.target.value)) / 100;
              const price = Number(data.PUR_PRICE) + markupAmount;
              setdata((p) => {
                return {
                  ...p,
                  MARK_RATE: e.target.value,
                  SALE_PRICE: price,
                };
              });
            }}
          />

          <GeniustextInput
            id={"SALE_PRICE"}
            grid={true}
            backgroundColor={data.ICODE ? "#e6e6e3" : "transparent"}
            width={`${Number(inputWidth.replace("px", "")) / 2 - 5}px`}
            title={"Sale Price"}
            titleColor={"darkBlue"}
            type={"number"}
            onBlur={(e) => {
              if (!data.MARK_RATE || data.MARK_RATE < 1) {
                const MARK_RATE =
                  !data.MARK_RATE || data.MARK_RATE < 1
                    ? ((e.target.value - data.PUR_PRICE) / data.PUR_PRICE) * 100
                    : data.MARK_RATE;

                setdata((p) => {
                  return {
                    ...p,
                    SALE_PRICE: e.target.value,
                    MARK_RATE: FormatNumber2(MARK_RATE),
                  };
                });
              }
            }}
            value={data.SALE_PRICE}
            onChange={(e) => {
              setdata((p) => {
                return {
                  ...p,
                  SALE_PRICE: e.target.value,
                };
              });
            }}
          />

          <GeniustextInput
            grid={true}
            id={"DISQ_RATE"}
            width={`${Number(inputWidth.replace("px", "")) / 2 - 5}px`}
            title={"Discount %"}
            type={"number"}
            value={data.DISQ_RATE}
            onChange={(e) =>
              setdata((p) => {
                return {
                  ...p,
                  DISQ_RATE: e.target.value,
                };
              })
            }
          />

          <GeniustextInput
            id={"WHOLE_SALE_PRICE"}
            width={inputWidth}
            title={"WholeSale Price"}
            type={"number"}
            grid={true}
            value={data.WHOLE_SALE_PRICE}
            onChange={(e) =>
              setdata((p) => {
                return {
                  ...p,
                  WHOLE_SALE_PRICE: e.target.value,
                };
              })
            }
          />
          <GeniustextInput
            id={"NET_PURCHASE_PRICE"}
            title={"Net Pur. Price"}
            type={"number"}
            titleColor={"red"}
            backgroundColor={data.ICODE ? "#e6e6e3" : "transparent"}
            grid={true}
            value={NET_PURCHASE_PRICE ? FormatNumber2(NET_PURCHASE_PRICE) : 0}
            readOnly={true}
          />
          <GeniustextInput
            id={"MARGIN_AMOUNT"}
            title={"Margin Amount"}
            grid={true}
            titleColor={"red"}
            type={"number"}
            value={MARGIN_AMOUNT ? FormatNumber2(MARGIN_AMOUNT) : 0}
            readOnly={true}
          />
        </div>
      </div>

      <div
        id={"Tax Details"}
        className="grid md:grid-cols-[200px,1fr] ll:grid-cols-[400px,1fr]  border-b pb-2 p-3"
      >
        <div>
          <div className="font-semibold text-[16px] text-black  w-fit ">
            Tax Details
          </div>
          <div className="text-[12px] text-gray-600  w-fit ">
            Edit your tax details
          </div>
        </div>
        <div>
          <div className="  grid grid-cols-1 md:grid-cols-2 gap-2">
            <div className="grid grid-cols-2 gap-2">
              <GeniustextInput
                id={"HSN_CODE"}
                grid={true}
                width={inputWidth}
                search={() => {
                  setHsnMasterHelpStatus(true);
                }}
                title={"HSN Code"}
                type={"number"}
                onKeyDown={(e) => {
                  if (e.code === "Enter" || e.code === "NumpadEnter") {
                    if (!data.HSN_CODE) {
                      setHsnMasterHelpStatus(true);
                    }
                  }
                }}
                value={data.HSN_CODE}
                onChange={() => setHsnMasterHelpStatus(true)}
              />
              <GeniustextInput
                max={10}
                grid={true}
                id={"TAX1_RATE"}
                width={`${Number(inputWidth.replace("px", "")) / 2 - 5}px`}
                title={"CGST"}
                type={"number"}
                value={data.TAX1_RATE}
              />
            </div>

            <div className="grid grid-cols-2 gap-2">
              {/* <GeniustextInput
                id={"TAX3_RATE"}
                max={10}
                grid={true}
                width={`${Number(inputWidth.replace("px", "")) / 2 - 5}px`}
                title={"IGST"}
                type={"number"}
                value={data.TAX3_RATE}
                onChange={(e) =>
                  setdata((p) => {
                    return {
                      ...p,
                      TAX3_RATE: e.target.value,
                    };
                  })
                }
              /> */}
              <GeniustextInput
                id={"TAX2_RATE"}
                max={10}
                grid={true}
                width={`${Number(inputWidth.replace("px", "")) / 2 - 5}px`}
                title={"SGST"}
                type={"number"}
                value={data.TAX2_RATE}
              />

              <GeniustextInput
                id={"CESS_RATE"}
                width={`${Number(inputWidth.replace("px", "")) / 2 - 5}px`}
                title={"Cess %"}
                type={"number"}
                grid={true}
                value={data.CESS_RATE}
                onChange={(e) =>
                  setdata((p) => {
                    return {
                      ...p,
                      CESS_RATE: e.target.value,
                    };
                  })
                }
              />
            </div>
          </div>
          <div className="grid grid-cols-4 gap-2">
            <div></div>

            <div></div>
          </div>
        </div>
      </div>

      <div
        id={"Stock Details"}
        className="grid md:grid-cols-[200px,1fr] ll:grid-cols-[400px,1fr]  border-b pb-2 p-3"
      >
        <div>
          <div className="font-semibold text-[16px] text-black  w-fit ">
            Stock Details
          </div>
          <div className="text-[12px] text-gray-600  w-fit ">
            Update your stock information
          </div>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
          <GeniustextInput
            id={"OPBAL"}
            title={"Opening Bal."}
            type={"number"}
            value={data.OPBAL}
            grid={true}
            onChange={(e) =>
              setdata((p) => {
                return {
                  ...p,
                  OPBAL: e.target.value,
                };
              })
            }
          />
          <GeniustextInput
            id={"OPBAL_DATE"}
            date={true}
            grid={true}
            fromDate={
              data.OPBAL_DATE
                ? moment(data.OPBAL_DATE).format("YYYY-MM-DD")
                : moment().format("YYYY-MM-DD")
            }
            setFromDate={(d) => {
              setdata((e) => {
                return {
                  ...e,
                  OPBAL_DATE: moment(d).format("YYYY-MM-DD"),
                };
              });
              setDateFocus(false);
            }}
            fromDateFocus={dateFocus}
            setFromDateFocus={setDateFocus}
            title={"Opening Bal. Date"}
            value={data.OPBAL_DATE}
          />
          <GeniustextInput
            id={"OPBAL_AMOUNT"}
            title={"Min. Stock"}
            type={"number"}
            grid={true}
            value={data.OPBAL_AMOUNT}
            onChange={(e) =>
              setdata((p) => {
                return {
                  ...p,
                  OPBAL_AMOUNT: e.target.value,
                };
              })
            }
          />
          <GeniustextInput
            id={"PRINT_ORDER_SLIP"}
            title={"Max. Stock"}
            grid={true}
            type={"number"}
            value={data.PRINT_ORDER_SLIP}
            onChange={(e) =>
              setdata((p) => {
                return {
                  ...p,
                  PRINT_ORDER_SLIP: e.target.value,
                };
              })
            }
          />
        </div>
      </div>

      <div
        id={"Supplier Details"}
        className="grid md:grid-cols-[200px,1fr] ll:grid-cols-[400px,1fr]  border-b pb-2 p-3"
      >
        <div>
          <div className="font-semibold text-[16px] text-black  w-fit ">
            Supplier Details
          </div>
          <div className="text-[12px] text-gray-600  w-fit ">
            Edit your supplier details
          </div>
        </div>
        <div className="   grid grid-cols-1 gap-2">
          <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
            <GeniustextInput
              id={"SUPPLIER_1"}
              title={"Supplier 1"}
              type={"text"}
              search={() => {
                setSupplearType("SUPPLIER_1");
                setPartyMasterHelpStatus(true);
              }}
              value={data.SUPPLIER_1}
              grid={true}
            />
            <GeniustextInput
              title={"Supplier 2"}
              grid={true}
              search={() => {
                setSupplearType("SUPPLIER_2");
                setPartyMasterHelpStatus(true);
              }}
              type={"text"}
              value={data.SUPPLIER_2}
            />

            <GeniustextInput
              id={"SUPPLIER_3"}
              title={"Supplier 3"}
              type={"text"}
              search={() => {
                setSupplearType("SUPPLIER_3");
                setPartyMasterHelpStatus(true);
              }}
              value={data.SUPPLIER_3}
              grid={true}
            />
          </div>

          <div className="grid grid-cols-1 p:grid-cols-2 gap-2"></div>

          <div className="grid md:grid-cols-4 grid-cols-2  gap-2">
            <GeniustextInput
              title={"Availability"}
              grid={true}
              option={[
                {
                  value: "InStore",
                  name: "InStore",
                },
                {
                  value: "Online & InStore",
                  name: "Online & InStore",
                },
                {
                  value: "Online",
                  name: "Online",
                },
              ]}
              onChange={(e) => {
                setdata((d) => {
                  return {
                    ...d,
                    ITEM_AVAILABILITY: e.target.value,
                  };
                });
              }}
              value={data.ITEM_AVAILABILITY}
            />
            <GeniustextInput
              grid={true}
              max={20}
              id={"AISLE"}
              width={`${Number(inputWidth.replace("px", "")) / 2 - 5}px`}
              type={"text"}
              title={"Aisle"}
              value={data.AISLE}
              onChange={(e) =>
                setdata((p) => {
                  return {
                    ...p,
                    AISLE: e.target.value,
                  };
                })
              }
            />
            <GeniustextInput
              grid={true}
              max={20}
              id={"SHELF"}
              width={`${Number(inputWidth.replace("px", "")) / 2 - 5}px`}
              title={"Shelf"}
              type={"text"}
              value={data.SHELF}
              onChange={(e) =>
                setdata((p) => {
                  return {
                    ...p,
                    SHELF: e.target.value,
                  };
                })
              }
            />
            <GeniustextInput
              max={20}
              id={"BAY"}
              grid={true}
              width={`${Number(inputWidth.replace("px", "")) / 2 - 5}px`}
              type={"text"}
              title={"Bay"}
              value={data.BAY}
              onChange={(e) =>
                setdata((p) => {
                  return {
                    ...p,
                    BAY: e.target.value,
                  };
                })
              }
            />
          </div>
        </div>
      </div>

      <AccountMasterHelp
        status={partyMasterHelpStatus}
        onRowClick={(p) => {
          setPartyMasterHelpStatus(false);
          setdata((d) => {
            return {
              ...d,
              [supplierType]: p.row.NAME,
            };
          });
        }}
        onClose={() => {
          setPartyMasterHelpStatus(false);
        }}
      />
    </>
  );
};

export default ItemPrice;
