import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import React, { useEffect, useRef, useState } from "react";
import welcome from "../../utility/images/LOGIN_VECTOR.svg";
import boxImg from "../../utility/images/box.svg";
import shape from "../../utility/images/LOGIN_DOODLE.svg";
import { useDispatch, useSelector } from "react-redux";
import CryptoJS from "crypto-js";
import AlertModal2 from "../../utility/component/modals/alertWithLottie";
import logo2 from "../../utility/Logo/orangeCircleLogo.svg";
import logo from "../../utility/Logo/TextLogo/orangeTextLogo.svg";
import { useNavigate } from "react-router-dom";
import {
  selectEmailVerifyStatus,
  selectSpinnerLoading,
  setALertStatus,
  setAlertMsg,
  setAlertWithTitle,
  setCheckEmailAlert,
  setEmailVerificationStatus,
  setSpinnerLoading,
} from "../../utility/Redux/modal";
import CallAPI from "../../utility/functions/getData";
import { useFocus } from "../../utility/hooks/hooks";

import {
  selectOtpModalStatus,
  selectRandomOtp,
  setOtpStatus,
  setToken,
} from "../../utility/Redux/security";
import SpinnerLoading from "../../utility/component/modals/spinnerLoading";
import moment from "moment";
import axios from "axios";
import styles from "../../utility/styles/LoginForm.module.css";
import GeniusFooterLogo from "../../utility/images/ico.png";
import { isTablet } from "react-device-detect";
import {
  selectLatitude,
  selectLongitude,
  selectWidth,
} from "../../utility/Redux/profile";
import ForgetModel from "./forget";
import CheckEmailModall from "../../utility/component/modals/resetPassword";
import CustomeModal from "../../utility/component/modals/customeModal";
import DraftsTwoToneIcon from "@mui/icons-material/DraftsTwoTone";
import clear from "../../utility/images/clear2.svg";
import HandleIndexedDB from "../../Test/handleIndexDb";
import deleteWholeDatabase from "../../utility/functions/deleteWholeDatabase";
import {
  decodeToken,
  isStateCheckEnabeld,
  licenceExpired_alert,
} from "../../utility/constant";
import { checkStateAllowedOrNot, runLoginFunc } from "./func";
import RetailxLogo from "../../utility/component/styles/homeLogo";
import OtpAlertModal from "../../utility/component/modals/otpAlert";
import { CallAPIPromise } from "../../billing/common";
import Lottie from "lottie-react";
import spinner from "../../utility/json/spinerWhite.json";
import Navbar from "../../utility/navbar";
const LoginV3 = () => {
  const [gmailAccountDetails, setGmailAccountDetails] = useState("");
  const loading = useSelector(selectSpinnerLoading);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [emailRef, setEmailFocus] = useFocus();
  const [passwordRef, setPasswordFocus] = useFocus();
  const [forget, setForget] = useState(false);
  const [location, setLocation] = useState(null);
  const otpStatus = useSelector(selectOtpModalStatus);
  const randomOTP = useSelector(selectRandomOtp);
  const [userInfo, setUserInfo] = useState("");
  const [err, setErr] = useState("");
  const [mobileErr, setMobileErr] = useState("");
  const [passwrdERR, setpasswrdErr] = useState("");
  const isEmailVerifyModelOpn = useSelector(selectEmailVerifyStatus);
  const move = useNavigate();
  const width = useSelector(selectWidth);
  const dispatch = useDispatch();
  const secretPass = process.env.REACT_APP_SECRET_KEY;

  const storeRememberData = async (email, passwrd) => {
    const e = CryptoJS.AES.encrypt(
      JSON.stringify(email),
      secretPass
    ).toString();
    const p = CryptoJS.AES.encrypt(
      JSON.stringify(passwrd),
      secretPass
    ).toString();

    localStorage.setItem(
      "rememberd",
      JSON.stringify({
        email: e,
        password: p,
      })
    );
  };

  const forgetPassword = (emailCheck) => {
    const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;

    // const hmac = CryptoJS.HmacSHA256(message, secret);
    const hmac = CryptoJS.HmacSHA256(username, secretPass);
    // Convert the HMAC to a string and encode it using Base64
    const hmacString = hmac.toString(CryptoJS.enc.Base64);
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneRegex = /^[6-9]\d{9}$/;
    if (re.test(emailCheck) || phoneRegex.test(emailCheck)) {
      dispatch(setSpinnerLoading("Loading"));
      CallAPIPromise(
        "/api/users/findUserDetail",
        {
          user_email: !phoneRegex.test(emailCheck) ? emailCheck : "",
          user_mobile: phoneRegex.test(emailCheck) ? emailCheck : "",
        },
        hmacString,
        dispatch
      )
        .then((res) => {
          if (res.data[0]) {
            CallAPIPromise(
              `/api/users/resetUserPasswordEmail`,
              {
                user_email: !phoneRegex.test(emailCheck) ? emailCheck : "",
                user_name: res.data[0].user_name,
                user_mobile: phoneRegex.test(emailCheck) ? emailCheck : "",
              },
              hmacString,
              dispatch
            )
              .then((r) => {
                dispatch(setCheckEmailAlert(true));
                // move('/auth/checkEmail')
              })
              .catch((err) => {
                dispatch(setAlertMsg(err.code + " " + err.message));
                dispatch(setALertStatus(true));
              })
              .finally(() => {
                dispatch(setSpinnerLoading(false));
              });
          } else {
            dispatch(setAlertMsg("Not registered user"));
            dispatch(setALertStatus(true));
          }
        })
        .catch((e) => {
          dispatch(setAlertMsg("Something wrong"));
          dispatch(setALertStatus(true));
        });
    } else {
      dispatch(setAlertMsg("Invailed email address"));
      dispatch(setALertStatus(true));
    }
  };
  localStorage.removeItem("financial");
  localStorage.removeItem("financial_company");
  localStorage.removeItem("masterPrefix");
  localStorage.removeItem("user");
  useEffect(() => {
    const isRemmberd = JSON.parse(localStorage.getItem("rememberd"));

    if (isRemmberd) {
      const bytes = CryptoJS.AES.decrypt(isRemmberd.email, secretPass);
      const bytes2 = CryptoJS.AES.decrypt(isRemmberd.password, secretPass);

      if (bytes.sigBytes < 0 || bytes2.sigBytes < 0) {
        localStorage.removeItem("rememberd");
      } else {
        const decEmail = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        const decPass = JSON.parse(bytes2.toString(CryptoJS.enc.Utf8));

        setUsername(decEmail);
        setPassword(decPass);
        setRememberMe(true);
      }
    }
    deleteWholeDatabase("pathHistory");
    localStorage.removeItem("userDefaultSetting");
  }, []);

  const forgetModel = (e) => {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneRegex = /^[6-9]\d{9}$/;
    if (re.test(e)) {
      setForget(true);
    } else {
      setEmailFocus();
      setMobileErr("Please Enter Valid Email");
      setTimeout(() => {
        setMobileErr("");
      }, 5000);
    }
  };
  const longitude = useSelector(selectLongitude);
  const latitude = useSelector(selectLatitude);
  const loginApi = (data, hmac) => {
    const d = {
      code: data.code,
      user_email: username,
      user_mobile: "",
      user_password: password,
      latidude: latitude ? latitude : "",
      longitude: longitude ? longitude : "",
      ip_address: "",
    };
    if (
      moment(data.end_date).format("YYYY-MM-DD") >=
      moment().format("YYYY-MM-DD")
    ) {
      dispatch(setSpinnerLoading("Authenticating user"));
      CallAPIPromise("/api/users/login/", d, hmac, dispatch)
        .then((e) => {
          if (e.data.token) {
            if (isStateCheckEnabeld) {
              checkStateAllowedOrNot(
                fetchAllowedState,
                data,
                dispatch,
                e,
                rememberMe,
                storeRememberData,
                username,
                password,
                move
              );
            } else {
              runLoginFunc(
                dispatch,
                e,
                data,
                rememberMe,
                storeRememberData,
                username,
                password,
                move
              );
            }
          } else {
            dispatch(
              setAlertWithTitle({
                title: "Alert",
                msg: "Something went wrong",
              })
            );
          }
        })
        .catch((e) => {
          console.log(e);
          dispatch(
            setAlertWithTitle({
              title: "Alert",
              msg: e.response.data.error,
            })
          );
        });
    } else {
      dispatch(
        setAlertWithTitle({
          title: "Alert",
          msg: licenceExpired_alert,
        })
      );
    }
  };

  const handleLogin = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    console.log(username);
    if (emailRegex.test(username)) {
      if (username && password) {
        const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
        // const hmac = CryptoJS.HmacSHA256(message, secret);
        const hmac = CryptoJS.HmacSHA256(username, secretPass);
        // Convert the HMAC to a string and encode it using Base64
        const hmacString = hmac.toString(CryptoJS.enc.Base64);
        const data = {
          user_email: username,
          user_mobile: "",
        };
        dispatch(setSpinnerLoading("Getting User Detail"));
        CallAPI(
          "/api/users/userInfo",
          data,
          hmacString,
          (e) => {
            console.log(e);
            if (e.data.response[0]) {
              const data = e.data.response[0];
              setUserInfo(data);
              if (data.email_verified === "Y") {
                if (data.valid_user === 0) {
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: "We have received your account activation request. Please wait for account activation.",
                    })
                  );
                } else {
                  loginApi(e.data.response[0], hmacString);
                }
              } else {
                dispatch(
                  setAlertWithTitle({
                    title: "Alert",
                    msg: "We have sent a verification email to your company email. Please verify it to log in.",
                  })
                );
              }
            } else {
              setPasswordFocus();
              dispatch(setSpinnerLoading(false));
              dispatch(
                setAlertWithTitle({
                  title: "Alert",
                  msg: "User not found",
                })
              );
            }
          },
          dispatch
        );
      } else {
        setPasswordFocus();

        setpasswrdErr("Please Enter Password");
        setTimeout(() => {
          setpasswrdErr("");
        }, 5000);
      }
    } else {
      setEmailFocus();
      setMobileErr("Please Enter Email");
      setTimeout(() => {
        setMobileErr("");
      }, 5000);
    }
  };

  const formRef = useRef(null);

  const fetchAllowedState = (email) => {
    return new Promise(async (resolve, reject) => {
      const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
      const hmac = CryptoJS.HmacSHA256(email, secretPass);
      // Convert the HMAC to a string and encode it using Base64
      const hmacString = hmac.toString(CryptoJS.enc.Base64);
      try {
        CallAPI(
          "/api/users/listAllowedStates",
          {
            user_email: email,
          },
          hmacString,
          (e) => {
            resolve(e.data.response);
          },
          dispatch
        );
      } catch (error) {
        resolve([]);
      }
    });
  };
  return (
    <div
      style={{
        position: "relative",
      }}
      className="h-screen w-screen overflow-hidden px-2  items-center bg-gradient-to-b from-[#f8e8d2] to-white"
    >
      <Navbar />
      <div className="flex justify-center items-center h-full">
        <div
          style={{
            zIndex: 100,
          }}
          className="grid shadow-md t:grid-cols-[1fr,440px] ml:grid-cols-[400px]  ms:grid-cols-[330px] ll:min-w-[1024px] rounded-[20px] overflow-hidden"
        >
          <div
            style={{
              position: "relative", // This makes it the reference for absolute children
            }}
            className="bg-[#fdf6f2] ms:hidden t:flex justify-center items-center overflow-hidden "
          >
            <div className="flex flex-col items-center ">
              <div className="text-gray-600">Nice to see you again</div>
              <div
                style={{
                  fontSize: 45,
                  fontWeight: 600,
                }}
                className="text-orange text-center "
              >
                Welcome back!
              </div>

              <img
                style={{
                  zIndex: 100,
                  width: 400,
                }}
                src={welcome}
              />
              <div
                style={{
                  position: "absolute",
                  bottom: -200,
                  right: -50,
                  zIndex: 0,
                }}
              >
                <img
                  style={{
                    height: 500,
                    opacity: 1,
                  }}
                  src={shape}
                  alt="Box"
                />
              </div>
            </div>
          </div>
          <div ref={formRef} className="bg-white  ml:px-8 px-3 py-4">
            <div className="">
              <div
                style={{
                  fontSize: 30,
                  fontWeight: "700",
                }}
              >
                Log in.
              </div>
              <div className="pb-6 pt-2 text-gray-400 text-sm ">
                Log in with your data you entered <br />
                during your registration
              </div>

              <div className="mb-4">
                <label className="block text-gray-900 font-semibold text-[13px] mb-2">
                  Enter Your Email Address
                </label>
                <input
                  type="email"
                  placeholder={mobileErr ? mobileErr : "Your Email"}
                  ref={emailRef}
                  onKeyDown={(e) => {
                    if (e.code === "Enter" || e.code === "NumpadEnter") {
                      setPasswordFocus();
                    }
                  }}
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className={`w-full px-3 py-2 ${
                    mobileErr
                      ? "placeholder:text-red-500 text-red-500"
                      : "text-black"
                  } border rounded-lg focus:outline-none text-[14px] focus:ring-2 focus:ring-orange`}
                />
              </div>

              <div className="">
                <label className="block text-gray-900 font-semibold text-[13px] mb-2">
                  Enter Your Password
                </label>
                <div className="relative w-full">
                  <input
                    ref={passwordRef}
                    placeholder={passwrdERR ? passwrdERR : "Password"}
                    onKeyDown={(e) => {
                      if (e.code === "Enter" || e.code === "NumpadEnter") {
                        handleLogin();
                      }
                    }}
                    className={`w-full ${
                      passwrdERR
                        ? "placeholder:text-red-500 text-red-500:"
                        : "text-black"
                    } px-3 py-2 border rounded-lg focus:outline-none text-[14px] focus:ring-2 focus:ring-orange pr-10`}
                    type={showPassword ? "text" : "password"}
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div
                    className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      // Eye icon (Show Password)
                      <i className="bi bi-eye"></i>
                    ) : (
                      // Eye with Slash icon (Hide Password)
                      <i className="bi bi-eye-slash"></i>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex justify-between w-full text-sm text-orange font-semibold mt-1">
                <label className="flex ml-2">
                  <input
                    type="checkbox"
                    color="orange"
                    className="mr-1 "
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                  />
                  <div className="text-sm font-normal text-black">
                    Remember Me
                  </div>
                </label>
                <button
                  onClick={() => {
                    if (username) {
                      forgetModel(username);
                    } else {
                      dispatch(
                        setAlertMsg(
                          "Please enter user email address for change password"
                        )
                      );
                      dispatch(setALertStatus(true));
                    }
                  }}
                  className=""
                >
                  Forget password?
                </button>
              </div>

              <button
                onClick={handleLogin}
                disabled={loading}
                type="button"
                className="flex text-center items-center justify-center py-2 text-white mt-4 rounded-[30px] bg-orange w-full "
              >
                {loading && (
                  <Lottie
                    animationData={spinner}
                    loop={true}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      height: "20px",
                      marginRight: "8px",
                    }}
                  />
                )}
                Log in
              </button>
              {!gmailAccountDetails && (
                <div className="mt-6 flex justify-center w-full ">
                  <GoogleOAuthProvider clientId="63087371523-ile4jrs20gof4l4mvequ2i91btj9kg0h.apps.googleusercontent.com">
                    <GoogleLogin
                      onSuccess={(response) => {
                        const details = decodeToken(response.credential);
                        console.log(details);
                        const hmac = CryptoJS.HmacSHA256(
                          process.env.REACT_APP_AUTH_HMA_KEY,
                          process.env.REACT_APP_AUTH_SECRET_KEY
                        );
                        dispatch(setSpinnerLoading("Verifying"));
                        CallAPIPromise(
                          "/api/googleAuth2Login/googleTokenVerify",
                          {
                            token: response.credential,
                          }
                        )
                          .catch(() => {
                            dispatch(
                              setAlertWithTitle({
                                title: "Alert",
                                msg: "Google Login Error",
                              })
                            );
                          })
                          .then((e) => {
                            if (e?.data?.token) {
                              const data = e.data.userDetails;
                              setUserInfo(data);
                              setUsername(data.user_email);

                              if (isStateCheckEnabeld) {
                                checkStateAllowedOrNot(
                                  fetchAllowedState,
                                  data,
                                  dispatch,
                                  e,
                                  false,
                                  storeRememberData,
                                  data.user_email,
                                  "",
                                  move
                                );
                              } else {
                                runLoginFunc(
                                  dispatch,
                                  e,
                                  data,
                                  false,
                                  storeRememberData,
                                  data.user_email,
                                  "",
                                  move
                                );
                              }
                            }
                            //   onClose();
                          });
                      }}
                      width={width < 768 ? 368 : width < 1024 ? 412 : 412}
                      shape={"circle"}
                      onError={() => console.log("Login Failed")}
                    ></GoogleLogin>
                  </GoogleOAuthProvider>
                </div>
              )}
              <div className="flex justify-center mt-[40px]">
                <div className="text-gray-500 text-sm">
                  Don't have an account?{" "}
                </div>
                <div
                  onClick={() => {
                    move("/auth/mobile_signup");
                  }}
                  className="text-sm text-orange ml-1 font-semibold"
                >
                  Sign Up
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            bottom: -100,
            left: -120,
            zIndex: 0,
          }}
        >
          <img
            style={{
              height: 400,
              opacity: 0.3,
            }}
            src={shape}
            alt="Box"
          />
        </div>
      </div>
      <AlertModal2 />
      <ForgetModel
        forget={forget}
        setForget={setForget}
        forgetPassword={forgetPassword}
        email={username}
      />
      <CustomeModal
        status={isEmailVerifyModelOpn}
        data={
          <div className="bg-white t:max-w-[450px] max-w-[320px]">
            <div className="flex justify-end p-3 pb-0 items-center">
              <button
                onClick={() => dispatch(setEmailVerificationStatus(false))}
              ></button>
              <img
                alt=""
                src={clear}
                style={{
                  width: "15px",
                }}
              />
            </div>
            <div
              onClick={() => dispatch(setEmailVerificationStatus(false))}
              className="flex justify-center"
            >
              <DraftsTwoToneIcon color={"warning"} fontSize={"large"} />
            </div>
            <div className="text-center font-semibold mt-3">
              Welcome To Retailx
            </div>
            <div className="text-center mt-3 px-4 ">
              let's get Started, We've just sent you a quick verification email
              to your inbox. verify if for continue.
            </div>
            <div className=" flex justify-center p-4">
              <div>
                <button
                  onClick={() => dispatch(setEmailVerificationStatus(false))}
                  className="bg-orange text-white p-2 text-sm px-5 rounded-sm "
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        }
      />
      <CheckEmailModall />
      <OtpAlertModal
        status={otpStatus}
        otp={randomOTP}
        onCancel={() => {
          dispatch(setOtpStatus(false));
        }}
        onSubmit={() => {
          if (userInfo) {
            const databaseName = "userDetails";
            const storeName = "data";
            deleteWholeDatabase(databaseName);

            const dataToSave = [
              {
                start_date: userInfo.start_date,
                end_date: userInfo.end_date,
                currency_name: userInfo.currency_name,
                currency_symbol: userInfo.currency_symbol,
                user_email: userInfo.user_email,
                ICODE: 1,
                lastLoginDate: moment().format("YYYY-MM-DD"),
              },
            ];

            HandleIndexedDB({
              databaseName,
              storeName,
              dataToSave,
            });
          }
          move("/auth/financial_year", {
            state: {
              CompanyEmail: userInfo?.company_email,
              UserEmail: username,
              companyName: userInfo?.company_name,
            },
          });
        }}
      />
    </div>
  );
};

export default LoginV3;
