import React, { lazy, Suspense, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import {
  CustomerMaster,
  DepartMentMaster,
  ItemMaster,
  SectionMaster,
  SubSecMaster,
} from "./masters/index";
import { ItemWiseSaleReport, Reports } from "./reports";
import SpinnerLoading from "./utility/component/modals/spinnerLoading";
import { ActivationAccount, Auth, NotFound } from "./auth";
import AppSettings from "./settings/appSettings";
import CrossAppCompanySetting from "./settings/bannerSettings/crossAppCompanySettings";
import CrossBanners from "./settings/bannerSettings/crossBanners";
import AliosImgLibrary from "./settings/alias_img_library";
import LoginV3 from "./auth/login/loginV3";
import ContactUs from "./auth/contactUs";
import LandingPage from "./landingPage";
import PendingDeliverdPayments from "./reports/pendingDeliverdPayments";
import ImageMaster from "./settings/imageLibrary";
import AccountsPage from "./accounts/dashboard";
const SaleSummary = lazy(() => import("./reports/saleSummary"));
const Purchase = lazy(() => import("./purchase"));

const ItemWisePurchaseReport = lazy(() =>
  import("./reports/itemWisePurchaseSummary")
);
const Login = lazy(() => import("./auth/login"));
const MobileSignUp = lazy(() => import("./auth/mobilesignup/index3"));
const FinancialYearForm = lazy(() => import("./auth/financial-year"));
const ResetEmail = lazy(() => import("./auth/resetUserEmail"));
const PartyMaster = lazy(() => import("./masters/account-master"));
const OfferMaster = lazy(() => import("./masters/offer-master"));
const HsnMaster = lazy(() => import("./masters/hsn-master"));
const PurchaseSummary = lazy(() => import("./reports/purchaseSummary"));
const UserMaster = lazy(() => import("./settings/userMaster"));
const ExpenseMaster = lazy(() => import("./masters/expense"));
const PaymentVoucher = lazy(() => import("./updation/paymentVoucher"));
const VoucherSummary = lazy(() => import("./reports/voucherSummary"));
const MessageSetupMaster = lazy(() => import("./settings/messageSetupMaster"));
const CompanyMaster = lazy(() => import("./settings/companyMaster"));
const OfferList = lazy(() => import("./reports/offerSummery"));
const RecieptVoucher = lazy(() => import("./updation/recieptVoucher"));
const Dashboard = lazy(() => import("./dashboard"));
const ForgotPassword = lazy(() => import("../src/auth/changePassword"));
const InvoiceBill = lazy(() => import("./invoice"));

const Masters = lazy(() => import("./masters/dashboard"));
const UserDefaultSetting = lazy(() => import("./settings/userDefaultSetting"));
const ProtectedRoute = lazy(() => import("./auth/util/protectedRoute"));
const Home = lazy(() => import("./home"));
const VerifyEmail = lazy(() => import("./auth/verifyEmail"));
const Updation = lazy(() => import("./updation/dashboard"));
const Settings = lazy(() => import("./settings/dashboard"));
const NewLoginDesign = lazy(() => import("./auth/loginNewDesign"));
const SaleTaxReport = lazy(() => import("./reports/saleTaxReport"));
const PaymodeSummary = lazy(() => import("./reports/payModeSummary"));
const ItemWisePurchase = lazy(() => import("./reports/itemWiseHistory"));
const ItemStatus = lazy(() => import("./reports/itemStatus"));
const StockReport = lazy(() => import("./reports/stockReport"));
const QRCodeScanner = lazy(() => import("./utility/component/AllScanner"));
const CreateCompanyDb = lazy(() => import("./settings/createDatabase"));
const Billing = lazy(() => import("./billing"));
const BillDashboard = lazy(() => import("./dashboard/billDashboard"));
const ExpiryReport = lazy(() => import("./reports/expiryReport"));
const CustomerLedger = lazy(() => import("./reports/customerLedger"));
const CustomerBalReport = lazy(() => import("./reports/CustomerBalReport"));
const PartyLedger = lazy(() => import("./reports/partyLedger"));
const PartyBalReport = lazy(() => import("./reports/partyWiseBalance"));
const Account_master = lazy(() => import("./reports/partyWiseBalance"));
const accountsPages = lazy(() => import("./accounts/dashboard"));
const balSheetHeadMaster = lazy(() =>
  import("./masters/bal_sheet_head_master")
);
const PurchasePaymentVoucher = lazy(() =>
  import("./updation/purchasePaymentVoucher")
);
const DFRReport = lazy(() => import("./reports/dfrReport"));
const LiveMap = lazy(() => import("./reports/fieldWayLocation"));
const ReceivedOrders = lazy(() => import("./reports/recievedOrders"));
const RecivedOrderDashboard = lazy(() =>
  import("./dashboard/recivedOrderDashboard")
);
const DailyStockMoveReport = lazy(() => import("./reports/dailyStockMovement"));
const PartyItemsSale = lazy(() => import("./reports/partyItemsSale"));
const CrossCompanyList = lazy(() => import("./settings/retailxCrossAllow"));
const CustomerEmailVerified = lazy(() => import("./verify/verifyCustomer"));
const PaymentForm = lazy(() => import("./billing/billingModalNew"));
const UserScopesMaster = lazy(() => import("./settings/user_scopes_master"));
const Test = lazy(() => import("./test"));
const EmployeeMaster = lazy(() => import("./masters/employee-master"));
const TemplateMaster = lazy(() => import("./masters/templateMaster"));
const NewLoginModal = lazy(() => import("./auth/newLogin"));
const BulkChangeMaster = lazy(() => import("./masters/bulkChangeMaster"));
const ImageLibrary = lazy(() => import("./settings/imageLibrary"));
const Issue = lazy(() => import("./Issue"));
const IssueSummary = lazy(() => import("./reports/issueSummary"));
const DepWiseIssue = lazy(() => import("./reports/depWiseIssue"));
const ItemWiseIssue = lazy(() => import("./reports/itemWiseIssue"));
const TermAndCondition = lazy(() => import("./auth/terms"));

const AppRoutes = ({ userType }) => {
  const authRoutes = [
    {
      path: "login",
      element: <LoginV3 />,
    },
    {
      path: "landing",
      element: <LandingPage />,
    },
    {
      path: "newLogin",
      element: <NewLoginModal />,
    },
    {
      path: "mobile_signup",
      element: <MobileSignUp />,
    },
    {
      path: "barCode",
      element: <QRCodeScanner />,
    },
    {
      path: "financial_year",
      element: <FinancialYearForm />,
    },
    {
      path: "reset_password_email",
      element: <ResetEmail />,
    },
  ];
  const privateRoutes = [
    { path: "/account_master", element: PartyMaster, userType: "allowed" },
    { path: "/newLogin", element: NewLoginDesign, userType: "allowed" },
    { path: "/sale_tax_report", element: SaleTaxReport, userType: "allowed" },
    { path: "/paymode_summary", element: PaymodeSummary, userType: "allowed" },
    { path: "/select_bill_type", element: BillDashboard, userType: "allowed" },
    {
      path: "/pending_payments_of_deliverd",
      element: PendingDeliverdPayments,
      userType: "allowed",
    },
    {
      path: "/issue_summary",
      element: IssueSummary,
      userType: "MANAGER STORE",
    },
    { path: "/test3", element: Test, userType: "allowed" },
    {
      path: "/cross_company_list",
      element: CrossCompanyList,
      userType: "ADMIN",
    },

    { path: "/company_master", element: CompanyMaster, userType: "ADMIN" },
    { path: "/image_library", element: ImageLibrary, userType: "ADMIN" },
    { path: "/customer_master", element: CustomerMaster, userType: "allowed" },
    { path: "/dashboard", element: Dashboard, userType: "ADMIN" },
    { path: "/template_master", element: TemplateMaster, userType: "ADMIN" },
    {
      path: "/product_img_library",
      element: ImageMaster,
      userType: "ADMIN",
    },
    {
      path: "/cross_app_company_settings",
      element: CrossAppCompanySetting,
      userType: "ADMIN",
    },
    {
      path: "/cross_banners",
      element: CrossBanners,
      userType: "ADMIN",
    },
    { path: "/payment", element: PaymentForm, userType: "allowed" },
    {
      path: "/bulk_change_master",
      element: BulkChangeMaster,
      userType: "MANAGER ADMIN",
    },
    {
      path: "/user_scope_roles",
      element: UserScopesMaster,
      userType: "allowed",
    },
    {
      path: "/department_master",
      element: DepartMentMaster,
      userType: "allowed",
    },
    {
      path: "/expense_master",
      element: ExpenseMaster,
      userType: "MANAGER ADMIN",
    },
    { path: "/hsn_master", element: HsnMaster, userType: "allowed" },
    { path: "/party_ledger", element: PartyLedger, userType: "allowed" },
    { path: "/party_bal_report", element: PartyBalReport, userType: "allowed" },
    {
      path: "/pur_payment_voucher",
      element: PurchasePaymentVoucher,
      userType: "allowed",
    },
    {
      path: "/bal_sheet_head_master",
      element: balSheetHeadMaster,
      userType: "allowed",
    },
    {
      path: "/dep_wise_issue",
      element: DepWiseIssue,
      userType: "MANAGER STORE",
    },
    {
      path: "/item_wise_issue",
      element: ItemWiseIssue,
      userType: "MANAGER STORE",
    },
    { path: "/customer_ledger", element: CustomerLedger, userType: "allowed" },
    { path: "/fieldway_location", element: LiveMap, userType: "ADMIN" },
    { path: "/item_master", element: ItemMaster, userType: "allowed" },
    {
      path: "/item_wise_purchase_report",
      element: ItemWisePurchaseReport,
      userType: "allowed",
    },
    {
      path: "/item_wise_sale_report",
      element: ItemWiseSaleReport,
      userType: "allowed",
    },
    { path: "/masters", element: Masters, userType: "allowed" },
    { path: "/employee_master", element: EmployeeMaster, userType: "allowed" },
    {
      path: "/message_setup_master",
      element: MessageSetupMaster,
      userType: "ADMIN",
    },
    { path: "/offer_list", element: OfferList, userType: "allowed" },
    { path: "/party_Items_Sale", element: PartyItemsSale, userType: "allowed" },
    {
      path: "/customer_balance_report",
      element: CustomerBalReport,
      userType: "allowed",
    },
    { path: "/offer_master", element: OfferMaster, userType: "allowed" },
    { path: "/stock_report", element: StockReport, userType: "allowed" },
    { path: "/accounts", element: AccountsPage, userType: "allowed" },
    { path: "/payment_voucher", element: PaymentVoucher, userType: "allowed" },
    { path: "/purchase", element: Purchase, userType: "allowed" },
    {
      path: "/purchase_summary",
      element: PurchaseSummary,
      userType: "allowed",
    },
    {
      path: "/Closing_Value_Report",
      element: DailyStockMoveReport,
      userType: "allowed",
    },
    { path: "/reciept_voucher", element: RecieptVoucher, userType: "allowed" },
    { path: "/reports", element: Reports, userType: "allowed" },
    { path: "/billing", element: Billing, userType: "STORE USER MANAGER" },
    { path: "/sale_summary", element: SaleSummary, userType: "allowed" },
    {
      path: "/order_dashboard",
      element: RecivedOrderDashboard,
      userType: "allowed",
    },
    { path: "/expiry_report", element: ExpiryReport, userType: "allowed" },
    { path: "/section_master", element: SectionMaster, userType: "allowed" },
    {
      path: "/item_wise_purchase",
      element: ItemWisePurchase,
      userType: "allowed",
    },
    { path: "/settings", element: Settings, userType: "allowed" },
    { path: "/fieldway_dfr", element: DFRReport, userType: "ADMIN" },
    { path: "/sub_section_master", element: SubSecMaster, userType: "allowed" },
    { path: "/updation", element: Updation, userType: "allowed" },
    {
      path: "/user_default_setting",
      element: UserDefaultSetting,
      userType: "MANAGER ADMIN",
    },
    { path: "/item_status", element: ItemStatus, userType: "allowed" },
    { path: "/recieved_orders", element: ReceivedOrders, userType: "allowed" },
    { path: "/create_db", element: CreateCompanyDb, userType: "ADMIN" },
    { path: "/app_settings", element: AppSettings, userType: "ADMIN" },
    { path: "/bill", element: Billing, userType: "allowed" },
    { path: "/performa", element: Billing, userType: "allowed" },
    { path: "/issue", element: Issue, userType: "allowed" },

    { path: "/user_master", element: UserMaster, userType: "MANAGER ADMIN" },
    { path: "/voucher_summary", element: VoucherSummary, userType: "allowed" },
  ];

  const otherRouter = [
    "/changeUserPassword",
    "/activateUser",
    "/emailVerified",
    "/invoice",
    "/customerEmail",
    "/auth/mobile_signup",
    "/auth/barCode",
    "/auth/newLogin",
    "/auth/login",
    "/terms_conditions",
    "/contact",
    "/landing",
  ];

  const expires = localStorage.getItem("expiresAt");
  const date = new Date().getTime();
  const navigate = useNavigate();
  const location = useLocation();

  const UserTime = () => {
    if (expires) {
      if (expires < date) {
        navigate("/landing");
      }
    } else {
      navigate("/landing");
    }
  };

  useEffect(() => {
    const isOtherRoute = otherRouter.filter((r) => r === location.pathname);
    if (!isOtherRoute[0]) {
      UserTime();
    }
  }, []);
  return (
    <Suspense
      fallback={
        <div className="flex items-center justify-center h-screen bg-gray-100">
          <div className="flex flex-col items-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-orange"></div>
            <p className="mt-4 text-gray-600 text-lg">Loading...</p>
          </div>
        </div>
      }
    >
      <Routes>
        <Route path="/auth" element={<Auth />}>
          {authRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Route>
        <Route path="*" element={<NotFound />} />

        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        >
          {privateRoutes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  route.userType.includes(userType) ||
                  route.userType === "allowed" ? (
                    <ProtectedRoute>
                      {" "}
                      <route.element />
                    </ProtectedRoute>
                  ) : (
                    <NotFound />
                  )
                }
              />
            );
          })}
        </Route>
        <Route path="/changeUserPassword" element={<ForgotPassword />} />
        <Route path="/activateUser" element={<ActivationAccount />} />
        <Route path="/emailVerified" element={<VerifyEmail />} />
        <Route path="/invoice" element={<InvoiceBill />} />
        <Route path="/customerEmail" element={<CustomerEmailVerified />} />
        <Route path="/terms_conditions" element={<TermAndCondition />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/test" element={<Test />} />
        <Route path="/landing" element={<LandingPage />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
