import { motion } from "framer-motion"; // ✅ CORRECT IMPORT
import fullHomeImg from "../utility/images/fullImg.svg";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  const navigate = useNavigate();
  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="l:flex justify-between ms:items-end sl:items-center pt-[120px] l:py-0 mx-auto"
    >
      {/* Left Content */}
      <motion.div
        initial={{ x: -100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 1 }}
        className="flex flex-col gap-6 pt-[110px] w-full l:pr-[0px] 2xl:pl-[110px] l:pl-[40px] pr-[70px] pl-[40px]"
      >
        <h1 className="2xl:text-[43px] d:text-[30px] text-[25px] font-bold text-[#000000] l:leading-[35px] ms:leading-[40px] lt:leading-[54px]">
          Complete POS Solution for Retail Billing & Stock Management with Your
          Ultimate Online Ordering Solution.
        </h1>
        <p className="text-[#3b3b3b] d:text-[16px] text-[13px]">
          Retailx is a powerful WebApp for retail billing and stock management,
          compatible with Windows, Mac, and Android. RetailxCross is its
          customer ordering and delivery management app, streamlining logistics
          with fast, reliable deliveries, order tracking, and route optimization
          for seamless retail operations.
        </p>

        {/* Input Box + Button */}
        <div className="flex flex-col t:flex-row">
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            style={{
              border: "1px solid #feae34",
            }}
            className=" rounded-[70px] p-3 w-fit flex items-center gap-2"
          >
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => {
                navigate("/contact");
              }}
              className="bg-[#FAAB33] text-white px-5 py-3 rounded-[70px] text-[16px] hover:bg-[#ff8c00]"
            >
              Get Free Demo
            </motion.button>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            style={{
              border: "1px solid #cc6100",
            }}
            className=" t:ml-3 ms:mt-3 t:mt-0  rounded-[70px] p-3 w-fit flex items-center gap-2"
          >
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => {
                navigate("/auth/mobile_signup");
              }}
              className="bg-[#cc6100] text-white px-5 py-3 rounded-[70px] text-[16px] hover:bg-[#cc6100]"
            >
              Sign Up Retailx
            </motion.button>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            style={{
              border: "1px solid #cc6100",
            }}
            className=" t:ml-3 ms:mt-3 t:mt-0  rounded-[70px] p-3 w-fit flex items-center gap-2"
          >
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => {
                window.open("https://www.retailxcross.com/");
              }}
              className="bg-[#993d00] text-white px-5 py-3 rounded-[70px] text-[16px] hover:bg-[#cc6100]"
            >
              RetailxCross
            </motion.button>
          </motion.div>
        </div>
      </motion.div>

      {/* Right Image */}
      <motion.div
        initial={{ x: 100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 1 }}
        style={{
          width: "calc(100% - 50px)",
        }}
        className="w-full ms:hidden l:flex justify-end py-10 l:py-0 overflow-hidden"
      >
        <img
          src={fullHomeImg}
          alt="POS Illustration"
          width={"100%"}
          className="-mt-[50px] xl:-mt-[110px] -mr-[25%]"
        />
      </motion.div>
    </motion.section>
  );
};

export default HeroSection;
